import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useSelector } from "react-redux";
import { AllContext } from "../../context";
import Select from "react-select";
import { apiPostMethod, apiGetMethod, apiPutMethod } from "../../api/rest";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import PageLoader from "../../components/pageLoader";
import FormElementGenerate from "../../components/FormElementsEdit/FormElementGenerate";
import {editorApiKey} from "../../shared/constants";
import openIcon from "../../assets/img/icons/openSide.svg";

const AddTicket = () => {
  const { state } = useLocation();
  const context = useContext(AllContext);
  const { allApiUrl, toastMessage } = context;
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [addTickets, setAddTickets] = useState("");
  const [ccTickets, setCcTickets] = useState([]);
  const [subjectTicket, setSubjectTicket] = useState("");
  const [relatedOrder, setRelatedOrder] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [allClient, setAllClient] = useState([]);
  const [allClientBackUp, setAllClientBackUp] = useState([]);
  const [ccClientBackUp, setCcClientBackUp] = useState([]);
  const [ccClient, setCcClient] = useState([]);
  const [showToEmail, setShowToEmail] = useState(false);
  const [content, setContent] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [clientOrder, setClientOrder] = useState([]);
  const [clientOrderBackUp, setClientOrderBackup] = useState([]);
  const editorRef = useRef(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [jsonData, setJsonData] = useState([]);

  const { ADD_TICKET, GET_ALL_CLIENT, ADD_CLIENT, CLIENT_ORDER_ID } =
    allApiUrl;
  useEffect(() => {
    if (state) {
      if (state.from == "client") {
        setAddTickets(state.mailTo?.email);
        setSelectedClient(state.mailTo?._id);
      }
    }
  }, []);
  const businessId = useSelector(
    (state) => state.loginReducer.loginUserInfo.businessId
  );
  const navigate = useNavigate();
  useEffect(() => {
    getAllClient();
  }, []);
  useEffect(() => {
    if (selectedClient) {
      getClientOrders(selectedClient);
    }
  }, [selectedClient]);

  function getClientOrders(selectedClient) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(CLIENT_ORDER_ID + "/" + selectedClient, "", header)
      .then((res) => {
        let clientOrder = [];
        res.data.order.map((item) => {
          clientOrder.push({
            title: item.title || " ",
            serviceName: item.serviceName || " ",
            label: (
              <>
                {item.title || " "} {item.serviceName ? "\n" : ""}{" "}
                <small>{item.serviceName || " "}</small>
              </>
            ),
            value: item._id,
          });
        });

        setClientOrder(clientOrder);
        setClientOrderBackup(clientOrder);
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const addTicket = (ccData) => {
    const ticketsFields = jsonData;
    const data = {
      to: "",
      cc: [],
      subject: "",
      relatedOrder: "",
      message: content,
      rating: null,
      status: "Open",
      businessId: businessId,
      ticketsFields
    };
    if (addTickets) {
      data.to = addTickets;
      if (ccData.length > 0) {
        ccData.map((row, index) => {
          ccTickets[index] = row.value;
        });
      } else {
        ccTickets.map((row, index) => {
          ccTickets[index] = row.value;
        });
      }
      data.cc = ccTickets;
      data.subject = subjectTicket;
      data.relatedOrder = relatedOrder.value;
      if (selectedFile.length > 0) {
        data.message = [];
      } else {
        data.message = [
          {
            from: userInfo.id,
            date: new Date().getTime(),
            message: content,
            to: "Client",
            attachments: [],
          },
        ];
      }
      data.client = selectedClient;
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(ADD_TICKET, data, header)
      .then((res) => {
        if (res.status === 201) {
          setAddTickets(res.data);
          toastMessage("success", res.data.message);
          if (selectedFile.length > 0) {
            updateTicketMessage(content, res.data.logs._id);
            setTimeout(() => {
              toastMessage("success", "uploadingFiles...");
            }, 800);
          } else {
            navigate("/tickets");
          }
        }
      })
      .catch((err) => {
        if (addTickets.length == 0) {
          toastMessage("error", "To is required");
        } else if (subjectTicket.length == 0) {
          toastMessage("error", "Subject is required");
        } else if (content.length == 0) {
          toastMessage("error", "Messsage is required");
        } else {
          toastMessage("error", err.data.message);
        }
      });
  };
  function updateTicketMessage(message, id) {
    const formData = new FormData();
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append("files", selectedFile[i]);
    }
    formData.append("from", userInfo.id);
    formData.append("message", message);
    formData.append("date", new Date().getTime());
    formData.append("to", "Client");
    formData.append("type", "Ticket");
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
      "Content-Type": "multipart/form-data",
    };
    apiPutMethod("/settings/sendMessage" + "/" + id, formData, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        navigate("/tickets");
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }

  function getAllClient() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_ALL_CLIENT, "", header)
      .then((res) => {
        if (res.status === 200) {
          let clientList = [];
          res.data.team.map((client) => {
            let label = "";
            if (client.userId.firstName || client.userId.lastName) {
              label = (
                <>
                  {client.userId.firstName || " "}{" "}
                  {client.userId.lastName || ""} {"\n"}{" "}
                  <small>{client.userId.email}</small>{" "}
                </>
              );
            }
            let data = {
              label: label,
              value: client.userId._id,
              firstName: client.userId.firstName || " ",
              lastName: client.userId.lastName || " ",
              email: client.userId.email || " ",
            };
            clientList.push(data);
          });
          setCcClient(clientList);
          setCcClientBackUp(clientList);
          setAllClient(res.data.team);
          setAllClientBackUp(res.data.team);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        setInitialLoading(false);
      });
  }
  function handleEditorChange(e) {
    // var str = e.target.getContent();
    setContent(e);
  }

  function CheckNewCC() {
    ccTickets.map((cc, index) => {
      if (cc.value == "newCC") {
        const autoFirstName = cc.label.substring(0, cc.label.indexOf("@"));
        const data = {
          accountStatus: "contact",
          role: "client",
          createdBy: userInfo.id,
          firstName: autoFirstName,
          email: cc.label,
        };
        let header = {
          Authorization: `Bearer ${loginUserAccessToken}`,
        };
        apiPostMethod(ADD_CLIENT, data, header)
          .then((res) => {
            if (res.status === 201) {
              ccTickets[index].value = res.data.id;
              toastMessage("success", res.data.message);
              if (index == ccTickets.length - 1) {
                addTicket(ccTickets);
              }
            }
          })
          .catch((err) => {
            toastMessage("error", err.data.message);
          });
      } else {
        if (index == ccTickets.length - 1) {
          addTicket(ccTickets);
        }
      }
    });
  }

  function AttachmentFiles() {
    if (selectedFile && selectedFile.length > 0) {
      let files = [...selectedFile];
      return files.map((selected, index) => {
        return (
          <span>
            <a href="#">
              {" "}
              <span className="innerIcon attachIcon me-1">
                <i className="fa-solid fa-link"></i>{" "}
              </span>
              {selected.name}{" "}
              <span className="innerIcon crossIcon mx-2">
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => {
                    var dt = new DataTransfer();
                    let input = document.getElementById("myFileInput");
                    var { files } = input;
                    for (var i = 0; i < files.length; i++) {
                      var file = files[i];
                      if (index !== i) dt.items.add(file);
                      input.files = dt.files;
                    }
                    setSelectedFile(input.files);
                  }}
                ></i>
              </span>
            </a>
          </span>
        );
      });
    }
    return "";
  }

  useEffect(() => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_TICKETS_FIELDS, "", header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setJsonData(res.data);
        }
      }).catch((err) => {
      toastMessage("error", err.data.message);
    });
  }, []);
  return (
    <div>
      <Sidebar
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="commonSlidePl">
          <div className="orders-section commonPadding dashboardTop">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 col-12">
                  <div className="heading position-relative">
                    <h2 className="mb-0">
                      Tickets
                      <span className="userCounter">&nbsp;</span>
                    </h2>
                    <span className="openArrowIcon" onClick={() => {
                      setToggleSidebar(!toggleSideBar)
                    }}>
                      <img src={openIcon} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid pl-240">
                  <form className="pt-4 pb-4">
                    <div className="row">

                      <div className="col-lg-9">
                        <div className="setting-form ticketForm formOuter">
                          <div className="commonForm px-4">
                          <h3 className="fs-4 m-0 mb-4 p-3">Add Ticket</h3>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group position-relative">
                                  <label>To</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="jim@agency.com"
                                    value={addTickets}
                                    onChange={(e) => {
                                      if (!e.target.value) {
                                        setShowToEmail(!showToEmail);
                                      }
                                      setAddTickets(e.target.value);
                                      if (e.target.value.length > 0) {
                                        let filteredAllClientList = [];

                                        filteredAllClientList = allClientBackUp.filter(
                                          (item) => {
                                            if (
                                              item.userId.firstName.includes(
                                                e.target.value
                                              ) ||
                                              item.userId?.email?.includes(
                                                e.target.value
                                              )
                                            ) {
                                              return item;
                                            }
                                          }
                                        );
                                        setAllClient(filteredAllClientList);
                                      } else {
                                        setAllClient(allClientBackUp);
                                      }
                                    }}
                                    onClick={() => {
                                      setShowToEmail(!showToEmail);
                                    }}
                                    onBlur={() => {
                                      setTimeout(() => {
                                        setShowToEmail(false);
                                      }, 300);
                                    }}
                                  />

                                  {showToEmail && (
                                    <ul className="dataDropdown AddDropdown">
                                      {allClient &&
                                        allClient.map((client) => {
                                          if (client.userId?.email)
                                            return (
                                              <li
                                                onClick={() => {
                                                  setShowToEmail(!showToEmail);
                                                  setAddTickets(client.userId.email);
                                                  setSelectedClient(client.userId._id);
                                                }}
                                              >
                                                {client.userId.firstName ||
                                                client.userId.lastName ? (
                                                  <>
                                                    {client.userId.firstName &&
                                                    client.userId.lastName ? (
                                                      client.userId.firstName +
                                                      " " +
                                                      client.userId.lastName
                                                    ) : (
                                                      <>
                                                        {client.userId.firstName
                                                          ? client.userId.firstName
                                                          : client.userId.lastName}
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  client.userId.email
                                                )}
                                                <br />
                                                <small>{client.userId.email}</small>
                                              </li>
                                            );
                                        })}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group position-relative ccGroup">
                                  {/* <label>
                                  CC <small>optional</small>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder=""
                                  value={ccTickets}
                                  onChange={(e) => {
                                    setCcTickets(e.target.value);
                                  }}
                                  onClick={() => {
                                    setShowCcEmail(!showCcEmail);
                                  }}
                                  onBlur={() => {
                                    setTimeout(() => {
                                      setShowCcEmail(false);
                                    }, 300);
                                  }}
                                /> */}
                                  {/* {showCcEmail && (
                                  <ul className="dataDropdown">
                                    {allClient &&
                                      allClient.map((client) => {
                                        if (client.userId?.email)
                                          return (
                                            <li
                                              onClick={() => {
                                                setShowCcEmail(!showCcEmail);
                                                setCcTickets(client.userId.email);
                                              }}
                                            >
                                              {client.userId.firstName ||
                                              client.userId.lastName ? (
                                                <>
                                                  {client.userId.firstName &&
                                                  client.userId.lastName ? (
                                                    client.userId.firstName +
                                                    " " +
                                                    client.userId.lastName
                                                  ) : (
                                                    <>
                                                      {client.userId.firstName
                                                        ? client.userId.firstName
                                                        : client.userId.lastName}
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                client.userId.email
                                              )}
                                              <br />
                                              <small>{client.userId.email}</small>
                                            </li>
                                          );
                                      })}
                                  </ul>
                                )} */}
                                  <Select
                                    className="my-react-select my-react-select--compact"
                                    classNamePrefix="lp-copy-sel"
                                    isMulti
                                    // value={multipleDiscountList[index]?.service}
                                    value={ccTickets}
                                    options={ccClient}
                                    placeholder="Add CC"
                                    onChange={(e) => {
                                      setCcTickets(e);

                                      // setMultipleDiscountList((prevData) => {
                                      //   if (prevData[index] == undefined) {
                                      //     prevData[index] = {
                                      //       service: e,
                                      //       discount: 0,
                                      //     };
                                      //   } else {
                                      //     prevData[index].service = e;
                                      //   }
                                      //   return [...prevData];
                                      // });
                                    }}
                                    onInputChange={(e) => {
                                      if (e.length > 0) {
                                        let list = [];
                                        list = ccClientBackUp.filter((data) => {
                                          if (
                                            data.firstName.includes(e) ||
                                            data.lastName.includes(e) ||
                                            data.email.includes(e)
                                          ) {
                                            return data;
                                          }
                                        });
                                        setCcClient(list);
                                      } else {
                                        setCcClient(ccClientBackUp);
                                      }
                                    }}
                                    filterOption={false}
                                    onBlur={(e) => {
                                      if (e.target.value.length > 6) {
                                        let status = String(e.target.value)
                                          .toLowerCase()
                                          .match(
                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                          );
                                        if (status != null) {
                                          let data = {
                                            label: e.target.value,
                                            value: "newCC",
                                          };
                                          ccTickets.push(data);
                                          setCcTickets(ccTickets);
                                        } else {
                                          toastMessage(
                                            "error",
                                            "Invalid Email Address"
                                          );
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Subject</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    onChange={(e) => {
                                      setSubjectTicket(e.target.value);
                                    }}
                                    value={subjectTicket}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group relatedOrder">
                                  <label>
                                    Related order <small>optional</small>
                                  </label>
                                  {/* <select
                                  className="form-control"
                                  value={realtedTicket}
                                  onChange={(event) => {
                                    setRelatedTicket(event.target.value);
                                  }}
                                >
                                  <option>
                                    Please Select... <body></body>
                                  </option>
                                  {clientOrder.map((value) => {
                                    return (
                                      <option value={value._id}>{value.title}</option>
                                    );
                                  })}
                                </select> */}
                                  <Select
                                    className="my-react-select my-react-select--compact"
                                    classNamePrefix="lp-copy-sel"
                                    isSearchable
                                    value={relatedOrder}
                                    options={clientOrder}
                                    onInputChange={(e) => {
                                      if (e.length > 0) {
                                        let list = [];
                                        list = clientOrderBackUp.filter((data) => {
                                          if (
                                            data.title
                                              .toLowerCase()
                                              .includes(e.toLowerCase()) ||
                                            data.serviceName
                                              .toLowerCase()
                                              .includes(e.toLowerCase())
                                          ) {
                                            return data;
                                          }
                                        });
                                        setClientOrder(list);
                                      } else {
                                        setClientOrder(clientOrderBackUp);
                                      }
                                    }}
                                    filterOption={false}
                                    onChange={(e) => {
                                      setRelatedOrder(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Message</label>
                                  {/* <textarea className="form-control" onChange={(e) => {
                                                        setMessageTicket(e.target.value);
                                                    }}
                                                        value={messageTicket}></textarea> */}
                                  <Editor
                                    required
                                    apiKey={editorApiKey}
                                    onInit={(evt, editor) =>
                                      (editorRef.current = editor)
                                    }
                                    value={content}
                                    init={{
                                      selector: "textarea",
                                      height: 200,
                                      menu: {
                                        custom: {
                                          title: "custom",
                                          items: "basicitem nesteditem toggleitem",
                                        },
                                      },
                                      toolbar:
                                        "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                        "fullpage" +
                                        "indent | blockquote | codesample | fontselect ",
                                      font_formats:
                                        "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                      block_formats:
                                        "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                      plugins: [
                                        "image",
                                        "help",
                                        "link",
                                        "codesample",
                                        "lists",
                                      ],
                                      branding: false,
                                      content_style:
                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                      menubar: false,
                                    }}
                                    onEditorChange={(e) => handleEditorChange(e)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="text-start">
                                <div id="attachment" className="messageAttachment">
                                  <AttachmentFiles />
                                </div>
                                <input
                                  type="file"
                                  className="btn btn-secondary"
                                  hidden
                                  multiple
                                  id="myFileInput"
                                  onChange={(e) => {
                                    setSelectedFile(e.target.files);
                                  }}
                                />
                                <span
                                  className="delete btn btn-light mt-2 attachmentIcon"
                                  onClick={() => {
                                    document.getElementById("myFileInput").click();
                                  }}
                                >
                                  <i className="fa-solid fa-link"></i>
                                </span>
                              </div>
                            </div>
                            <FormElementGenerate jsonData={jsonData} setJsonData={setJsonData} showCardUI={false} />
                          </div>
                          
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="commonBtn-section">
                          <button className="btn btn-cancel" onClick={(e) => {
                            e.preventDefault();
                            navigate("/tickets")
                          }}>Cancel</button>
                          <button
                                className="btn btn-save"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (ccTickets.length > 0) {
                                    CheckNewCC();
                                  } else {
                                    addTicket([]);
                                  }
                                }}
                              >
                                Add Ticket
                              </button>
                        </div>
                      </div>
                    </div>
              </form>
          </div>
        </div>
        </div>
      )}
    </div>
  );
};
export default AddTicket;