import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import noData from "../../assets/img/nodata-img.svg";
import PaginationNewUI from "../../components/PaginationNewUI";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const ClientReport = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [clientRevenue, setClientRevenue] = React.useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [clientDataByDate, setClientDataByDate] = useState([]);
  const [open, setOpen] = useState(false);
  const context = useContext(AllContext);
  const { allApiUrl, apiPostMethod, toastMessage, redirectToLogin } = context;
  const { DASHBOARD, DASHBOARD_DATE_FILTER } = allApiUrl;
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");
    getTenDayData();
  }, []);

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const onChange = (dates) => {
    const [start, end] = dates;
    setDateRange(dates);
    getDateFilterData(start, end);
    setOpen(false);
  };
  const endOffset = parseInt(itemOffset) * parseInt(itemsPerPage);
  const currentList = clientRevenue.slice(
    itemOffset > 1 ? itemOffset * itemsPerPage - (itemsPerPage - 1) - 1 : 0,
    endOffset
  );
  let pageCount = Math.ceil(clientRevenue.length / itemsPerPage);
  function getDateFilterData(start, end) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    if (end && end != startDate) {
      data = {
        startDate: startDate,
        endDate: end,
      };
    } else {
      data = {
        startDate: start ? start : startDate,
      };
    }
    setIsLoading(true);
    apiPostMethod(DASHBOARD_DATE_FILTER, data, header)
      .then((res) => {
        let clientDataTemp = res.data.data.clientRevenue;
        let tempArr = [];
        for (let item of clientDataTemp) {
          let data1 = item.split(":");
          if (data1[0] !== "undefined") {
            let obj = {};
            obj["name"] = data1[0];
            obj["revenueGenerated"] = data1[2];
            tempArr.push(obj);
          }
        }
        setTotalRecordsCount(tempArr.length);
        setClientRevenue(tempArr);
        setIsLoading(false);
        //new client data by date
        if (end && startDate) {
          let startDateSplits = startDate.toLocaleString().split("/");
          let endDateSplits = end.toLocaleString().split("/");
          let numOfDays = 0;
          let year = startDateSplits[2];
          year = year.split(",")[0];
          if (startDateSplits[1] == endDateSplits[1]) {
            numOfDays = endDateSplits[0] - startDateSplits[0];
            numOfDays += 1;
            let finalData = [...Array(numOfDays)].map((_a, index) => {
              let currentDate = index + parseInt(startDateSplits[0]);
              let dateExist = res.data.data.clientDataByDate.find((row) => {
                let date = row.name.split("-")[2];
                if (date == currentDate) {
                  return true;
                }
                return false;
              });
              if (dateExist) {
                return dateExist;
              } else {
                return {
                  name: `${year}-${startDateSplits[1]}-${
                    index + parseInt(startDateSplits[0]) > 9
                      ? currentDate
                      : "0" + currentDate
                  }`,
                  count: 0,
                };
              }
            });
            finalData.forEach((item, index) => {
              setClientDataByDate(() => {
                finalData[index].name = formatDateMonthName(item.name);
                return [...finalData];
              });
            });
          } else {
            // else case
            let var1 = new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0];
            let var2 = new Date(end.getTime() - end.getTimezoneOffset() * 60000)
              .toISOString()
              .split("T")[0];
            let currStartDate = startDate.toLocaleString().split(",");
            currStartDate = currStartDate[0];
            currStartDate = currStartDate.replaceAll("/", "-");
            const date = currStartDate;
            const [day, month, year] = date.split("-");
            currStartDate = [year, month, day].join("-");
            let currEndDate = end.toLocaleString().split(",");
            currEndDate = currEndDate[0];
            currEndDate = currEndDate.replaceAll("/", "-");
            const dateEnd = currEndDate;
            const [dayEnd, monthEnd, yearEnd] = dateEnd.split("-");
            currEndDate = [yearEnd, monthEnd, dayEnd].join("-");
            let dateRangeDate = getDatesInRange(new Date(var1), new Date(var2));
            function formatDate(date) {
              var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

              if (month.length < 2) month = "0" + month;
              if (day.length < 2) day = "0" + day;

              return [year, month, day].join("-");
            }
            dateRangeDate = dateRangeDate.flat();
            dateRangeDate = dateRangeDate.map((item) => {
              return formatDate(item);
            });

            let finalData = dateRangeDate.map((_a, index) => {
              let dateExist = res.data.data.clientDataByDate.find((row) => {
                let date = row.name.split("-")[2];
                let month = row.name.split("-")[1];
                if (date == _a.split("-")[2] && month == _a.split("-")[1]) {
                  return true;
                }
                return false;
              });

              if (dateExist) {
                return dateExist;
              } else {
                return {
                  name: _a,
                  count: 0,
                };
              }
            });
            finalData.forEach((item, index) => {
              setClientDataByDate(() => {
                finalData[index].name = formatDateMonthName(item.name);
                return [...finalData];
              });
            });
          }
        } else {
          let singleDate = new Date(
            start.getTime() - start.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          if (res.data.data.clientDataByDate.length === 0) {
            res.data.data.clientDataByDate[0] = {
              name: singleDate,
              count: 0,
            };
          }
          res.data.data.clientDataByDate.forEach((item, index) => {
            setClientDataByDate(() => {
              res.data.data.clientDataByDate[index].name = formatDateMonthName(
                item.name
              );
              return [...res.data.data.clientDataByDate];
            });
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
    date.setDate(date.getDate());
    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }
  function formatDateMonthName(d) {
    var date = new Date(d);

    if (isNaN(date.getTime())) {
      return d;
    } else {
      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "Jun";
      month[6] = "Jul";
      month[7] = "Aug";
      month[8] = "Sept";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";

      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      return day + " " + month[date.getMonth()];
    }
  }

  function getTenDayData() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {
      startDate: new Date(new Date().setDate(new Date().getDate() - 10)),
      endDate: startDate,
    };
    setIsLoading(true);
    apiPostMethod(DASHBOARD_DATE_FILTER, data, header)
      .then((res) => {
        let clientDataTemp = res.data.data.clientRevenue;
        let tempArr = [];
        for (let item of clientDataTemp) {
          let data1 = item.split(":");
          if (data1[0] !== "undefined") {
            let obj = {};
            obj["name"] = data1[0];
            obj["revenueGenerated"] = data1[2];
            tempArr.push(obj);
          }
        }
        setTotalRecordsCount(tempArr.length);
        setClientRevenue(tempArr);
        //newClient by day
        let startDateFilter = new Date(
          new Date().setDate(new Date().getDate() - 11)
        );
        let endDateFilter = startDate;
        let startDateSplits = startDateFilter.toLocaleString().split("/");
        let endDateSplits = endDateFilter.toLocaleString().split("/");

        setIsLoading(false);
        let numOfDays = 0;
        let year = startDateSplits[2];
        year = year.split(",")[0];
        if (startDateSplits[1] == endDateSplits[1]) {
          numOfDays = endDateSplits[0] - startDateSplits[0];
          let finalData = [...Array(numOfDays)].map((_a, index) => {
            let currentDate = index + parseInt(startDateSplits[0]) + 1;
            let dateExist = res.data.data.clientDataByDate.find((row) => {
              let date = row.name.split("-")[2];
              if (date == currentDate) {
                return true;
              }
              return false;
            });
            if (dateExist) {
              return dateExist;
            } else {
              return {
                name: `${year}-${startDateSplits[1]}-${
                  index + parseInt(startDateSplits[0]) > 9
                    ? currentDate
                    : "0" + currentDate
                }`,
                count: 0,
              };
            }
          });
          finalData.forEach((item, index) => {
            setClientDataByDate(() => {
              finalData[index].name = formatDateMonthName(item.name);
              return [...finalData];
            });
          });
        } else {
          let var1 = new Date(
            startDateFilter.getTime() -
              startDateFilter.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          let var2 = new Date(
            endDateFilter.getTime() - endDateFilter.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          let currStartDate = startDateFilter.toLocaleString().split(",");
          currStartDate = currStartDate[0];
          currStartDate = currStartDate.replaceAll("/", "-");
          const date = currStartDate;
          const [day, month, year] = date.split("-");
          currStartDate = [year, month, day].join("-");
          let currEndDate = endDateFilter.toLocaleString().split(",");
          currEndDate = currEndDate[0];
          currEndDate = currEndDate.replaceAll("/", "-");
          const dateEnd = currEndDate;
          const [dayEnd, monthEnd, yearEnd] = dateEnd.split("-");
          currEndDate = [yearEnd, monthEnd, dayEnd].join("-");
          let dateRangeDate = getDatesInRange(new Date(var1), new Date(var2));
          function formatDate(date) {
            var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
          }
          dateRangeDate = dateRangeDate.flat();
          dateRangeDate = dateRangeDate.map((item) => {
            return formatDate(item);
          });
          let finalData = dateRangeDate.map((_a, index) => {
            let dateExist = res.data.data.clientDataByDate.find((row) => {
              let date = row.name.split("-")[2];
              let month = row.name.split("-")[1];
              if (date == _a.split("-")[2] && month == _a.split("-")[1]) {
                return true;
              }
              return false;
            });

            if (dateExist) {
              return dateExist;
            } else {
              return {
                name: _a,
                count: 0,
              };
            }
          });
          finalData.forEach((item, index) => {
            setClientDataByDate(() => {
              finalData[index].name = formatDateMonthName(item.name);
              return [...finalData];
            });
          });
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err?.data?.message);
      });
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0 && payload[0].payload) {
      return (
        <div className="custom-tooltip" style={{ background: "white" }}>
          <p>{payload[0].payload.name}</p>
          <p>
            new Client <b>{payload[0].payload.count}</b>
          </p>
        </div>
      );
    }
  };
  return (
    <div>
      <Sidebar
        activePage="dashboard"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      {!isLoading && (
        <div className="commonSlidePl">
          <div className="dashboardSection commonPadding pb-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-4">
                  <div className="heading">
                    <h2 style={{ overflow: "hidden" }}>Clients</h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 d-flex align-items-center justify-content-end">
                  <div className="dashboardFilters">
                    <div className="dropdown d-flex">
                      <button
                        className="btn btn-secondary dropdown-toggle dashboardDropdown"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ width: "170px" }}
                      >
                        Clients
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => navigate("/revenue")}
                          >
                            Revenue
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => navigate("/service-report")}
                          >
                            Services
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => navigate("/client-report")}
                          >
                            Clients
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => navigate("/sales")}
                          >
                            Sale Performance
                          </a>
                        </li>
                      </ul>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            Last 7 Days
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Last 4 Weeks
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Last 3 Months
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Previous Month
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Month to Date
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Quarter to Date
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Year to Date
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            All Time
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="datepicker-wrapper ms-3">
                    <DatePicker
                      className="form-control"
                      selectsRange
                      maxDate={moment().toDate()}
                      dateFormat="MM/dd/yyyy"
                      startDate={startDate}
                      endDate={endDate}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xxl-12">
                <div className="card mt-3">
                  <div className="card-header">
                    <h3 className="mb-0">Clients Revenue</h3>
                  </div>
                  <div className="card-body">
                    <BarChart
                      width={1500}
                      height={300}
                      data={clientDataByDate}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={20}
                    >
                      <XAxis
                        dataKey="name"
                        scale="point"
                        padding={{ left: 10, right: 10 }}
                      />
                      <YAxis />
                      <Tooltip content={<CustomTooltip />} />
                      <Legend />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Bar
                        dataKey="count"
                        fill="#8884d8"
                        background={{ fill: "#eee" }}
                      />
                    </BarChart>
                  </div>
                </div>

                <div className="listingSection mt-4">
                  <div className="common-table rounded-1">
                    <div className="sectionPagination justify-content-end">
                      <PaginationNewUI
                        itemsPerPage={itemsPerPage}
                        itemOffset={itemOffset}
                        setItemOffset={setItemOffset}
                        pageCount={pageCount}
                        totalRecordsCount={totalRecordsCount}
                        setItemsPerPage={setItemsPerPage}
                      />
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Name</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentList &&
                          currentList.length > 0 &&
                          currentList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <a href="#">{item.name}</a>
                                </td>
                                <td>${item.revenueGenerated}</td>
                              </tr>
                            );
                          })}
                        {currentList && currentList.length <= 0 && (
                          <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ClientReport;
