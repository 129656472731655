import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
// import * as Yup from "yup";
import commonCurrency from "../../../api/common-currency.json";
import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import { AllContext } from "../../../context";
import Switch from "react-switch";
import { apiPutMethod } from "../../../api/rest";
import SettingTabs from "../tabs";
import TaxRulesList from "./TaxRulesList";
import AddTaxRuleModal from "./AddTaxRuleModal";
import { savePaymentSettinsData } from "../../../redux/action/sidemenu";
import AdminBillingAddress from "./AdminBillingAddressForm";
import openIcon from "../../../assets/img/icons/openSide.svg";

const initialBillingFormValues = { companyName: "", address: "", homeCountry: "", homeProvince: "", city: "", postalCode: "", taxId: "" };

const Payment = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [isLoading, setIsLoading] = useState(false);
  const {
    allApiUrl,
    apiPostMethod,
    toastMessage,
    apiGetMethod,
    redirectToLogin,
  } = context;
  const { PAYMENT_METHOD } = allApiUrl;
  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const [paypalPublicKey, setPaypalPublicKey] = useState("");
  const [paypalSecretKey, setPaypalSecretKey] = useState("");
  const [stripePublicKey, setStripePublicKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [inputPrefixNumber, setInputPrefixNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [companyDetails, setCompanyDetails] = useState("");
  const [homeCountry, setHomeCountry] = useState("");
  const [taxName, setTaxName] = useState("");
  const [taxRules, setTaxRules] = useState([]);
  const [paymentSettingData, setPaymentSettingData] = useState(null);
  const [isTaxesEnabled, setIsEnableTaxes] = useState(false);
  const [isAddRuleModalModalOpen, setIsAddRuleModalModalOpen] = useState(false);
  const [billingAddressFields, setBillingAddressFields] = useState(initialBillingFormValues);

  const [currency, setCurrency] = useState("USD");
  useEffect(() => {
    setIsLoading(true);
    getPyamentData();
  }, []);

  const getPyamentData = () => {
    apiGetMethod(PAYMENT_METHOD, "", header)
      .then((res) => {
        if (res.status === 200) {
          dispatch(savePaymentSettinsData(res.data.values));
          updateExistingPaymentValues(res.data.values);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  const updateExistingPaymentValues = (values) => {
    setPaypalPublicKey(values.paypal.publicKey);
    setPaypalSecretKey(values.paypal.privateKey);
    setStripePublicKey(values.stripe.publicKey);
    setStripeSecretKey(values.stripe.privateKey);
    setCompanyDetails(values.address);
    setIsEnableTaxes(values.taxes.isTaxesEnabled);
    setTaxName(values.taxes.taxName);
    setHomeCountry(values.taxes.homeCountry);
    setTaxRules(values.taxes.taxRules ? values.taxes.taxRules : []);
    setBillingAddressFields(values.taxes.billingAddress ? values.taxes.billingAddress : initialBillingFormValues);
    setPaymentSettingData(values);
    setCurrency(values.currency.code || "USD");
    if (values.invoice) {
      values.invoice.forEach((item) => {
        if (item.activeStatus) {
          setInputPrefixNumber(item.prefix);
          setInvoiceNumber(item.invoiceNumber);
        }
      });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let prefix = "";
    if (inputPrefixNumber) {
      prefix = inputPrefixNumber;
    }
    const currencyData = commonCurrency[currency];
    const data = {
      currency: currencyData,
      stripe: {
        publicKey: stripePublicKey,
        privateKey: stripeSecretKey,
      },
      paypal: {
        publicKey: paypalPublicKey,
        privateKey: paypalSecretKey,
      },
      invoice: {
        prefix,
        invoiceNumber: invoiceNumber,
        activeStatus: true,
      },
      address: companyDetails,
    };
    setIsLoading(true);
    apiPostMethod(PAYMENT_METHOD, data, header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleToggleChange = toggleChecked => {
    setIsEnableTaxes(toggleChecked);
    const data = {
      taxes: {
        isTaxesEnabled: toggleChecked,
        homeCountry,
        taxName,
        taxRules,
        billingAddress: billingAddressFields
      }
    }
    updatePaymentSetting(data);
  };

  const handleTaxRuleChange = (updatedTaxRuleData) => {
    const data = {
      taxes: {
        isTaxesEnabled,
        homeCountry,
        taxName,
        taxRules: updatedTaxRuleData,
        billingAddress: billingAddressFields
      }
    }
    updatePaymentSetting(data);
  };

  // const updateAllCountriesTax = () => {
  //   const taxCountriesData = taxCountries.slice();
  //   if (updateAlltaxValue > 0) {
  //     const updatedTaxCountries = taxCountriesData.map(data => {
  //       return { ...data, taxValue: updateAlltaxValue };
  //     });

  //     const data = {
  //       taxes: {
  //         taxCountries: updatedTaxCountries,
  //         homeCountry,
  //         taxName,
  //         isTaxesEnabled,
  //         billingAddress: billingAddressFields
  //       }
  //     }
  //     updatePaymentSetting(data);
  //     setUpdateAlltaxValue(0);
  //   }
  // }

  const handleSaveTaxes = () => {
    const data = {
      taxes: {
        taxRules,
        taxName,
        homeCountry,
        isTaxesEnabled,
        billingAddress: billingAddressFields
      }
    }
    updatePaymentSetting(data);
  }

  const handleDeleteTaxRule = (selectedItem) => {
    const taxRulesData = taxRules.slice();
    const updatedTaxRules = taxRulesData.filter(data => {
      return selectedItem.taxRuleName !== data.taxRuleName;
    });

    const data = {
      taxes: {
        taxRules: updatedTaxRules,
        homeCountry,
        taxName,
        isTaxesEnabled,
        billingAddress: billingAddressFields
      }
    }
    updatePaymentSetting(data);
  }

  const updatePaymentSetting = (data) => {
    setIsLoading(true);
    apiPutMethod(`${PAYMENT_METHOD}/${paymentSettingData._id}`, data, header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", res.data.message);
          getPyamentData();
          setIsAddRuleModalModalOpen(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  const handleAddRule = (data) => {
    const taxRulesData = taxRules.slice();

    const found = taxRulesData.some(el => el.taxRuleName.toLowerCase() === data.taxRuleName.toLowerCase());

    if (!found) {
      taxRules.push(data);

      const dataVal = {
        taxes: {
          taxRules: taxRules,
          homeCountry,
          taxName,
          isTaxesEnabled,
          billingAddress: billingAddressFields
        }
      }
      updatePaymentSetting(dataVal);
    } else {
      toastMessage("error", `Tax Rule already added for ${data.taxRuleName} country`);
    }
  }

  const handleCancelModal = () => {
    setIsAddRuleModalModalOpen(false);
  }

  const handleBillingAddressChange = (event) => {
    setBillingAddressFields({ ...billingAddressFields, [event.target.name]: event.target.value });
  }

  const handleSelectChange = (event, inputName) => {
    setBillingAddressFields({ ...billingAddressFields, [inputName]: event });
  }

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 col-12">
                  <div className="heading position-relative">
                    <h2 className="mb-0">
                      Payment Setting
                    </h2>
                    <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                      <img src={openIcon} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>  
      <SettingTabs currentTab="payment" />

      {/*<div className="setting-tab">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-content">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <div
                      className="section-back"
                      onClick={() => navigate("/settings")}
                    >
                      <a href="#" className="nav-link">
                        <i className="fa-solid fa-chevron-left"></i> Settings
                      </a>
                    </div>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    on
                    onClick={() => navigate("/settings/general")}
                  >
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Payments
                    </button>
                  </li>
                   <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Payments
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => navigate("/settings/team")}
                  >
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Team
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-back pl-240 mt-3">
        <a onClick={() => {navigate('/settings')}} href="#" className="horizontalPadding">
          <i class="fa-solid fa-angle-left"></i> Settings
        </a>handleEditRuleSubmit
      </div>*/}
      
      <div className="commonSlidePl">
        <div className="setting-form-section mt-5 mb-3">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-xxl-10 col-lg-10 mx-auto">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabIndex="1"
                  >
                    {/* Payment Mode */}
                    <div className="setting-form">
                      <div className="commonForm rounded-1">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Currency</label>
                                <select
                                  className="form-control"
                                  value={currency}
                                  onChange={(e) => { setCurrency(e.target.value) }}
                                >
                                  {
                                    Object.values(commonCurrency).map((row, idx) => {
                                      return <option key={idx} value={row.code}>{row.name} ( {row.symbol} )</option>
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                          {/* <h3>Paypal Method</h3>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>API Key</label>
                              <input
                                required
                                name="paypalPublicKey"
                                type="text"
                                className="form-control"
                                value={paypalPublicKey}
                                onChange={(e) =>
                                  setPaypalPublicKey(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>Secret Key</label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                value={paypalSecretKey}
                                onChange={(e) =>
                                  setPaypalSecretKey(e.target.value)
                                }
                                name="paypalSecretKey"
                              />
                            </div>
                          </div>
                        </div> */}
                          <h3 className="fs-5">Stripe Integration</h3>
                          <div className="row mb-4">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>API Key</label>
                                <input
                                  required
                                  name="stripePublicKey"
                                  type="text"
                                  className="form-control"
                                  value={stripePublicKey}
                                  onChange={(e) =>
                                    setStripePublicKey(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label>Secret Key</label>
                                <input
                                  required
                                  name="stripeSecretKey"
                                  type="text"
                                  className="form-control"
                                  value={stripeSecretKey}
                                  onChange={(e) =>
                                    setStripeSecretKey(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <h3 className="fs-5">Invoice</h3>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  required
                                  value={inputPrefixNumber}
                                  name="invoiceNumberPrefix"
                                  type="text"
                                  className="form-control"
                                  placeholder="INV-"
                                  onChange={(e) =>
                                    setInputPrefixNumber(e.target.value)
                                  }
                                />
                                <small>Invoice number prefix</small>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  required
                                  name="invoiceNumber"
                                  type="number"
                                  className="form-control"
                                  placeholder="e.g. 1000"
                                  value={invoiceNumber}
                                  onChange={(e) => setInvoiceNumber(e.target.value)}
                                />
                                <small>Starting invoice number</small>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group">
                                <textarea
                                  required
                                  name="companyDetails"
                                  className="form-control"
                                  placeholder="Company name, address, VAT number etc."
                                  value={companyDetails}
                                  onChange={(e) =>
                                    setCompanyDetails(e.target.value)
                                  }
                                ></textarea>
                                <small>Company details</small>
                              </div>
                              <div className="btn-section text-end mb-0">
                                <button className="btn btn-save">Save</button>
                              </div>
                            </div>
                          </div>
                        </form>
                        <hr />
                        <div className="d-flex align-items-center mt-4">
                          <h3 className="me-3">Enable Taxes</h3>
                          <Switch
                            onChange={handleToggleChange}
                            checked={isTaxesEnabled}
                            className="react-switch"
                            onHandleColor="#007D87"
                            handleDiameter={28}
                            onColor="#007D87"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={18}
                            width={45}
                          />
                          {isTaxesEnabled &&
                            <div className="ms-auto">
                              <button type="button" className="btn btn-rule ms-2" onClick={() => setIsAddRuleModalModalOpen(true)}>
                                <i className="fa-solid fa-plus"></i> Add Tax
                              </button>
                            </div>
                          }
                        </div>
                        {isTaxesEnabled &&
                          <>
                            {isAddRuleModalModalOpen && <AddTaxRuleModal
                              isAddRuleModalModalOpen={isAddRuleModalModalOpen}
                              handleAddRule={handleAddRule}
                              handleCancelModal={handleCancelModal}
                            />}
                            <div className="taxes-forms mt-3">
                              {/* <div className="form-group">
                              <label>Name</label>
                              <input
                                required
                                name="taxName"
                                type="text"
                                value={taxName}
                                className="form-control"
                                onChange={(e) => setTaxName(e.target.value) }
                                readOnly
                              />
                              <small>This is default rule of EU VAT</small>
                            </div> */}
                              {/* <div className="form-group">
                              <label>Home Country</label>
                              <select
                                className="form-control"
                                onChange={(e) => setHomeCountry(e.target.value)}
                                value={homeCountry}
                              >
                                {countries.map((item, idx) => {
                                  return (
                                    <option key={idx} value={item.name}>{item.name}</option>
                                  );
                                })}
                              </select>
                              <small>VAT will not be reverse charge from business customers in your country</small>
                            </div> */}
                              <AdminBillingAddress {...billingAddressFields} handleBillingAddressChange={handleBillingAddressChange} handleSelectChange={handleSelectChange} />
                              <div className="btn-section text-end mb-0 mt-4">
                                <button className="btn btn-save" onClick={handleSaveTaxes}>Save Taxes</button>
                              </div>

                              <div className="tax-countries">
                                <h3 className="mb-2">Tax Rules</h3>
                                <TaxRulesList taxRulesData={taxRules} handleTaxRuleChange={handleTaxRuleChange} handleDeleteTaxRule={handleDeleteTaxRule} />
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default Payment;
