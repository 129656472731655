import $ from "jquery";
import React, {
  Component,
  createRef,
  useEffect,
  useState,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import "../index.css";
window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");
const initialData = [
  {
    type: "paragraph",
    subtype: "p",
    label: "Question 1:What is you branch ?",
    access: false,
  },
  {
    type: "textarea",
    required: false,
    label: "answer",
    className: "form-control",
    name: "textarea-1672910238578",
    access: false,
    subtype: "textarea",
  },
  {
    type: "paragraph",
    subtype: "p",
    label: "Question 2: Why is your branch ?",
    access: false,
  },
  {
    type: "textarea",
    required: false,
    value: "hello ",
    label: "answer",
    className: "form-control",
    name: "textarea-1672910178641-0",
    access: false,
    subtype: "textarea",
  },
  {
    type: "paragraph",
    subtype: "p",

    label: "Question 3:)Upload the image of your Branch if any ?",
    access: false,
  },
  {
    type: "file",
    required: false,
    label: "File Upload",
    className: "form-control",
    name: "file-1672910248154-0",
    access: false,
    subtype: "file",
    multiple: false,
  },
];
const FormBuilder = (props) => {
  const ref = useRef();
  const [formData, setFormData] = React.useState(initialData);
  React.useEffect(() => {
    let options = {
      formData: props && props.initialFormData,
      onSave: function (formData) {
        props.setTemplateSaved(true);
        props.setCustomIntakeFormData(formBuilder.formData);
        props.setCurrActiveTab("Tasks");
        $(".render-wrap").formRender({ formData });
      },
    };
    var fbEditor = ref.current;
    var formBuilder = $(fbEditor).formBuilder(options);
  }, []);

  return (
    <>
      <div id="fb-editor" ref={ref} />
    </>
  );
};

export default FormBuilder;
