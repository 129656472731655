import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { fontAwesomeUrl } from "../../shared/constants";
import PageLoader from "../../components/pageLoader";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../../context";
import { saveSideMenuData } from "../../redux/action/sidemenu";
import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddCustomSideMenu from "./components/AddCustomSideMenu";

const LayoutSettings = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [isAddMenuModelOpen, setIsAddMenuModelOpen] = useState(false);
  const context = useContext(AllContext);
  const dispatch = useDispatch();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const {
    allApiUrl,
    toastMessage,
    redirectToLogin,
    apiGetMethod,
    apiPutMethod,
    apiPostMethod,
  } = context;
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const [menuData, setMenuData] = useState([]);
  const [menuItem, setMenuItem] = useState({});

  useEffect(() => {}, [menuItem]);

  function EditHandler(e) {
    // edit handler function
    e.preventDefault();
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {
      name: menuItem.name,
      url: menuItem.url,
      icon: menuItem.icon,
      newTab: menuItem.newTab,
      show: menuItem.show,
    };
    apiPutMethod(allApiUrl.EDITSIDENAVBARS + "/" + menuItem._id, data, header)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          toastMessage("success", "Edit Successfully");
          getSideBarMenus();
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  useEffect(() => {
    getSideBarMenus();
  }, []);

  async function getSideBarMenus() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.SIDENAVBARS, "", header);
      if (response.status === 200) {
        setMenuData(response.data);
        dispatch(saveSideMenuData(response.data));
        setInitialLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  /*const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    width: 900,
    ...draggableStyle
  });
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    width: 250
  });
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((o, k) => o.orderId = k)
    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempMenuData = reorder(
      menuData,
      result.source.index,
      result.destination.index
    );
    console.log('tem', tempMenuData);
    setMenuData(tempMenuData)
    resetSideBar();
  }

  const getItem = (item) => {
    return (
      <div>
        {item.id} {item.name}
      </div>
    );
  }*/

  const resetSideBar = (data) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPostMethod(allApiUrl.REORDER_SIDEBAR_MENU, data, header)
      .then(async (res) => {
        if (res.status === 200) {
          await getSideBarMenus();
          setIsLoading(false);
          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setIsLoading(false);
      });
  };

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let updatedList = [...menuData];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setMenuData(updatedList);
    resetSideBar(updatedList);
  };

  const handleSwitchToggleChange = (toggleChecked, selectedMenuItem) => {
    let updatedList = menuData.map((data) => {
      if (selectedMenuItem._id === data._id) {
        return { ...data, show: toggleChecked };
      }
      return data;
    });
    setMenuData(updatedList);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    selectedMenuItem.show = toggleChecked;
    apiPutMethod(
      allApiUrl.EDITSIDENAVBARS + "/" + selectedMenuItem._id,
      selectedMenuItem,
      header
    )
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          toastMessage("success", "Update Successfully");
          getSideBarMenus();
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleToggle = () => {
    setIsAddMenuModelOpen(false);
  };

  const openAddCutomMenuModal = () => {
    setIsAddMenuModelOpen(true);
  };

  const addmenuHandler = (formData) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.ADD_SUBMENU, formData, header)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          toastMessage("success", "Created Menu Item Successfully");
          getSideBarMenus();
          setIsAddMenuModelOpen(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        setIsAddMenuModelOpen(false);
      });
  };

  return (
    <div>
      <Sidebar
        activePage="clientSettings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="commonSlidePl">
        <div className="setting-form-section mt-5 mb-4">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-xxl-8 col-lg-8 mx-auto">
                <div className="d-flex align-items-center setting-form">
                  <h3 className="m-0">Layout</h3>
                  <div className="ms-auto btn-section">
                    <button
                      type="button"
                      className="btn btn-primary btn-save"
                      onClick={openAddCutomMenuModal}
                    >
                      Add Menu
                    </button>
                  </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabIndex="0"
                  >
                    <div className="setting-form">
                      <form className="commonForm rounded-1">
                        <div className="table-resposnive">
                          <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId="list-container">
                              {(provided) => (
                                <table
                                  className="table"
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <tbody>
                                    {menuData.map((item, index) => (
                                      <Draggable
                                        key={item._id}
                                        draggableId={item._id}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <tr
                                            key={index}
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                          >
                                            <td>
                                              <span className="me-2">
                                                <i className={item.icon}></i>
                                              </span>
                                              {item.name}
                                            </td>
                                            <td className="text-end">
                                              <Switch
                                                onChange={(toggleChecked) =>
                                                  handleSwitchToggleChange(
                                                    toggleChecked,
                                                    item
                                                  )
                                                }
                                                checked={item.show}
                                                className="react-switch"
                                                onHandleColor="#007D87"
                                                handleDiameter={20}
                                                onColor="#007D87"
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={12}
                                                width={32}
                                              />
                                            </td>
                                            <td>
                                              <div className="dropdown">
                                                <button
                                                  className="btn btn-secondary dropdown-toggle"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#editModal"
                                                      onClick={() => {
                                                        setMenuItem(item);
                                                      }}
                                                    >
                                                      Edit
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </tbody>
                                </table>
                              )}
                            </Droppable>
                          </DragDropContext>
                          {/*<DragDropContext onDragEnd={onDragEnd}>
                          <div>
                            <Droppable droppableId="Categories" type="droppableItem">
                              {(provided) => (
                                <div ref={provided.innerRef}>
                                  {sidebarMenuCategories.map((category, index) => (
                                    <Draggable
                                      draggableId={`category-${category.id}`}
                                      key={`category-${category.id}`}
                                      index={index}
                                    >
                                      {(parentProvider) => (
                                        <div
                                          ref={parentProvider.innerRef}
                                          {...parentProvider.draggableProps}
                                        >
                                          <Droppable droppableId={category.id.toString()}>
                                            {(provided) => (
                                              <div ref={provided.innerRef}>
                                                <ul className="list-unstyled">
                                                  <h6
                                                    className="h6 mb-3"
                                                    {...parentProvider.dragHandleProps}
                                                  >
                                                    {category.name}
                                                  </h6>
                                                  {menuData
                                                    .filter(
                                                      (item) => item.type === category.name
                                                    )
                                                    .map((item, index) => (
                                                      <Draggable
                                                        draggableId={item.id}
                                                        key={item.id}
                                                        index={index}
                                                      >
                                                        {(provided) => (
                                                          <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                          >
                                                            <li className="mb-3 d-flex align-items-center justify-content-between border p-3">
                                                              {getItem(item)}
                                                              <button className="btn btn-dark">
                                                                ...
                                                              </button>
                                                            </li>
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                    ))}
                                                  {provided.placeholder}
                                                </ul>
                                              </div>
                                            )}
                                          </Droppable>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}

                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </DragDropContext>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (

                                <table className="table">
                                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                  {menuData.map((role, index) => (
                                    <Draggable key={role._id} draggableId={role._id} index={index}>
                                      {(provided, snapshot) => (
                                        <tr key={role._id} ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                        >
                                          <td>
                                      <span>
                                        <i className={role.icon}></i>
                                      </span>&nbsp;
                                            {role.name}
                                          </td>
                                          <td>
                                            <div className="dropdown">
                                              <button
                                                className="btn btn-secondary dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                              </button>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editModal"
                                                    onClick={() => {
                                                      setMenuItem(role);
                                                    }}
                                                  >
                                                    Edit
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                  </tbody>
                                </table>
                              )}
                            </Droppable>
                          </DragDropContext>*/}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
      {/* Delete Modal  */}
      {/* <div
        className="modal fade history-modal"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Are you sure you want to delete the Column
                  <span style={{ fontWeight: "bold" }}> {menuName} ?</span>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={deleteHandler}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {isAddMenuModelOpen && (
        <AddCustomSideMenu
          isAddMenuModelOpen={isAddMenuModelOpen}
          handleToggle={handleToggle}
          addmenuHandler={addmenuHandler}
          menuDataLength={menuData.length}
        />
      )}

      {/* Edit Modal  */}
      <div
        className="modal fade history-modal"
        id="editModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label className="mb-3">Link Text</label>
                      <input
                        type="text"
                        name="text"
                        className="form-control"
                        placeholder=""
                        defaultValue={menuItem?.name}
                        required
                        onChange={(e) => (menuItem.name = e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label className="mb-3">Link URL</label>
                      <input
                        type="text"
                        name="text"
                        className="form-control"
                        placeholder=""
                        defaultValue={menuItem?.url}
                        required
                        onChange={(e) => (menuItem.url = e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label className="mb-3">Link icon class</label>
                      <input
                        type="text"
                        name="text"
                        className="form-control"
                        placeholder=""
                        defaultValue={menuItem?.icon}
                        required
                        onChange={(e) => (menuItem.icon = e.target.value)}
                      />
                      <small>
                        Choose any icon from{" "}
                        <a target="_blank" href={fontAwesomeUrl}>
                          FontAwesome
                        </a>{" "}
                        and copy its class here
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={!!menuItem?.newTab}
                        onChange={(e) => (menuItem.newTab = !!e.target.checked)}
                      />
                      &nbsp;
                      <label>Open in new tab</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={EditHandler}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LayoutSettings;