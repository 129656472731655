
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import logo from "../../assets/img/logo.svg";
import imgSignin from "../../assets/img/imgSignin.svg";
import userIcon from "../../assets/img/iconUser.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AllContext } from "../../context";
import { loginAPi } from "../../api/authApi/login";
import PageLoader from "../../components/pageLoader";
import { saveSuperAdminAccessToken, saveSuperAdminLoginInfo } from "../../redux/action/login";
import { useSelector } from "react-redux";

const initialValues = {
    email: "",
    password: "",
};

const LoginSchema = Yup.object({
    email: Yup.string().min(2).max(50).required("Please enter your name"),
    password: Yup.string().required("Please enter your password"),
});

const SuperAdminLogin = () => {
    const {isSuperAdminLoggedIn} = useSelector((state) => state?.loginReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const context = useContext(AllContext);
    const { allApiUrl, toastMessage } = context;
    const [isLoading, setIsLoading] = useState(false);
    const [passwordView, setPasswordView] = useState("password");

    useEffect(() => {
        if(isSuperAdminLoggedIn) {
            navigate("/super-admin/dashboard");
        }
    }, []);

    const handleFormSubmit = (values) => {
        setIsLoading(true);

        const data = {
          email: values.email,
          password: values.password,
        };
        loginAPi(allApiUrl.SUPER_ADMIN_LOGIN, data, {})
          .then((res) => {
            setIsLoading(false);
            dispatch(saveSuperAdminAccessToken(res.data.token));
            dispatch(saveSuperAdminLoginInfo(res.data));
            navigate("/super-admin/dashboard");
          }).catch((err) => {
            setIsLoading(false);
            toastMessage("warn", err.data?.message);
        });
    }

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values) => {
          handleFormSubmit(values);
        },
    });
    return (
        <main className="wrapper">
            <PageLoader isLoading={isLoading} />

            <div className="section-login">
                <div className="container-fluid">
                    <div className="row h-100vh">
                        <div className="col-lg-6 extralightGreyBg formOrder formGrid">
                        <div className="loginForm">
                            <a className="brandName" href="#">
                            <img src={logo} />
                            </a>
                            <form className="formLogin" onSubmit={handleSubmit}>
                                <h3>Super Admin Sign In! Enter account details</h3>
                                <div className="formInner">
                                    <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Email"
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        required
                                    />
                                    <label htmlFor="floatingInput">Email</label>

                                    <span className="formIcon">
                                        <img src={userIcon} />
                                    </span>
                                    </div>
                                    <div className="form-floating mb-3">
                                    <input
                                        type={passwordView}
                                        className="form-control"
                                        id="floatingPassword"
                                        placeholder="Password"
                                        name="password"
                                        onChange={handleChange}
                                        value={values.password}
                                        required
                                    />
                                    <span className="eyeIcon fa-solid fa-key"
                                        onClick={() => {
                                        if (passwordView == "password") {
                                            setPasswordView("text");
                                        } else {
                                            setPasswordView("password");
                                        }
                                        }}
                                    ></span>
                                    <label htmlFor="floatingPassword">Password</label>
                                    </div>
                                    <div className="input-group">
                                        <button className="btn btn-primary btn-submit">
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="formFooter">
                            <p className="copyrightText">© AgencyCRM. All Rights Reserved.{" "}</p>
                        </div>
                        </div>
                        <div className="col-lg-6 lightGreyBg border formGrid">
                        <div className="loginImg">
                            <img src={imgSignin} />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default SuperAdminLogin;