import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import DummyAvatar from "../../assets/img/dummyImg.jpeg";
import PageLoader from "../../components/pageLoader";
import noData from "../../assets/img/nodata-img.svg";
import openIcon from "../../assets/img/icons/openSide.svg";
import sortIcon from "../../assets/img/icons/sortIcon.svg";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import {
  convertToUpperCase,
  formatMonthNameAndDate,
} from "../../shared/functions";
import ReactTooltip from "react-tooltip";
import LoadingOverlay from "react-loading-overlay";
import * as _ from "lodash";
import { SpinnerCircularFixed } from "spinners-react";
import FormElementGenerate from "../../components/FormElementsEdit/FormElementGenerate";
import PaginationNewUI from "../../components/PaginationNewUI";
import SelectAsync from "../../components/SelectAsync";
LoadingOverlay.propTypes = undefined;

const Orders = () => {
  const { state } = useLocation();
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    allApiUrl,
    toastMessage,
    redirectToLogin,
    apiGetMethod,
    apiPostMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const roleList = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  //useState
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [serviceListArr, setServiceListArr] = useState([]);
  const [orderAddedSuccessfully, setOrderAddedSuccessfully] = useState(false);
  const [allTeam, setAllTeam] = useState([]);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [countStatus, setCountStatus] = useState({});
  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [jsonData, setJsonData] = useState([]);

  const [selectedWorkflow, setSelectedWorkflow] = useState({} || null );
  const [selectedService, setSelectedService] = useState([] | null);
  const [selectedTag, setSelectedTag] = useState([] | null);
  const [selectedClient, setSelectedClient] = useState([] | null);
  const [selectedTeam, setSelectedTeam] = useState([] | null);
  const [selectedDatePicker, setSelectedDatePicker] = useState({} | null);
  const [selectedOrderClient, setSelectedOrderClient] = useState({} | null);
  const [selectedOrderService, setSelectedOrderService] = useState({} | null);

  const [seed, setSeed] = useState(1);

  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };

  useEffect(() => {
    setSeed(Math.random());
    setSelectedService([...[]]);
  }, [selectedWorkflow]);

  useEffect(() => {
    if (selectedWorkflow?.value) {
      filterOrders();
    }
  }, [
    state && state.orderId,
    orderAddedSuccessfully,
    activeTab,
    itemOffset,
    itemsPerPage,
    selectedWorkflow?.value,
  ]);

  useEffect(() => {
    setItemOffset(1);
  }, [activeTab]);

  useEffect(() => {
    getAllServices();

    if (!serviceListArr.length) {
      setIsLoadingFilters(true);
    }
  }, []);

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const filterOrders = (orderId = null) => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let data = {};
    if (orderId) {
      data.orderId = orderId;
    }
    if (selectedTag.length) {
      data.tags = _.map(selectedTag, "value");
    }
    if (selectedTeam.length > 0) {
      data.assignedTo = _.map(selectedTeam, "value");
    }
    if (selectedClient.length > 0) {
      data.client = _.map(selectedClient, "value");
    }
    if (selectedService.length > 0) {
      data.service = _.map(selectedService, "value");
    }
    if (selectedWorkflow && selectedWorkflow.value) {
      data.workflow = [selectedWorkflow.value];
    }

    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;

    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.status = activeTab;

    apiPostMethod(allApiUrl.FILTER_ORDERS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setOrderList([...res.data.filteredOrders]);
          setCountStatus({ ...res.data.countStatus });
          setTabs(res.data.tabList);
          setTotalRecordsCount(res.data.totalItems);
          setInitialLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
        setIsLoading(false);
      });
  };

  function getAllServices() {
    apiGetMethod(allApiUrl.SERVICES, "", header)
      .then((res) => {
        let options = [];
        res.data.data.map((item) => {
          options.push({
            value: item._id,
            label: (
              <>
                {item.name || " "} {"\n"}
                <small>${item.price.toFixed(2)}</small>
              </>
            ),
            ...item,
          });
        });
        // setServiceListArrBkup(options);
        setServiceListArr(options);
        // setServiceAllListArrBkup(options);
        setIsLoadingFilters(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setIsLoadingFilters(false);
      });
  }
  async function addOrderHandler() {
    setIsLoading(true);
    const data = {
      userId: selectedOrderClient.value,
      productId: selectedOrderService.value,
      ordersCrmFields: jsonData,
    };
    try {
      const response = await apiPostMethod(allApiUrl.ORDER, data, header);
      if (response.status === 200) {
        toastMessage("success", response.data.message);
        setOrderAddedSuccessfully(!orderAddedSuccessfully);
        setSelectedOrderClient(null);
        setSelectedOrderService(null);
        setJsonData([]);
        setIsLoading(false);
        navigate(`/view-orders/${response.data.orderId}`, {
          state: {
            _id: response.data._id,
          },
        });
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  // Order Table
  const [multiDeleteId, setMultiDeleteId] = useState([]);
  const [isChangeStatusVisible, setIsChangeStatusVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [orderStatus, setOrderStatus] = useState("pending");
  useEffect(() => {
    setMultiDeleteId([]);
  }, [activeTab]);

  const onChangeCheckbox = (id, val) => {
    if (val) {
      multiDeleteId.push(id);
      setMultiDeleteId([..._.uniq(multiDeleteId)]);
    } else {
      _.remove(multiDeleteId, (o) => o === id);
      setMultiDeleteId([...multiDeleteId]);
    }
  };
  function selectOption(selected) {
    if (selected.target.value === "changeStatus") {
      setIsChangeStatusVisible(true);
    } else {
      setIsChangeStatusVisible(false);
    }
    setSelectedOption(selected.target.value);
  }
  function orderStatusOption(selected) {
    setOrderStatus(selected.target.value);
  }

  function deleteMultiple() {
    setIsLoading(true);
    apiPostMethod(allApiUrl.DELETE_ORDER_BULK, { ids: multiDeleteId }, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        filterOrders();
        setMultiDeleteId([...[]]);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function updateStatusMultiple(status) {
    apiPostMethod(
      allApiUrl.UPDATE_ORDER_STATUS_BULK,
      {
        ids: multiDeleteId,
        status: status,
      },
      header
    )
      .then((res) => {
        setMultiDeleteId([...[]]);
        toastMessage("success", res.data.message);
        setOrderAddedSuccessfully(!orderAddedSuccessfully);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function checkedAll() {
    if (orderList.length === multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = orderList.map((e) => {
        return e._id;
      });
      setMultiDeleteId(ids);
    }
  }

  const getTabCount = (val) => {
    return countStatus[val];
  };
  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      if (getTabCount(item)) {
        tempArray.push(
          <li key={idx} className="nav-item" role="presentation">
            <button
              className={
                activeTab.toLowerCase() === item.toLowerCase()
                  ? "nav-link active"
                  : "nav-link"
              }
              id="pills-overdue-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-overdue"
              type="button"
              role="tab"
              aria-controls="pills-overdue"
              aria-selected="true"
              onClick={() => {
                setMultiDeleteId([]);
                setActiveTab(item);
              }}
            >
              {convertToUpperCase(item)}
              <span>({getTabCount(item)})</span>
            </button>
          </li>
        );
      }
    });
    return tempArray;
  };
  const onClearFilters = () => {
    setSelectedTag([...[]]);
    setSelectedTeam([...[]]);
    setSelectedClient([...[]]);
    // setSelectedWorkflow();
    setSelectedService([...[]]);
    setSelectedDatePicker(null);

    setstartDateFilter(0);
    setendDateFilter(0);
    if (orderAddedSuccessfully)
      setOrderAddedSuccessfully(!orderAddedSuccessfully);
    setActiveTab("all");
  };

  return (
    <div>
      <Sidebar
        activePage="orders"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} propSetAllClientList={setAllTeam} />
      <PageLoader isLoading={initialLoading} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Orders <span className="userCounter">&nbsp;</span>
                    <button
                      className="btn btn-addOrder"
                      data-bs-toggle="modal"
                      data-bs-target="#addorderModal"
                    >
                      Add Order
                    </button>
                  </h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>

              <PaginationNewUI
                itemsPerPage={itemsPerPage}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                pageCount={pageCount}
                totalRecordsCount={totalRecordsCount}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </div>
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid">
            <LoadingOverlay
              styles={{
                spinner: (base) => ({
                  ...base,
                  width: "50px",
                  "& svg circle": {
                    stroke: "#007D87",
                  },
                }),
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 0.5)",
                }),
              }}
              active={isLoading}
              spinner
              text="Loading content..."
            >
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className={
                      initialLoading
                        ? "topFilters flex-wrap opacity-0"
                        : "topFilters flex-wrap"
                    }
                  >
                    <p>Filter By:</p>
                    <SelectAsync
                      placeholder="Any WorkFlow"
                      searchType="workflows"
                      selectedValue={selectedWorkflow}
                      onChange={(e) => {
                        setSelectedWorkflow({
                          label: e?.label,
                          value: e.value,
                        });
                        setJsonData(e?.workFlowCrmFields);
                      }}
                    />
                    <SelectAsync
                      key={seed}
                      isMulti
                      placeholder="Any Workflow Service"
                      searchType="services"
                      selectedValue={selectedService}
                      dataParam={{ workflowId: selectedWorkflow?.value }}
                      onChange={(list) => {
                        const data = list.map((e) => {
                          const label =
                            typeof e.label === "object" && e.label !== null
                              ? e?.label?.props?.children[0]
                              : e?.label;
                          return {
                            label,
                            value: e.value,
                          };
                        });
                        setSelectedService(data);
                      }}
                    />

                    <SelectAsync
                      isMulti
                      placeholder="Any Tags"
                      searchType="tags"
                      selectedValue={selectedTag}
                      onChange={(list) => {
                        const data = list.map((e) => {
                          const label =
                            typeof e.label === "object" && e.label !== null
                              ? e?.label?.props?.children[0]
                              : e?.label;
                          return {
                            label,
                            value: label, // tags search by name
                          };
                        });
                        setSelectedTag(data);
                      }}
                    />
                    <SelectAsync
                      isMulti
                      placeholder="Any Assigned User"
                      searchType="teams"
                      selectedValue={selectedTeam}
                      onChange={(list) => {
                        const data = list.map((e) => {
                          return {
                            label:
                              typeof e.label === "object" && e.label !== null
                                ? e?.label?.props?.children[0]
                                : e?.label,
                            value: e.value,
                          };
                        });
                        setSelectedTeam(data);
                      }}
                    />
                    <SelectAsync
                      isMulti
                      placeholder="Any Client"
                      searchType="multiClients"
                      selectedValue={selectedClient}
                      onChange={(list) => {
                        const data = list.map((e) => {
                          return {
                            label:
                              typeof e.label === "object" && e.label !== null
                                ? e?.label?.props?.children[0]
                                : e?.label,
                            value: e.value,
                          };
                        });
                        setSelectedClient(data);
                      }}
                    />
                    <SelectAsync
                      placeholder="Any Date"
                      searchType="date"
                      selectedValue={selectedDatePicker}
                      onChange={(e) => {
                        setSelectedDatePicker({
                          label: e?.label,
                          value: e.value,
                        });
                      }}
                    />

                    {!!(selectedDatePicker && selectedDatePicker?.value) && (
                      <div className="d-flex dateBetween-filter align-items-center">
                        <input
                          type="date"
                          name="startDate"
                          className="form-control date-input"
                          onChange={(event) =>
                            setstartDateFilter(
                              new Date(event.target.value).getTime()
                            )
                          }
                        />
                        <span className="mx-2">to</span>
                        <input
                          className="form-control date-input"
                          type="date"
                          name="endDate"
                          onChange={(event) =>
                            setendDateFilter(
                              new Date(event.target.value).getTime()
                            )
                          }
                          id=""
                        />
                      </div>
                    )}

                    {isLoadingFilters && (
                      <button className="btn">
                        <SpinnerCircularFixed
                          size="25"
                          secondaryColor=""
                          color="#201b50"
                          thickness="200"
                        />
                      </button>
                    )}
                    <button className="btn btn-clear" onClick={onClearFilters}>
                      Clear
                    </button>
                    <button
                      className="btn btn-update"
                      onClick={() => filterOrders()}
                    >
                      Apply
                    </button>
                  </div>
                  {!initialLoading && (
                    <div className="common-tabs orderTabs">
                      <ul
                        className="nav nav-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <TabsList />
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-overdue"
                          role="tabpanel"
                          aria-labelledby="pills-overdue-tab"
                          tabIndex="0"
                        >
                          <div className="common-table TableOrders rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        checked={
                                          orderList?.length &&
                                          orderList?.length ===
                                            multiDeleteId?.length
                                        }
                                        onChange={() => checkedAll()}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      ></label>
                                    </div>
                                  </th>
                                  <th>
                                    Client{" "}
                                    <span>
                                      <img src={sortIcon} />
                                    </span>
                                  </th>
                                  <th>
                                    Assigned Team Member{" "}
                                    <span>
                                      <img src={sortIcon} />
                                    </span>
                                  </th>
                                  <th>
                                    Status{" "}
                                    <span>
                                      <img src={sortIcon} />
                                    </span>
                                  </th>
                                  <th>
                                    Added{" "}
                                    <span>
                                      <img src={sortIcon} />
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderList.length ? (
                                  orderList.map((item, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td className="ps-4 w65-px">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={"order" + item._id}
                                              checked={
                                                !!_.includes(
                                                  multiDeleteId,
                                                  item._id
                                                )
                                              }
                                              onChange={(e) => {
                                                onChangeCheckbox(
                                                  item._id,
                                                  e.target.checked
                                                );
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={
                                                "order" + item.userId?._id
                                              }
                                            ></label>
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            className="clientDetail"
                                            onClick={() =>
                                              navigate(
                                                `/view-orders/${item.orderId}`,
                                                {
                                                  state: {
                                                    _id: item._id,
                                                    formType: item.inTakeForm,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            <p className="name mb-1">
                                              <a>
                                                {item.userId &&
                                                item.userId.firstName
                                                  ? `${item.userId.firstName} ${
                                                      item.userId?.lastName !=
                                                      undefined
                                                        ? item.userId.lastName
                                                        : ""
                                                    }`
                                                  : item?.userId?.email}

                                                {!!item.message && (
                                                  <>
                                                    <span className="ms-1">
                                                      <i className="fa-solid fa-envelope"></i>
                                                      {item.message}
                                                    </span>
                                                  </>
                                                )}
                                                {/* </span> */}
                                                {Object.values(item.tags).map(
                                                  (tagItem, idx) => {
                                                    return (
                                                      <span
                                                        key={idx}
                                                        className="mx-1"
                                                        style={{}}
                                                      >
                                                        {tagItem}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </a>
                                            </p>
                                            <p className="subject">
                                              <a href="#">{item.title} </a>
                                              {item.price && (
                                                <span>${item.price}</span>
                                              )}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="assignedImg d-flex align-items-center">
                                            {item.assignedTo.map(
                                              (item, idx) => {
                                                return (
                                                  <div key={idx} className="me-2">
                                                    <img
                                                      className="ms-n1"
                                                      src={
                                                        item.image
                                                          ? item.image
                                                          : DummyAvatar
                                                      }
                                                      data-tip={
                                                        item.firstName +
                                                        " " +
                                                        item.lastName
                                                      }
                                                    />
                                                    <ReactTooltip />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          <span
                                            className="pill pill-grey orderStatus fw-normal"
                                            style={{
                                              backgroundColor:
                                                item.orderStatus &&
                                                item.orderStatus.bgColor &&
                                                item.orderStatus.bgColor,
                                              color:
                                                item.orderStatus &&
                                                item.orderStatus.textColor &&
                                                item.orderStatus.textColor,
                                            }}
                                          >
                                            {item.orderStatus &&
                                            item.orderStatus.name
                                              ? item.orderStatus.name.toUpperCase()
                                              : "N/A"}
                                          </span>
                                        </td>
                                        <td>
                                          {formatMonthNameAndDate(
                                            item.createdDate,
                                            companyData.timezone
                                          )}
                                          {/* <br /> <small>due 23th from now</small> */}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {multiDeleteId.length > 0 && (
                            <div className="table-bottom-option">
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  <div className="input-group">
                                    <select
                                      id="selectOption"
                                      value={selectOption.target}
                                      onChange={(e) => selectOption(e)}
                                      className="form-control"
                                    >
                                      <option value="">With selection</option>
                                      <option value="changeStatus">
                                        Change Status
                                      </option>
                                      {roleList?.orderManagement?._delete && (
                                        <option value="Delete">Delete</option>
                                      )}
                                    </select>

                                    {isChangeStatusVisible && (
                                      <select
                                        id="orderStatusOption"
                                        value={orderStatusOption.target}
                                        onChange={(e) => orderStatusOption(e)}
                                        style={{
                                          marginLeft: "4px",
                                          marginRight: "4px",
                                        }}
                                        className="form-control"
                                      >
                                        <option value="pending">Pending</option>
                                        <option value="submitted">
                                          Submitted
                                        </option>
                                        <option value="working">Working</option>
                                        <option value="completed">
                                          Completed
                                        </option>
                                        <option value="cancelled">
                                          Cancelled
                                        </option>
                                        <option value="pending update">
                                          Pending Update
                                        </option>
                                        <option value="void">Void</option>
                                        <option value="info lacking">
                                          Info Lacking
                                        </option>
                                        <option value="preparing draft">
                                          Preparing Draft
                                        </option>
                                        <option value="draft for approval">
                                          Draft For Approval
                                        </option>

                                        <option value="editor is reviewing draft">
                                          Editor Is Reviewing Draft
                                        </option>

                                        <option value="edits are being made">
                                          Edits Are Being Made
                                        </option>

                                        <option value="edits done">
                                          Edits Done
                                        </option>

                                        <option value="draft for publication">
                                          Draft For Publication
                                        </option>

                                        <option value="needs response">
                                          Needs Response
                                        </option>
                                      </select>
                                    )}
                                    <button
                                      className="btn btn-apply"
                                      onClick={() => {
                                        if (selectedOption == "Delete") {
                                          deleteMultiple();
                                        } else if (
                                          selectedOption == "changeStatus"
                                        ) {
                                          updateStatusMultiple(orderStatus);
                                          setIsChangeStatusVisible(false);
                                        }
                                      }}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
        <div
          className="modal fade add-order-modals"
          id="addorderModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add order
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="add-order-form">
                  <div className="add-alert">
                    <p>
                      Adding an order manually will not trigger any
                      notifications or payments. To add a paid order you can
                      create a new invoice and mark it as paid.
                    </p>
                  </div>
                  <form>
                    <div className="form-group">
                      <label>Service</label>
                      <SelectAsync
                        key={seed}
                        placeholder="Any Service"
                        searchType="services"
                        selectedValue={selectedOrderService}
                        dataParam={{ workflowId: selectedWorkflow?.value }}
                        onChange={(e) => {
                          const label =
                            typeof e.label === "object" && e.label !== null
                              ? e?.label?.props?.children[0]
                              : e?.label;
                          setSelectedOrderService({
                            label,
                            value: e.value,
                          });

                          if (
                            e?.defaultAssignee &&
                            e?.defaultAssignee !== "*"
                          ) {
                            const getDefaultClient = allTeam.find(
                              (data) => data.userId?._id === e.defaultAssignee
                            );
                            const client = {
                              value: getDefaultClient?.userId?._id,
                              label: (
                                <>
                                  {convertToUpperCase(
                                    getDefaultClient?.userId?.firstName
                                  )}
                                  <br />
                                  <small>
                                    {getDefaultClient?.userId?.email}
                                  </small>
                                </>
                              ),
                            };
                            const label =
                              typeof client.label === "object" &&
                              client.label !== null
                                ? client?.label?.props?.children[0]
                                : client?.label;
                            setSelectedOrderClient({
                              label,
                              value: client.value,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Client</label>
                      <SelectAsync
                        placeholder="Add orders"
                        searchType="clients"
                        selectedValue={selectedOrderClient}
                        onChange={(e) => {
                          setSelectedOrderClient({
                            label:
                              typeof e.label === "object" && e.label !== null
                                ? e?.label?.props?.children[0]
                                : e?.label,
                            value: e.value,
                          });
                        }}
                      />
                    </div>

                    <FormElementGenerate
                      jsonData={jsonData}
                      setJsonData={setJsonData}
                      showCardUI={false}
                    />
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary close-btn"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setSelectedOrderClient(null);
                    setSelectedOrderService(null);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={addOrderHandler}
                >
                  Add order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Orders;
