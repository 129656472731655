import React, { useContext, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import FormBuilder from "../../components/FormBuilder";
import { editorApiKey } from "../../shared/constants";
import openIcon from "../../assets/img/icons/openSide.svg";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import AddEditTaskModal from "./addEditTaskModal";
const AddService = () => {
  const editorRef = useRef(null);
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { SERVICES, ADD_SERVICE, MESSAGE, TAGS } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { currency } = useSelector((state) => state.sideMenuReducer.paymentSettingData);
  const { teamsData } = useSelector((state) => state.teamReducer);
  const navigate = useNavigate();
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [newTag, setNewTag] = useState([]);
  const [tag, setTag] = useState("");
  const [services, setServices] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [payLater, setPayLater] = useState(false);
  const [addOn, setAddOn] = useState({});
  const [image, setImage] = useState();
  const [orderService, setOrderService] = useState("");
  const [filterTags, setFilterTags] = useState([]);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [duration, setDuration] = useState("months");
  const [durationQuantity, setDurationQuantity] = useState(0);
  const [intakeFormStatus, setIntakeFormStatus] = useState(false);
  const [oneTimeService, setOneTimeService] = useState(true);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [selectedIntakeForm, setSelectedIntakeForm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [priceRecurring, setPriceRecurring] = useState(0);
  const [newOrder, setNewOrder] = useState("newOrder");
  const [templateSaved, setTemplateSaved] = useState(false);
  const [customIntakeFormData, setCustomIntakeFormData] = useState([]);
  const [currActiveTab, setCurrActiveTab] = useState("Edit");
  const [messages, setMessages] = useState([]);
  const [selectedMessageTemplateId, setSelectedMessageTemplateId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({
    addEdit: false,
    delete: false
  })

  //task module states
  const [task, setTask] = useState({
    taskName: "",
    description: "",
    deadline: 0,
    deadlineValue: "day",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [delectTask, setDeleteTask] = useState("");
  const [editTask, setEditTask] = useState("");
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [selectedWorkflowOrderStatuses, setSelectedWorkflowOrderStatuses] = useState([]);
  const [defaultWorkflowOrderStatus, setDefaultWorkflowOrderStatus] = useState('');
  const [workflowData, setWorkflowData] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [clientListArr, setClientListArr] = useState([]);
  const [defaultAssignee, setDefaultAssignee] = useState("*");
  const businessId = useSelector(
    (state) => state.loginReducer.loginUserInfo.businessId
  );
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  function handleFormSubmit() {
    updateProfile();
  }
  function handleEditorChange(e) {
    setDescription(e);
  }
  useEffect(() => {
    getAllServices();
    getAllMessages();
    getWorkFlows();
  }, []);

  useEffect(() => {
    let options = [];
    allClients.map((item) => {
      if (item.userId) {
        options.push({
          value: item.userId._id,
          label: `${item.userId.firstName || " "} ${item.userId.lastName || ""
            } - ${item.userId.email}`,
        });
      }
    });
    setClientListArr(options);
  }, [allClients]);

  function getFilteredTags(value) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(TAGS, `/${value}`, header)
      .then((res) => {
        if (res.status === 200) {
          setFilterTags(res.data.tags);
        }
      })
      .catch((err) => {
        toastMessage("error", err);
      });
  }
  const addTag = () => {
    for (let i = 0; i < newTag.length; i++) {
      const data = {
        name: newTag[i],
      };
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiPostMethod(TAGS, data, header)
        .then((res) => {
          if (res.status === 201) {
            newTag.push(tag);
            setTag("");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  function getAllServices() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(SERVICES, "", header)
      .then((res) => {
        setServices(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  const getAllMessages = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(MESSAGE, "", header)
      .then((res) => {
        setMessages(res.data.data);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  };

  const updateProfile = async () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
      "Content-Type": "multipart/form-data",
    };
    let recurringOrder = {
      status: !oneTimeService && true,
      durationTime: durationQuantity, //value
      durationPeriod: duration, //type (month,days,weeks)
      newOrder: newOrder == "newOrder" ? false : true,
    };
    const formData = new FormData();
    formData.append("imageUrl", image ? image : "");
    formData.append("name", serviceName.trim());
    formData.append("price", oneTimeService == true ? price : priceRecurring);
    formData.append("currency", currency?.code ? currency?.code : "USD");
    formData.append("description", description);
    formData.append("tags", JSON.stringify(newTag));
    formData.append("orderCount", quantity);
    formData.append("addOn", addOn);
    formData.append("public", visibility);
    formData.append("oneTimeService", oneTimeService);
    formData.append("tasks", JSON.stringify(taskList));
    formData.append("payLater", JSON.stringify(payLater));
    formData.append("workflowId", selectedWorkflow);
    formData.append("workflowOrderStatusId", defaultWorkflowOrderStatus);
    formData.append("defaultAssignee", defaultAssignee);
    formData.append("businessId", businessId);
    if (!!selectedMessageTemplateId !== false) {
      formData.append("messageTemplateId", selectedMessageTemplateId);
    }
    let obj = {};
    if (templateSaved == true) {
      obj = JSON.parse(customIntakeFormData);
    } else {
      obj = [];
    }
    formData.append("intakeForm", obj.length == 0 ? "no_form" : "forms");
    formData.append("customForm", JSON.stringify(obj));
    formData.append("recurringOrder", JSON.stringify(recurringOrder));
    setIsLoading(true);
    apiPostMethod(ADD_SERVICE, formData, header)
      .then((res) => {
        if (res.status === 200) {
          addTag();
          toastMessage("success", res.data.message);
          setIsLoading(false);
          navigate("/services");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  function addTaskHandler() {
    if (task && task.taskName) {
      setTaskList((prev) => [...prev, task]);
      setTask({
        taskName: "",
        description: "",
        deadline: 0,
        deadlineValue: "day",
      });
      handleCancelModal()
    } else {
      toastMessage(
        "error",
        "Task name is not provided . please Enter Task Name"
      );
    }
  }
  function deleteTaskHandler() {
    const removeItem = taskList.filter((taskItem) => {
      return taskItem.taskName !== delectTask;
    });
    setTaskList(removeItem);
    setDeleteTask("");
    handleCancelModal()
  }
  function setEditValue(value) {
    setIsEditMode(true);
    setTask(value);
    setEditTask(value.taskName);
  }
  function editHandler() {
    if (task && task.taskName) {
      const update_obj = taskList.map((obj) => {
        if (obj.taskName == editTask) {
          return { ...task };
        }
        return obj;
      });
      setTaskList(update_obj);
      handleCancelModal()
    } else {
      toastMessage("error", "Task name is not provided please Enter Task Name");
    }
  }

  const selectMessageOption = (e) => {
    setSelectedMessageTemplateId(e.target.value);
  };

  const selectWorkflowOption = (e) => {
    setSelectedWorkflow(e.target.value);
    const workflowOrderStatusData = workflowData.find(data => data._id === e.target.value);
    setSelectedWorkflowOrderStatuses(workflowOrderStatusData.orderStatuses)
    setDefaultWorkflowOrderStatus(workflowOrderStatusData.orderStatuses[0]._id)
  };

  const selectWorkflowOrderOption = (e) => {
    setDefaultWorkflowOrderStatus(e.target.value);
  };

  const getWorkFlows = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_WORKFLOWS, "", header)
      .then(async (res) => {
        if (res.status === 200) {
          const workflowValues = res.data;
          if (workflowValues.length) {
            setWorkflowData(workflowValues);
            const getDefaultWorkflow = workflowValues.findIndex(data => data.isDefaultWorkflow);
            if (getDefaultWorkflow !== -1) {
              setSelectedWorkflow(workflowValues[getDefaultWorkflow]._id);
              if (workflowValues[getDefaultWorkflow]?.orderStatuses.length) {
                setSelectedWorkflowOrderStatuses(workflowValues[getDefaultWorkflow]?.orderStatuses);
                setDefaultWorkflowOrderStatus(workflowValues[getDefaultWorkflow]?.orderStatuses[0]._id);
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }

  const handleCancelModal = () => {
    setIsModalOpen({
          delete: false,
          addEdit: false
        });
  }

  return (
    <div>
      <Sidebar
        activePage="services"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} propSetAllClientList={setAllClients} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Services <span className="userCounter">&nbsp;</span>
                  </h2>
                  <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="setting-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12">
                
              </div>
            </div>
          </div>
        </div>

        <div className="setting-form-section pt-3 horizontalPadding">
            <div className="container-fluid">
              <div className="row">

                <div className="col-xxl-12 col-xl-12">
                  <div className="common-tabs serviceTabs mt-4">
                      <ul className="nav nav-pills" id="pills-tab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                currActiveTab == "Edit" ? "nav-link active" : "nav-link"
                              }
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                              onClick={() => setCurrActiveTab("Edit")}
                            >
                              Edit
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                currActiveTab == "InTakeForm"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                              disabled={serviceName == ""}
                              onClick={() => setCurrActiveTab("InTakeForm")}
                            >
                              Intake form
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                currActiveTab == "Tasks"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              id="pills-task-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-task"
                              type="button"
                              role="tab"
                              aria-controls="pills-task"
                              aria-selected="false"
                              onClick={() => setCurrActiveTab("Tasks")}
                              disabled={serviceName == ""}
                            >
                              Tasks
                            </button>
                          </li>
                        </ul>
                    </div>
                  <div className="setting-form">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className={`tab-pane serviceTabs fade ${currActiveTab == "Edit" && "show active"
                          }`}
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabIndex="0"
                      >

                        <div className="row">
                          <div className="col-lg-9">
                            
                            <div className="tabs-content mt-3">
                              
                            </div>
                            <div className="setting-form ticketForm formOuter">
                              <div className="commonForm rounded-1 px-4 pt-4 pb-4">
                                <h3 className="fs-4 m-0 mb-4 p-3 pt-0">Add Service</h3>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <label>Service Name</label>
                                      <input
                                        type="text"
                                        required
                                        className="form-control"
                                        placeholder="My Service"
                                        value={serviceName}
                                        onChange={(e) => {
                                          setServiceName(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <label>
                                        Description <small>Optional</small>
                                      </label>
                                      <Editor
                                        required
                                        apiKey={editorApiKey}
                                        onInit={(evt, editor) =>
                                          (editorRef.current = editor)
                                        }
                                        value={description}
                                        init={{
                                          forced_root_block: "",
                                          selector: "textarea",
                                          height: 500,
                                          menu: {
                                            custom: {
                                              title: "custom",
                                              items: "basicitem nesteditem toggleitem",
                                            },
                                          },
                                          toolbar:
                                            "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                            "fullpage" +
                                            "indent | blockquote | codesample | fontselect ",
                                          font_formats:
                                            "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                          block_formats:
                                            "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                          plugins: [
                                            "image",
                                            "help",
                                            "link",
                                            "codesample",
                                            "lists",
                                          ],
                                          branding: false,
                                          content_style:
                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                          menubar: false,
                                        }}
                                        onEditorChange={(e) => handleEditorChange(e)}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <div className="form-group mb-0">
                                      <div className="d-flex">
                                        <button className="btn btn-default">
                                          Upload Image
                                          <input
                                            type="file"
                                            id="uploadCaptureInputFile"
                                            onChange={(e) => {
                                              setImage(e.target.files[0]);
                                            }}
                                          />
                                        </button>
                                      </div>
                                      <div className="previewImg">
                                        <span className="ImgSection">
                                          {image && (
                                            <>
                                              <img
                                                src={
                                                  image ? URL.createObjectURL(image) : ""
                                                }
                                              />
                                              <span className="Del">
                                                <i
                                                  className="fa-solid fa-trash"
                                                  onClick={() => {
                                                    setImage();
                                                    document.getElementById(
                                                      "uploadCaptureInputFile"
                                                    ).value = "";
                                                  }}
                                                ></i>
                                              </span>
                                            </>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="">
                                <div className="serviceTabs">
                                  <h3 className="m-0 mb-4 p-3 pt-0">Pricing</h3>
                                  <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                      <button
                                        className="nav-link active"
                                        id="home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#home-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="home-tab-pane"
                                        aria-selected="true"
                                        onClick={() => setOneTimeService(true)}
                                      >
                                        One-time service
                                      </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                      <button
                                        className="nav-link"
                                        id="profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#profile-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="profile-tab-pane"
                                        aria-selected="false"
                                        onClick={() => setOneTimeService(false)}
                                      >
                                        Recurring service
                                      </button>
                                    </li>
                                  </ul>
                                    <div className="tab-content" id="myTabContent">
                                      <div
                                        className="tab-pane fade show active"
                                        id="home-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                        tabIndex="0"
                                      >
                                        <div className="row">
                                          <div className="col-xxl-7 col-lg-7">
                                            <div className="form-group">
                                              <label>Price</label>
                                              <div className="row">
                                                <div className="col-lg-4">
                                                  <input
                                                    className="form-control mobileSpace"
                                                    type="text"
                                                    value={currency?.code ? currency?.code : "USD"}
                                                    disabled
                                                    onClick={() => setPrice()}
                                                  />
                                                </div>
                                                <div className="col-lg-8">
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    value={price}
                                                    onChange={(e) => {
                                                      if (e.target.value < 0) {
                                                        toastMessage(
                                                          "error",
                                                          "Price cannot be less than 0"
                                                        );
                                                      } else {
                                                        setPrice(e.target.value);
                                                      }
                                                    }}
                                                    onWheel={() =>
                                                      document.activeElement.blur()
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {/* <a href="#">Create multiple orders?</a> */}

                                            <div className="form-group">
                                              <div className="input-group">
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={quantity}
                                                  onChange={(e) => {
                                                    if (e.target.value < 1) {
                                                      toastMessage(
                                                        "error",
                                                        "Order cannot be less than 1"
                                                      );
                                                    } else {
                                                      setQuantity(e.target.value);
                                                    }
                                                  }}
                                                  onWheel={() =>
                                                    document.activeElement.blur()
                                                  }
                                                  aria-describedby="basic-addon2"
                                                />
                                                <span
                                                  className="input-group-text"
                                                  id="basic-addon2"
                                                >
                                                  orders
                                                </span>
                                              </div>
                                              <small>
                                                {quantity} new orders will be created when
                                                this service is purchased.
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="tab-pane fade"
                                        id="profile-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                        tabIndex="0"
                                      >
                                        <div className="row">
                                          <div className="form-group">
                                            <label>Price</label>
                                            <div className="row">
                                              <div className="col-lg-6">
                                                <div className="row">
                                                  <div className="col-lg-4">
                                                    <input
                                                      className="form-control mobileSpace"
                                                      type="text"
                                                      value={currency?.code ? currency?.code : "USD"}
                                                      disabled
                                                    />
                                                  </div>
                                                  <div className="col-lg-8">
                                                    <input
                                                      className="form-control mobileSpace"
                                                      type="number"
                                                      value={priceRecurring}
                                                      onChange={(e) => {
                                                        if (e.target.value < 0) {
                                                          toastMessage(
                                                            "error",
                                                            "Price cannot be less than 0"
                                                          );
                                                        } else {
                                                          setPriceRecurring(
                                                            e.target.value
                                                          );
                                                        }
                                                      }}
                                                      onWheel={() =>
                                                        document.activeElement.blur()
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-6">
                                                <div className="input-group">
                                                  <span
                                                    className="input-group-text"
                                                    id="basic-addon2"
                                                  >
                                                    every
                                                  </span>
                                                  <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-describedby="basic-addon2"
                                                    value={durationQuantity}
                                                    onChange={(e) => {
                                                      if (e.target.value < 0) {
                                                        toastMessage(
                                                          "error",
                                                          "Duration cannot be less than 0"
                                                        );
                                                      } else {
                                                        setDurationQuantity(
                                                          e.target.value
                                                        );
                                                      }
                                                    }}
                                                    onWheel={() =>
                                                      document.activeElement.blur()
                                                    }
                                                  />
                                                  <select
                                                    className="form-control"
                                                    onChange={(e) =>
                                                      setDuration(e.target.value)
                                                    }
                                                  >
                                                    <option value="months">Month</option>
                                                    <option value="years">Year</option>
                                                    <option value="weeks">Week</option>
                                                    <option value="days">Day</option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="alert alert-warning" role="alert">
                                          A price under $0.50 may not be supported in your
                                          payment processor.
                                        </div>

                                        <div className="form-group">
                                          <label className="mb-3">
                                            a recurring payment is received...
                                          </label>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault1"
                                              id="flexRadioDefault1"
                                              value="newOrder"
                                              checked={newOrder == "newOrder"}
                                              onChange={(e) => {
                                                setNewOrder(e.target.value);
                                              }}
                                            />
                                            <label
                                              className="form-check-label checkbox-label"
                                              htmlFor="flexRadioDefalt1"
                                            >
                                              Do nothing <br />
                                              <small>
                                                Order status and due date will not change.
                                              </small>
                                            </label>
                                          </div>

                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault2"
                                              id="flexRadioDefault2"
                                              value="createNewOrder"
                                              checked={newOrder == "createNewOrder"}
                                              onChange={(e) => {
                                                setNewOrder(e.target.value);
                                              }}
                                            />
                                            <label
                                              className="form-check-label checkbox-label"
                                              htmlFor="flexRadioDefa"
                                            >
                                              Create new orders <br />{" "}
                                              <small>
                                                If you want clients to fill out {quantity}{" "}
                                                new intake forms every day.
                                              </small>
                                            </label>
                                            {newOrder == "createNewOrder" && (
                                              <div className="col-lg-4">
                                                <div className="input-group">
                                                  <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-describedby="basic-addon2"
                                                    value={quantity}
                                                    onChange={(e) => {
                                                      if (e.target.value < 1) {
                                                        toastMessage(
                                                          "error",
                                                          "Order cannot be less than 1"
                                                        );
                                                      } else {
                                                        setQuantity(e.target.value);
                                                      }
                                                    }}
                                                    onWheel={() =>
                                                      document.activeElement.blur()
                                                    }
                                                  />
                                                  <span
                                                    className="input-group-text"
                                                    id="basic-addon2"
                                                  >
                                                    orders
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                {/* <h3 className="mt-4">Orders of this service</h3> */}
                                {/* <div className="commonForm rounded-1">
                                <div className="form-group">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="newOrders"
                                      checked={orderService}
                                      onChange={(e) => {
                                        setOrderService(e.target.checked);
                                      }}
                                    />
                                    <label
                                      className="form-check-label checkbox-label"
                                      for="newOrders"
                                    >
                                      This is an add-on, don't create a new order <br />
                                      <small>
                                        An order will not be created when this service is
                                        purchased. Intake form will be appended to parent
                                        service. You can select one or more parent
                                        services below.
                                      </small>
                                      {orderService == true ? (
                                        <select
                                          className="form-control mt-2"
                                          value={addOn}
                                          onChange={(event) => {
                                            setAddOn(event.target.value);
                                          }}
                                        >
                                          <option value="" selected>
                                            Select Parent Service<body></body>
                                          </option>
                                          {services.map((val, key) => {
                                            return (
                                              <option value={val.name}>{val.name}</option>
                                            );
                                          })}
                                          {/* <option>Select Parent Service</option> */}
                                {/* <option>Select Parent Service</option>
                                          <option>Select Parent Service</option> */}
                                {/* </select>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div> */}

                                <h3 className="m-0 mb-4 p-3 pt-0">Visibility</h3>
                                    <div className="form-group mb-0">
                                      <div className="form-check">
                                        <input
                                          required
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="showServicePage"
                                          checked={visibility}
                                          onChange={(e) => {
                                            setVisibility(e.target.checked);
                                          }}
                                        />
                                        <label
                                          className="form-check-label checkbox-label"
                                          htmlFor="showServicePag"
                                        >
                                          Show in services page <br />
                                          <small>
                                            Choose whether to list this service in your
                                            Client Portal's services page. Service can still
                                            be used in order forms.
                                          </small>
                                        </label>
                                      </div>
                                    </div>

                                  <h3 className="m-0 mb-4 p-3 pt-0">Message Template</h3>
                                    <Form.Select onChange={selectMessageOption}>
                                      <>
                                        <option vlaue="">Select welcome template</option>
                                        {messages.map((message, index) => (
                                          <option key={index} value={message._id}>
                                            {message.name}
                                          </option>
                                        ))}
                                      </>
                                    </Form.Select>

                                  <h3 className="m-0 mt-4 mb-4 p-3 pt-0">Order Settings</h3>
                                    <div className="form-group mb-3">
                                      <label>Select Workflow</label>
                                      <Form.Select onChange={selectWorkflowOption} value={selectedWorkflow}>
                                        <>
                                          <option vlaue="" disabled>Select Workflow</option>
                                          {workflowData.map((workflow, index) => (
                                            <option key={index} value={workflow._id}>
                                              {workflow.workflowName}
                                            </option>
                                          ))}
                                        </>
                                      </Form.Select>
                                    </div>
                                    <div className="form-group mb-4">
                                      <label>Workflow default status</label>
                                      <Form.Select onChange={selectWorkflowOrderOption} value={defaultWorkflowOrderStatus}>
                                        <>
                                          {selectedWorkflowOrderStatuses.map((orderStatuses, index) => (
                                            <option key={index} value={orderStatuses._id}>
                                              {orderStatuses.name}
                                            </option>
                                          ))}
                                        </>
                                      </Form.Select>
                                    </div>

                                    <div className="form-group mb-0">
                                      <label>Default order Assignee</label>
                                      <Form.Select onChange={(e) => setDefaultAssignee(e.target.value)} value={defaultAssignee}>
                                        <>
                                          <option value={'*'}>
                                            Anyone
                                          </option>
                                          {clientListArr.map((client, index) => (
                                            <option key={index} value={client.value}>
                                              {client.label}
                                            </option>
                                          ))}
                                        </>
                                      </Form.Select>
                                    </div>

                                  <h3 className="m-0 mt-4 mb-4 p-3 pt-0">Pay Later</h3>
                                    <div className="form-group mb-0">
                                      <div className="form-check">
                                        <input
                                          required
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="showServicePage"
                                          checked={payLater}
                                          onChange={(e) => {
                                            setPayLater(e.target.checked);
                                          }}
                                        />
                                        <label
                                          className="form-check-label checkbox-label"
                                          htmlFor="showServicePag"
                                        >
                                          Pay Later <br />
                                          <small>
                                            Choose whether during checkout the customers
                                            should have option for Pay later
                                          </small>
                                          <br />
                                          <small>
                                            In case client selects multiple services in cart
                                            without Pay Later option then it will not show
                                            Pay Later button.
                                          </small>
                                        </label>
                                      </div>
                                    </div>
                                  <h3 className="m-0 mt-4 mb-4 p-3 pt-0">Tags</h3>
                                    <div className="form-group mb-0">
                                      <div className="input-group mb-3 position-relative">
                                        <input
                                          className="form-control"
                                          placeholder="New Tag"
                                          value={tag}
                                          aria-label="New Tag"
                                          aria-describedby="basic-addon2"
                                          onChange={(e) => {
                                            setTag(e.target.value);
                                            if (e.target.value) {
                                              getFilteredTags(e.target.value);
                                            }
                                          }}
                                        />
                                        {filterTags && (
                                          <ul className="dataDropdown">
                                            {filterTags.length > 0 &&
                                              filterTags.map((tag) => {
                                                return (
                                                  <li
                                                    onClick={(e) => {
                                                      if (
                                                        !newTag.find(
                                                          (value) => value == tag.name
                                                        )
                                                      ) {
                                                        newTag.push(tag.name);
                                                        setFilterTags();
                                                        setTag("");
                                                      }
                                                    }}
                                                    key={tag.name}
                                                  >
                                                    {tag.name}
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        )}
                                        <button
                                          className="input-group-text tagsButton"
                                          id="basic-addon2"
                                          onClick={(e) => {
                                            if (tag) {
                                              if (!newTag.find((tagName) => tagName == tag)) {
                                                newTag.push(tag);
                                                setNewTag([...newTag]);
                                                setTag("");
                                              }
                                            }
                                          }}
                                        >
                                          Add
                                        </button>
                                      </div>
                                      <div className="added-tags">
                                        {newTag.length > 0 &&
                                          newTag.map((tagName) => {
                                            return (
                                              <a
                                                onClick={() => {
                                                  let index = newTag.findIndex(
                                                    (value) => value == tagName
                                                  );
                                                  newTag.splice(index, 1);
                                                  setNewTag([...newTag]);
                                                }}
                                              >
                                                {tagName}{" "}
                                                <span className="cencelTag">
                                                  <i className="fa-solid fa-xmark"></i>
                                                </span>
                                              </a>
                                            );
                                          })}
                                      </div>
                                    </div>

                                  {/* <h3 className="mt-4">Tags</h3>
                                <div className="commonForm rounded-1">
                                  <div className="form-group mb-0">
                                    <div className="form-check">
                                      <input
                                        required
                                        className="form-check-input"
                                        type="text"
                                        value=""
                                        id="showServicePage"
                                        checked={payLater}
                                        onChange={(e) => {
                                          setPayLater(e.target.checked);
                                        }}
                                      />
                                      <label
                                        className="form-check-label checkbox-label"
                                        htmlFor="showServicePag"
                                      >
                                        Pay Later <br />
                                        <small>
                                          Choose whether during checkout the customers should have option for Pay later
                                        </small>
                                      </label>
                                    </div>
                                  </div>
                                </div> */}

                                  <div className="btn-section text-end">
                                    
                                  </div>
                                </div>
                              </div>

                              
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="commonBtn-section">
                              <button type="button" className="btn btn-cancel" onClick={() => navigate("/services")}>Cancel</button>
                              <button
                                      className="btn btn-save"
                                      disabled={serviceName == ""}
                                      onClick={(e) => {
                                        setCurrActiveTab("InTakeForm");
                                        // setIntakeFormStatus(true);
                                      }}
                                    >
                                      Add Service
                                    </button>
                            </div>
                          </div>
                        </div>
                        

                        
                      </div>
                      <div
                        className={`tab-pane fade ${currActiveTab == "InTakeForm" && "show active"
                          }`}
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabIndex="0"
                      >
                        {/* <div className="commonForm mb-3 rounded-1 mt-3">
                        <div className="form-group col-lg-4 mb-0 filter-drowdown">
                          <label>Select Intake Form</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setSelectedIntakeForm(e.target.value);
                            }}
                          >
                            <option value="no_form" selected>
                              No Intake Form
                            </option>
                            <option value="intake_form_1">Intake Form 1</option>
                            <option value="intake_form_2">Intake Form 2</option>
                          </select>
                        </div>
                      </div> */}
                        <div className="tab-content" id="pills-tabContent">
                          {selectedIntakeForm == "intake_form_1" && (
                            <div className="intakeForm">
                              <h3>Intake form for writing service</h3>
                              <p>
                                Clients get access to this form after buying your
                                service. Their order will remain{" "}
                                <span>Pending</span> until the form is filled out.
                              </p>
                              <div className="commonForm rounded-1">
                                <div className="form-group">
                                  <label>
                                    Upload upto 3 photos. Guidelines: Make sure to
                                    submit ONLY high quality landscape "horizontral"
                                    photos
                                  </label>

                                  {/* <div className="form-group">
                                  <label>
                                    Please tell us more about your brand that we
                                    won't be able to find on your website or any
                                    social media account: (ad least 100 words
                                    minimum) <small>Optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div> */}
                                  <div className="Upldimages">
                                    <input type="file" />
                                    <i className="fa-solid fa-file-arrow-up"></i>
                                    <p>
                                      <span>Browse or</span> drag and drop
                                    </p>
                                  </div>

                                  {/* <div className="previewImg">
                                  <span className="ImgSection">
                                    <img src={DummyAvatar} />
                                    <span className="Del">
                                      <i className="fa-solid fa-trash"></i>
                                    </span>
                                  </span>
                                  <span className="ImgSection">
                                    <img src={DummyAvatar} />
                                    <span className="Del">
                                      <i className="fa-solid fa-trash"></i>
                                    </span>
                                  </span>
                                </div> */}
                                </div>
                                <div className="form-group">
                                  <label>
                                    Who is your audience for this featured article ?{" "}
                                    <small>Optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>
                                <div className="form-group">
                                  <label>
                                    What is the name of your brand?{" "}
                                    <small>Optional</small>
                                  </label>
                                  <input type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                  <label>
                                    Is there a special quote about your brand that
                                    you want us to use? if yes, what is it?{" "}
                                    <small>Optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Please tell us more about your brand that we
                                    won't be able to find on your website or any
                                    social media account: (ad least 100 words
                                    minimum) <small>Optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Please provide us your website, social media
                                    links <small>Optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Please give us your brand bio (atleast 100 words
                                    minimum) <small>Optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>

                                <div className="form-group mb-0">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="showServicePage"
                                    />
                                    <label
                                      className="form-check-label checkbox-label mb-0"
                                      htmlFor="showServicePage"
                                    >
                                      Approval and Confirmation <br />
                                      <small>
                                        I approve this content to get publish, so
                                        long as it passes the publication's
                                        guidelines
                                      </small>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedIntakeForm == "intake_form_2" && (
                            <div className="intakeForm mb-4">
                              <h3>Intake form for writing service</h3>
                              <p>
                                Clients get access to this form after buying your
                                service. Their order will remain{" "}
                                <span>Pending</span> until the form is filled out.
                              </p>
                              <div className="commonForm rounded-1">
                                <div className="form-group">
                                  <label>
                                    Please pick the publication you want this
                                    article to get published in
                                  </label>
                                  <select className="form-control">
                                    <option>Astist Weekly</option>
                                    <option>Astist Weekly</option>
                                    <option>Astist Weekly</option>
                                  </select>
                                </div>

                                <div className="form-group">
                                  <label>
                                    If you wrote your article. Paste a Google Doc
                                    link here. (Make sure the link access is set to
                                    "Anyone With the Link") Ignore this section if
                                    you want us to write your article.{" "}
                                    <small>Optional</small>
                                  </label>

                                  <textarea className="form-control"></textarea>
                                  <small>
                                    Click this link to see out photo submission
                                    guide: <br />{" "}
                                    <a
                                      className="text-decoration-none text-muted"
                                      href="#"
                                      target="_blank"
                                    >
                                      https://docs.google.com/document/d/1JqbZZdyzYkLjC95ps-N29sv1dDt-kEhtX9yEpVx5uw0/edit#
                                    </a>
                                  </small>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Upload Up to 3 photos. Guidelines: Make sure to
                                    submit ONLY high quality landscape "horizontal"
                                    photos
                                  </label>
                                  <div className="Upldimages">
                                    <input type="file" />
                                    <i className="fa-solid fa-file-arrow-up"></i>
                                    <p>
                                      <span>Browse or</span> drag and drop
                                    </p>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Who is your audience for this featured acticle?{" "}
                                    <small>Optional</small>
                                  </label>
                                  <input type="" className="form-control" />
                                </div>

                                <div className="form-group">
                                  <label>
                                    What is your brand? <small>optional</small>
                                  </label>
                                  <input type="" className="form-control" />
                                </div>

                                <div className="form-group">
                                  <label>
                                    Is there a special quote about your brand that
                                    you want us to use? If yes, what is it?{" "}
                                    <small>optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Please tell us more about your brand that we
                                    won't be able to find on your website or any
                                    social media account: (al least 100 word
                                    minimum) <small>optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Please provide us your website, social media
                                    links <small>optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                  <label>
                                    Please give us your brand bio (at least 100 word
                                    minimum) <small>optional</small>
                                  </label>
                                  <textarea className="form-control"></textarea>
                                </div>

                                <div className="form-group mb-0">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checkbox-label"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label mb-0"
                                      htmlFor="flexCheckDefault"
                                    >
                                      Approval and Confirmation <br />{" "}
                                      <small>
                                        I approve this content to get publish, so
                                        long as it passes the publication's
                                        guidelines
                                      </small>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12 mt-4">
                          <div className="intakeDropform">
                            <FormBuilder
                              inititalFormData={[]}
                              setTemplateSaved={setTemplateSaved}
                              setCurrActiveTab={setCurrActiveTab}
                              setCustomIntakeFormData={setCustomIntakeFormData}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${currActiveTab == "Tasks" && "show active"
                          }`}
                        id="pills-task"
                        role="tabpanel"
                        aria-labelledby="pills-task-tab"
                        tabIndex="0"
                      >
                        <div className="col-xxl-6 col-lg-8 mx-auto">
                          <div className="commonForm mb-3 rounded-1 mt-3">
                            <div className="form-group mb-0 filter-drowdown">
                              <div className="servicesTask">
                                <h4>
                                  Tasks
                                  <button
                                    className="btn btn-add"
                                    onClick={() => {
                                      setIsEditMode(false);
                                      setTask({
                                        taskName: "",
                                        description: "",
                                        deadline: 0,
                                        deadlineValue: "day",
                                      });
                                      setIsModalOpen({
                                        ...isModalOpen,
                                        addEdit: true
                                      })
                                    }}
                                  >
                                    Add Task
                                  </button>
                                </h4>

                                <div className="form-group">
                                  <div className="table-resposnive">
                                    <table className="table mb-0 rounded-1">
                                      <tbody>
                                        {taskList &&
                                          taskList.map((taskItem, index) => {
                                            return (
                                              <tr key={taskItem.tagName + index}>
                                                <td>
                                                  <div className="form-check">
                                                    {/* <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  value=""
                                                  id={`${taskItem.taskName}_flexCheckDefault`}
                                                /> */}
                                                    <label
                                                      className="form-check-label"
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {taskItem.taskName} <br />
                                                      <small>
                                                        {taskItem.description} (
                                                        {taskItem.deadline}{" "}
                                                        {taskItem.deadlineValue})
                                                      </small>
                                                    </label>
                                                    {/* <div>
                                                  <small
                                                    style={{
                                                      display: "block",
                                                    }}
                                                  >
                                                    {"deadline" +
                                                      " " +
                                                      taskItem.deadline +
                                                      " " +
                                                      taskItem.deadlineValue}
                                                  </small>
                                                </div> */}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="dropdown">
                                                    <button
                                                      className="btn btn-secondary dropdown-toggle"
                                                      type="button"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                    >
                                                      <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                      <li
                                                        onClick={() => {
                                                          if (userInfo.roles.name === "admin") {
                                                            setEditValue(
                                                              taskItem,
                                                              index
                                                            )
                                                            setIsModalOpen({
                                                              ...isModalOpen,
                                                              addEdit: true
                                                            })
                                                          } else {

                                                            navigate(
                                                              "/permissionDenied",
                                                              {
                                                                state: {
                                                                  noPermission: false,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        }
                                                        }
                                                      >
                                                        <a
                                                          className="dropdown-item btn btn-add"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a
                                                          className="dropdown-item"
                                                          href="#"
                                                          onClick={() => {
                                                            if (
                                                              userInfo.roles
                                                                .name === "admin"
                                                            ) {
                                                              setDeleteTask(
                                                                taskItem.taskName
                                                              );
                                                              setIsModalOpen({
                                                                ...isModalOpen,
                                                                delete: true
                                                              })
                                                            } else {
                                                              toastMessage(
                                                                "error",
                                                                "You dont have permission to access this resource"
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          Delete
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btn-section text-end">
                            <button
                              className="btn btn-save"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFormSubmit();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

      {/* task modal */}
      {isModalOpen.addEdit ? (
        <AddEditTaskModal
          isModalOpen={isModalOpen.addEdit}
          isEditMode={isEditMode}
          task={task}
          setTask={setTask}
          editHandler={editHandler}
          addTaskHandler={addTaskHandler}
          handleCancelModal={handleCancelModal}
        />
      ) : (
        ""
      )}

      {/* Delete Modal */}

      {isModalOpen.delete ? (
        <DeleteConfirmModal
          isDeleteConfirmModalOpen={isModalOpen.delete}
          handleDelete={deleteTaskHandler}
          handleCancelModal={handleCancelModal}
          modalContent={`Are you sure you want to delete this task: ${task.taskName}`}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default AddService;