import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

const EditTicketModal = ({ isModalOpen, setTicketName, ticketName, setTicketDescription, ticketDescription, editMode, updateTicketStatus, onSubmit, disabledBtn, handleCancelModal }) => {
    return (
        <Modal
            show={isModalOpen}
            onHide={handleCancelModal}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            className="add-new-taxrule-modal add-order-modals"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {editMode ? 'Edit Ticket' : 'Add Ticket'}
                </Modal.Title>
            </Modal.Header>

            <ModalBody>
                {/* <div
                    className="modal fade history-modal"
                    id="addEditStatusModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body"> */}
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Title"
                                        onChange={(e) => {
                                            setTicketName(e.target.value);
                                        }}
                                        value={ticketName}
                                        name="status"
                                        required
                                    />
                                </div>
                                <br />
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput2"
                                        placeholder="Description"
                                        onChange={(e) => {
                                            setTicketDescription(e.target.value);
                                        }}
                                        value={ticketDescription}
                                        name="statusdes"
                                        required
                                    />
                                </div>
                                {/* <br />
                            </div>
                        </div>
                    </div>
                </div> */}
            </ModalBody>

            <ModalFooter>
                <Button color="secondary" className="cancel-btn" onClick={handleCancelModal}>Cancel</Button>{' '}
                <Button color="primary" className="btn btn-save" disabled={disabledBtn} onClick={editMode ? updateTicketStatus : onSubmit}>Save</Button>
            </ModalFooter>
        </Modal>
    )
}

export default EditTicketModal;