import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { convertToUpperCase } from "../../shared/functions";

const EditTeam = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const {
    apiGetMethod,
    allApiUrl,
    apiPutMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { GET_SPECIFIC_TEAM, GET_ALL_ROLE, EDIT_TEAM_MEMBER } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roles, SetRoles] = useState("");
  const [rolesList, setAllRolesList] = useState([]);
  const [resetPassword, setResetPassword] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  useEffect(() => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_ALL_ROLE, "", header)
      .then((res) => {
        if (res.status === 200) {
          let AdminRoleList = [];
          res.data.roles.forEach((role) => {
            if (role.type === "admin") {
              const labels = role.name.replace(
                /(^\w{1})|(\s+\w{1})/g,
                (label) => label.toUpperCase()
              );
              if (role.name !== "system") {
                AdminRoleList.push({ value: role.name, label: labels });
              }
            }
          });
          setAllRolesList(AdminRoleList);
          setIsLoading(false);
        }
      })

      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }, []);
  useEffect(() => {
    getSpecificRolesDataHandler();
  }, []);
  var specificTeamMember = window.location.pathname.split("/")[3];
  function getSpecificRolesDataHandler() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);

    apiGetMethod(`${GET_SPECIFIC_TEAM}/${specificTeamMember}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          const label = convertToUpperCase(res.data.user[0].role);
          SetRoles({ value: res.data.user[0].role, label });
          setEmail(res.data.user[0].userId.email);
          setFirstName(res.data.user[0].userId.firstName);
          setLastName(res.data.user[0].userId.lastName);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  const editTeamMemberHandler = (event) => {
    event.preventDefault();
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {
      email,
      firstName,
      lastName,
      role: roles.value,
      resetPassword,
      password,
    };
    apiPutMethod(`${EDIT_TEAM_MEMBER}/${specificTeamMember}`, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        navigate("/settings/team");
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const onSelectRole = (e) => {
    SetRoles(e);
  };

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="commonSlidePl">
      <div className="setting-form-section pt-4 horizontalPadding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-back"
                onClick={() => navigate("/settings/team")}
              >
                <a href="#">
                  <i className="fa-solid fa-angle-left"></i> Team
                </a>
              </div>

              <div className="col-xxl-8 col-lg-8 mx-auto">
                <div className="setting-form">
                  <h3 className="fs-4">Edit team member</h3>
                  <form onSubmit={editTeamMemberHandler}>
                    <div className="commonForm rounded-1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              required
                              type="email"
                              className="form-control"
                              placeholder=""
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              First name <small>optional</small>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              onChange={(e) => setFirstName(e.target.value)}
                              value={firstName}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>
                              Last name <small>optional</small>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              onChange={(e) => setLastName(e.target.value)}
                              value={lastName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Role</label>
                            <Select
                              isSearchable
                              value={roles}
                              options={rolesList}
                              onChange={onSelectRole}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="commonForm mt-4 rounded-1">
                      <div className="form-group mb-1">
                        <div className="form-check">
                          <label className="d-block checkbox-label form-check-label">
                            <input
                              className="checkbox form-check-input"
                              type="checkbox"
                              onClick={() => {
                                setResetPassword(!resetPassword);
                              }}
                              checked={resetPassword}
                              readOnly
                            />
                            Reset password and send welcome email
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-0">
                        <div className="form-check">
                          <label className="d-block checkbox-label form-check-label">
                            <input
                              className="checkbox form-check-input"
                              type="checkbox"
                              onClick={() => {
                                setIsChangePasswordVisible(
                                  !isChangePasswordVisible
                                );
                              }}
                              checked={isChangePasswordVisible}
                              readOnly
                            />
                            Change password
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-8 col-12 mb-0 ms-4">
                        {isChangePasswordVisible && (
                          <input
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        )}
                      </div>
                    </div>
                    <div className="btn-section text-end">
                      <button type="submit" className="btn btn-save">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default EditTeam;
