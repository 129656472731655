import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { AllContext } from "../../context";
import cardIcon from "../../assets/img/card-img.png";
import visaIcon from "../../assets/img/card-visa.svg";
import { getCountries } from "node-countries";
import { Select } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "../CartSection";
import { savePaymentAddress } from "../../redux/action/login";
import PageLoader from "../../components/pageLoader";
const { Option } = Select;

const countriesData = getCountries();

const Payment = () => {
  //constants
  const { state } = useLocation();
  const context = useContext(AllContext);

  const {
    allApiUrl,
    apiPostMethod,
    apiGetMethod,
    redirectToLogin,
    toastMessage,
  } = context;
  const [isLoading, setIsLoading] = useState(false);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const dispatch = useDispatch();
  const { EDIT_USER_DETAILS, PUBLICINVOICESUCCESS, PAYMENT_TAXES } = allApiUrl;
  const [isPurchasingForCompany, setIsPurchasingForCompany] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [userInformation, setUserInformation] = useState({});
  const [isCompletePurchased, setCompletePurchased] = useState(false);
  const [completePurchaseResponse, setCompletePurchaseReponse] = useState({});
  const [sendData, setSendData] = useState({});

  const [paymentMethod, setPaymentMethod] = useState("newCard");
  const [saveCard, setSaveCard] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [addToCartList, setAddToCartList] = useState([]);
  const [isPublicInvoicePayment, setIsPublicInvoicePayment] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [cardId, setCardId] = useState("");
  const [taxRulesData, setTaxRulesData] = useState([]);
  const [taxableTaxRules, setTaxableTaxRules] = useState([]);
  const [isTaxesEnabled, setIsTaxesEnabled] = useState(false);
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [paymentSettingData, setPaymentSettingData] = useState({});
  const [provinces, setProvinces] = useState([]);

  const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_KEY);

  const navigate = useNavigate();
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );

  useEffect(() => {
    if (state.data) {
      setAddToCartList(state.data);
      setGrandTotal(state?.data?.totalPrice);
      setInvoiceId(state?.data?._id);
      setSendData(state?.data?.userDetails);
      if (state.unAssigedInvoice == false) {
        setUserInformation(state?.data?.userDetails);
      }
    }
    getTaxData();
    getPaymentSettingData();
  }, []);
  function getUserDetails(userProfileId) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(EDIT_USER_DETAILS, userProfileId, header)
      .then((res) => {
        setIsPurchasingForCompany(
          res.data.user[0].company || res.data.user[0].taxId ? true : false
        );
        setUserInformation(res.data.user[0]);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  const getTaxData = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(PAYMENT_TAXES, "", header)
      .then((res) => {
        const taxesRulesData = res.data.values;
        setTaxRulesData(taxesRulesData.taxRules);
        setIsTaxesEnabled(taxesRulesData?.isTaxesEnabled);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  };
  async function getPaymentSettingData() {
    setIsLoading(true);
    let header = {
      //   Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.PAYMENT_METHOD, "", header);
      if (response.status === 200) {
        setIsLoading(false);
        setPaymentSettingData(response.data.values);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  const [stripe, setStripe] = useState("");
  const [element, setElement] = useState("");
  function handleCheckout(event) {
    event.preventDefault();
    if (!!userInformation.country === false && provinces.length === 0) {
      toastMessage("error", "Please select country");
      return false;
    }
    if (provinces.length > 0 && !!userInformation.state === false) {
      toastMessage("error", "Please select Province");
      return false;
    }
    if (
      provinces.length > 0 &&
      provinces.filter((ele) => ele.name === userInformation.state).length === 0
    ) {
      toastMessage("error", "Please enter valid Province");
      return false;
    }

    if (paymentMethod == "newCard") {
      handleSubmit(event, stripe, element);
    } else {
      setInitialLoading(true);
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      const data = {
        couponCode: addToCartList.coupon || "",
        currency: paymentSettingData?.currency?.code
          ? paymentSettingData?.currency?.code
          : "usd",
        invoiceId: invoiceId || "",
        type: state.from == "invoice" ? "invoice" : "cart",
        newCard: paymentMethod == "newCard" ? true : false,
        // saveCard,
        // cardToken: result?.token?.id,
        billingAddress: userInformation.billingAddress,
        city: userInformation.city,
        state: userInformation.state,
        postal: userInformation.postal,
        country: userInformation.country,
        firstName: userInformation.userId.firstName,
        email: userInformation.userId.email
          ? userInformation.userId.email
          : userInformation.email,
      };
      if (paymentMethod !== "newCard") {
        data["cardId"] = cardId;
      }
      if (isPurchasingForCompany) {
        data["taxId"] = userInformation.taxId;
        data["company"] = userInformation.company;
      }
      if (loginUserAccessToken) {
        apiPostMethod(allApiUrl.CHECKOUT, data, header).then((res) => {
          if (res.data.message == "Invoice already paid.") {
            setIsLoading(false);
            toastMessage("error", res.data.message);
          } else if (res.data.message == "Amount should be greater than 0") {
            toastMessage("error", res.data.message);
            setInitialLoading(false);
          } else {
            if (res.status === 200) {
              dispatch(
                savePaymentAddress({
                  ...data,
                  id: res.data.docs.id,
                  stripeData: res.data.stripeData,
                  invoiceId,
                })
              );
              setCompletePurchased(true);
              setCompletePurchaseReponse(res.data);
              setIsLoading(false);
              setInitialLoading(false);
            }
          }
        });
      } else {
        apiPostMethod(allApiUrl.PUBLICCHECKOUT, data, header)
          .then((res) => {
            if (res.data.message == "Invoice already paid.") {
              setIsLoading(false);
              toastMessage("error", res.data.message);
            } else if (res.data.message == "Amount should be greater than 0") {
              toastMessage("error", res.data.message);
              setInitialLoading(false);
            } else {
              if (res.status === 200) {
                if (isPublicInvoicePayment) {
                  const userData =
                    sendData == undefined ? userInformation : sendData;
                  let invoicePayload = {
                    chargeId: res.data.docs.id,
                    invoiceId,
                    email: userData.userId
                      ? userData.userId.email
                      : userData.email,
                    isTaxPaid: res.data?.stripeData?.isTaxPaid,
                    taxAmount: res.data?.stripeData?.taxAmount,
                    appliedTaxes: res.data?.stripeData.appliedTaxes,
                    billingAddress: data.billingAddress,
                    city: data.city,
                    state: data.state,
                    postal: data.postal,
                    company: data.company,
                    taxId: data.taxId,
                    country: data.country,
                  };
                  handlePublicSuccessInvoice(invoicePayload);
                } else {
                  dispatch(
                    savePaymentAddress({
                      ...data,
                      id: res.data.docs.id,
                      stripeData: res.data.stripeData,
                      invoiceId,
                    })
                  );
                  setCompletePurchased(true);
                  setCompletePurchaseReponse(res.data);
                  setIsLoading(false);
                  setInitialLoading(false);
                }
              }
            }
          })
          .catch((err) => {
            console.log("err", err);

            redirectToLogin(dispatch, navigate, err);
            setInitialLoading(false);
          });
      }
    }
  }
  const [partialPayment, setPartialPayment] = useState(0);
  async function getInvoiceDetail(data) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.INVOICE_BY_ORDER + "/" + data,
        "",
        header
      );
      if (response.status === 200) {
        setInvoiceId(response.data.invoice._id);

        setAddToCartList(response.data.invoice);
        let total =
          response.data.invoice &&
          response.data.invoice.product &&
          response.data.invoice.product.length > 0 &&
          response.data.invoice.product.map(
            (item) => item.id.price * item.quantity
          );

        setGrandTotal(
          total?.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          )
        );
        if (
          response.data.invoice.paymentCredit ||
          response.data.invoice.paymentDue
        ) {
          setPartialPayment(
            response.data.invoice.paymentCredit
              ? response.data.invoice.paymentCredit
              : response.data.invoice.paymentDue
          );
        }

        setIsPurchasingForCompany(
          response.data.invoice.userDetails.company ||
            response.data.invoice.userDetails.company
            ? true
            : false
        );

        setUserInformation({
          ...response.data.invoice.userDetails,
          userId: {
            firstName: response.data.invoice.userDetails.firstName,
            email: response.data.invoice.userDetails.email,
          },
        });

        response.data.invoice &&
          response.data.invoice.product &&
          response.data.invoice.product.length > 0 &&
          response.data.invoice.product.map((obj) => {
            if (obj.id.recurringOrder.status == true) {
              count = count + 1;
              setSubTotalSection(obj);
            }

            setIsMoreThanOneRecurring(count);
          });
      }
    } catch (err) {
      toastMessage("error", err.data.message);
    }
  }

  useEffect(() => {
    if (state && state.from === "cart") {
      getAddToCartList();
    }
    if (state && state.url !== "public-invoice") {
      getListOfCards();
    }
    if (state && state.url === "public-invoice") {
      setIsPublicInvoicePayment(true);
    }
    if (state && state.from === "cart") {
      getUserDetails(loginUserInfo.id);
    } else {
      if (state && state.unAssigedInvoice == true) {
        getUserDetails(loginUserInfo.id);
      }

      if (state && state.url !== "public-invoice") {
        getInvoiceDetail(state.orderId);
      }
    }
  }, []);

  useEffect(() => {
    if (isTaxesEnabled && userInformation?.country) {
      const selectedCountry = countriesData.filter(
        (data) =>
          data.name.toLowerCase() === userInformation?.country.toLowerCase()
      );
      if (selectedCountry[0].provinces) {
        setProvinces(selectedCountry[0].provinces);
        setTaxableTaxRules([]);
      } else {
        processTaxData(selectedCountry[0].name);
        setProvinces([]);
      }
    }
  }, [userInformation?.country]);

  const handleOnChangeCountry = (event) => {
    setUserInformation((prevData) => {
      return {
        ...prevData,
        country: event,
      };
    });
  };

  const handleOnChangeProvinces = (e) => {
    if (isTaxesEnabled) {
      processTaxData(userInformation.country, e);
    }
    setUserInformation((prevData) => {
      return {
        ...prevData,
        state: e,
      };
    });
  };

  const processTaxData = (countryName, provinceName) => {
    if (!!provinceName === false) {
      const taxableRulesData = taxRulesData.filter((data) => {
        return data.taxCountries.includes(countryName);
      });
      setTaxableTaxRules(taxableRulesData);
      processTotalPayableAmount(taxableRulesData);
    } else {
      const taxableRulesData = taxRulesData.filter((data) => {
        return (
          data.taxCountries.includes(countryName) &&
          data.taxProvinces.includes(provinceName)
        );
      });
      setTaxableTaxRules(taxableRulesData);
      processTotalPayableAmount(taxableRulesData);
    }
  };

  const calculateTaxAmount = (taxValue) => {
    if (addToCartList.paymentCredit || addToCartList.paymentDue) {
      const itemsTotal = (grandTotal - partialPayment).toFixed(2);

      const itemsTax = parseFloat((itemsTotal * taxValue) / 100).toFixed(2);
      return itemsTax;
    } else {
      const itemsTax = parseFloat((grandTotal * taxValue) / 100).toFixed(2);
      return itemsTax;
    }
  };

  const calculateTotalPayableAmount = (total) => {
    if (isTaxesEnabled) {
      let taxAmountVal = 0;
      if (taxableTaxRules.length) {
        taxableTaxRules.forEach((data) => {
          const itemsTax = parseFloat(
            (grandTotal * data.taxValue) / 100
          ).toFixed(2);
          taxAmountVal = parseFloat(
            parseFloat(taxAmountVal) + parseFloat(itemsTax)
          ).toFixed(2);
        });
        const totalAmount = parseFloat(taxAmountVal) + parseFloat(total);
        return parseFloat(totalAmount);
      } else {
        const itemsTax = parseFloat((grandTotal * 10) / 100).toFixed(2);
        const totalAmount = parseFloat(itemsTax) + parseFloat(total);
        return parseFloat(totalAmount).toFixed(2);
      }
    } else {
      return parseFloat(total).toFixed(2);
    }
  };

  const processTotalPayableAmount = (taxableRulesData) => {
    if (isTaxesEnabled) {
      let taxAmountVal = 0;
      if (taxableRulesData.length) {
        taxableRulesData.forEach((data) => {
          const itemsTax = parseFloat(
            (grandTotal * data.taxValue) / 100
          ).toFixed(2);
          taxAmountVal = parseFloat(
            parseFloat(taxAmountVal) + parseFloat(itemsTax)
          ).toFixed(2);
        });
        const totalAmount = parseFloat(taxAmountVal) + parseFloat(grandTotal);
        setTaxableAmount(parseFloat(totalAmount));
      } else {
        const itemsTax = parseFloat((grandTotal * 10) / 100).toFixed(2);
        const totalAmount = parseFloat(itemsTax) + parseFloat(grandTotal);
        setTaxableAmount(parseFloat(totalAmount).toFixed(2));
      }
    } else {
      setTaxableAmount(grandTotal);
    }
  };

  // const taxablePercentage = () => {
  //   const taxableCountry = taxRulesData.taxCountries.find(taxCountry => {
  //     return taxCountry.name.toLowerCase() === userInformation?.country.toLowerCase();
  //   });
  //   if(taxableCountry) {
  //     return taxableCountry.taxValue;
  //   } else {
  //     const taxableDefaultCountry = taxRulesData.taxCountries.find(taxCountry => {
  //       return taxCountry.name.toLowerCase() === 'default';
  //     });
  //     return taxableDefaultCountry.taxValue;
  //   }
  // }

  async function getAddToCartList() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.CART, "", header);
      if (response.status === 200) {
        if (response.data.couponData._id) {
          let fixedAmount = response.data.couponData.fixedAmount;
          response.data.couponData.applies_to.forEach((name) => {
            if (!Array.isArray(name.service) || !name.service.length) {
              response.data.cart[0]?.product.forEach((product, index) => {
                if (fixedAmount) {
                  response.data.cart[0].product[index].discountPrice =
                    response.data.cart[0].product[index].price - name.discount;
                  if (response.data.cart[0].product[index].discountPrice < 0) {
                    response.data.cart[0].product[index].discountPrice = 0;
                  }
                } else {
                  response.data.cart[0].product[index].discountPrice =
                    response.data.cart[0].product[index].price -
                    (
                      (name.discount / 100) *
                      response.data.cart[0].product[index].price
                    ).toFixed(2);
                  if (response.data.cart[0].product[index].discountPrice < 0) {
                    response.data.cart[0].product[index].discountPrice = 0;
                  }
                }
                // }
              });
            } else {
              name.service.forEach((item) => {
                response.data.cart[0]?.product.forEach((product, index) => {
                  if (item === product.id._id) {
                    if (fixedAmount) {
                      response.data.cart[0].product[index].discountPrice =
                        response.data.cart[0].product[index].price -
                        name.discount;
                      if (
                        response.data.cart[0].product[index].discountPrice < 0
                      ) {
                        response.data.cart[0].product[index].discountPrice = 0;
                      }
                    } else {
                      response.data.cart[0].product[index].discountPrice =
                        response.data.cart[0].product[index].price -
                        (
                          (name.discount / 100) *
                          response.data.cart[0].product[index].price
                        ).toFixed(2);
                      if (
                        response.data.cart[0].product[index].discountPrice < 0
                      ) {
                        response.data.cart[0].product[index].discountPrice = 0;
                      }
                    }
                  }
                });
              });
            }
          });
          setAddToCartList(response.data.cart[0]);
          let total =
            response.data.cart[0] &&
            response.data.cart[0].product &&
            response.data.cart[0].product.length > 0 &&
            response.data.cart[0].product.map(
              (item) =>
                (item.discountPrice ? item.discountPrice : item.price) *
                item.quantity
            );
          setGrandTotal(
            total?.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            )
          );
          response.data.cart[0] &&
            response.data.cart[0].product &&
            response.data.cart[0].product.length > 0 &&
            response.data.cart[0].product.map((obj) => {
              if (obj.id.recurringOrder.status == true) {
                count = count + 1;
                setSubTotalSection(obj);
              }

              setIsMoreThanOneRecurring(count);
            });
        } else {
          setAddToCartList(response.data.cart[0]);
          let total =
            response.data.cart[0] &&
            response.data.cart[0].product &&
            response.data.cart[0].product.length > 0 &&
            response.data.cart[0].product.map(
              (item) => item.price * item.quantity
            );
          setGrandTotal(
            total?.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            )
          );
          response.data.cart[0] &&
            response.data.cart[0].product &&
            response.data.cart[0].product.length > 0 &&
            response.data.cart[0].product.map((obj) => {
              if (obj.id.recurringOrder.status == true) {
                count = count + 1;
                setSubTotalSection(obj);
              }

              setIsMoreThanOneRecurring(count);
            });
        }
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
    }
  }
  let recurringServiceExist =
    addToCartList &&
    addToCartList.product &&
    addToCartList.product.some(
      (obj) => obj.id?.recurringOrder?.status === true
    );

  let recurringInvoiceExist =
    addToCartList &&
    addToCartList.recurringInvoice &&
    addToCartList.recurringInvoice.status == true;
  let count = 0;
  const [isMoreThanOneRecurring, setIsMoreThanOneRecurring] = useState(null);
  const [subTotalSection, setSubTotalSection] = useState({});

  async function handleSubmit(event, stripe, elements) {
    setInitialLoading(true);
    event.preventDefault();
    setIsLoading(true);
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      toastMessage("error", result.error.message);
      setInitialLoading(false);
    } else {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      const data = {
        couponCode: addToCartList.coupon ? addToCartList.coupon : "",
        currency: paymentSettingData?.currency?.code
          ? paymentSettingData?.currency?.code
          : "usd",
        invoiceId: invoiceId ? invoiceId : "",
        type: state.from == "invoice" ? "invoice" : "cart",
        newCard:
          saveCard || recurringServiceExist || recurringInvoiceExist
            ? true
            : false,
        cardToken: result?.token?.id,
      };
      if (userInformation && userInformation.billingAddress) {
        data["billingAddress"] = userInformation.billingAddress || "";
      }
      if (userInformation && userInformation.city) {
        data["city"] = userInformation.city || "";
      }
      if (userInformation && userInformation.state) {
        data["state"] = userInformation.state || "";
      }
      if (userInformation && userInformation.postal) {
        data["postal"] = userInformation.postal || "";
      }

      if (
        userInformation &&
        userInformation.company &&
        isPurchasingForCompany
      ) {
        data["company"] = userInformation.company || "";
      }
      if (userInformation && userInformation.country) {
        data["country"] = userInformation.country || "";
      }
      if (userInformation && userInformation.taxId && isPurchasingForCompany) {
        data["taxId"] = userInformation.taxId || "";
      }
      if (userInformation && userInformation.name) {
        data["name"] = userInformation.firstName || "";
      }
      if (
        (userInformation && userInformation.email) ||
        (userInformation && userInformation.userId.email)
      ) {
        data["email"] = userInformation.email
          ? userInformation.email
          : userInformation.userId.email || "";
      }

      if (paymentMethod !== "newCard") {
        data["cardId"] = cardId;
      }

      if (loginUserAccessToken) {
        apiPostMethod(allApiUrl.CHECKOUT, data, header).then((res) => {
          if (res.data.message == "Invoice already paid.") {
            setIsLoading(false);
            toastMessage("error", res.data.message);
          } else if (res.status === 200) {
            dispatch(
              savePaymentAddress({
                ...data,
                id: res.data.docs.id,
                stripeData: res.data.stripeData,
                invoiceId,
              })
            );
            setCompletePurchased(true);
            setCompletePurchaseReponse(res.data);
            setIsLoading(false);
            setInitialLoading(false);
          }
        });
      } else {
        apiPostMethod(allApiUrl.PUBLICCHECKOUT, data, header)
          .then((res) => {
            if (res.data.message == "Invoice already paid.") {
              setIsLoading(false);
              toastMessage("error", res.data.message);
            } else if (res.data.message == "Amount should be greater than 0") {
              toastMessage("error", res.data.message);
              setInitialLoading(false);
            } else {
              if (res.status === 200) {
                if (isPublicInvoicePayment) {
                  const userData =
                    sendData == undefined ? userInformation : sendData;
                  let invoicePayload = {
                    chargeId: res.data.docs.id,
                    invoiceId,
                    email: userData.userId
                      ? userData.userId.email
                      : userData.email,
                    isTaxPaid: res.data?.stripeData?.isTaxPaid,
                    taxAmount: res.data?.stripeData?.taxAmount,
                    appliedTaxes: res.data?.stripeData.appliedTaxes,
                    billingAddress: data.billingAddress,
                    city: data.city,
                    state: data.state,
                    postal: data.postal,
                    company: data.company,
                    taxId: data.taxId,
                    country: data.country,
                  };
                  handlePublicSuccessInvoice(invoicePayload);
                } else {
                  dispatch(
                    savePaymentAddress({
                      ...data,
                      id: res.data.docs.id,
                      stripeData: res.data.stripeData,
                      invoiceId,
                    })
                  );
                  setCompletePurchased(true);
                  setCompletePurchaseReponse(res.data);
                  setIsLoading(false);
                  setInitialLoading(false);
                }
              }
            }
          })
          .catch((err) => {
            redirectToLogin(dispatch, navigate, err);
            setInitialLoading(false);
          });
      }
    }
  }

  const [existingCardList, setExistingcardList] = useState([]);
  function getListOfCards() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.LIST_CARDS, "", header)
      .then((res) => {
        if (res.status == 200) {
          res.data.data = res.data.data.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.last4 === value.last4 &&
                  t.exp_month == value.exp_month &&
                  t.exp_year == value.exp_year
              )
          );
          setExistingcardList(res.data.data);
        }
      })
      .catch((err) => {
        // toastMessage("error", err.data.message);
      });
  }

  const handlePublicSuccessInvoice = (invoicePayload) => {
    toastMessage("success", "user detail is being saved");
    apiPostMethod(PUBLICINVOICESUCCESS, invoicePayload)
      .then((res) => {
        if (res.status == 200) {
          setTimeout(() => {
            toastMessage(
              "success",
              "Payment success, please login to check your order."
            );
          }, 4000);

          navigate("/login");
        }
      })
      .catch((err) => {
        // console.log("ererr", err);
        toastMessage("error", err.data.message);
      });
  };

  const currencyCheck = () => {
    if (paymentSettingData?.currency?.symbol) {
      return paymentSettingData?.currency?.symbol
        ? paymentSettingData?.currency?.symbol
        : "$";
    }
    return "$";
  };

  return (
    <>
      {isCompletePurchased ? (
        <Thankyou
          completePurchaseResponse={completePurchaseResponse}
          from={state.from}
          sendData={sendData == undefined ? userInformation : sendData}
        />
      ) : (
        <div>
          <PageLoader isLoading={initialLoading} />
          <section className="checkOut-page">
            <div className="commonSlidePl">
            <div className="container-fluid pe-0 overflow-hidden">
              <div className="row">
                <div className="col-lg-7">
                  <div className="checkoutListing pr-80 mt-5 formCheckout">
                    <p className="agencyName">OpFlow CRM</p>

                    <form onSubmit={handleCheckout}>
                      <h4>Email</h4>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                userInformation?.email
                                  ? userInformation?.email
                                  : userInformation?.userId?.email
                              }
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    userId: {
                                      ...prevData.userId,
                                      email: e.target.value,
                                    },
                                  };
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <h4>Billing Address</h4>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              value={userInformation?.billingAddress}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    billingAddress: e.target.value,
                                  };
                                })
                              }
                              required
                            />
                            <p>Address</p>
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              value={userInformation?.city}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    city: e.target.value,
                                  };
                                })
                              }
                            />
                            <p>City</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Search to Country"
                              value={userInformation?.country}
                              onChange={(e) => handleOnChangeCountry(e)}
                              required
                            >
                              {countriesData.map((item, idx) => {
                                return (
                                  <Option key={idx} value={item.name}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                            <p>
                              <small>Country</small>
                            </p>
                          </div>
                          {provinces.length ? (
                            <div className="col-lg-4">
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Search to Provines"
                                onChange={(e) => handleOnChangeProvinces(e)}
                                required
                              >
                                {provinces.map((item, idx) => {
                                  return (
                                    <Option key={idx} value={item.name}>
                                      {item.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <p>State/Province/Region</p>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              value={userInformation?.postal}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    postal: e.target.value,
                                  };
                                })
                              }
                            />
                            <p>Postal/ Zip Code</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={() => {
                              setIsPurchasingForCompany(
                                !isPurchasingForCompany
                              );
                            }}
                            checked={isPurchasingForCompany}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            I'm purchasing for a company
                          </label>
                        </div>
                      </div>
                      {isPurchasingForCompany && (
                        <>
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={userInformation.company}
                                  onChange={(e) =>
                                    setUserInformation((prevData) => {
                                      return {
                                        ...prevData,
                                        company: e.target.value,
                                      };
                                    })
                                  }
                                  required
                                />
                                <small>Company</small>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={userInformation.taxId}
                                  onChange={(e) =>
                                    setUserInformation((prevData) => {
                                      return {
                                        ...prevData,
                                        taxId: e.target.value,
                                      };
                                    })
                                  }
                                  required
                                />
                                <small>Tax ID</small>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <h4 className="mt-3">Payment method</h4>

                      {existingCardList &&
                        existingCardList.length > 0 &&
                        existingCardList.map((cardItem, idx) => {
                          return (
                            <div key={idx} className="paymentOption">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`}
                                  id={`existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`}
                                  value={`existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`}
                                  checked={
                                    paymentMethod ==
                                    `existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`
                                  }
                                  onChange={(e) => {
                                    setCardId(cardItem.id);
                                    setPaymentMethod(e.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label d-block"
                                  htmlFor={`existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`}
                                >
                                  <div className="d-flex justify-content-between">
                                    <p className="existingCard mb-0">
                                      <img src={visaIcon} /> **** **** ****
                                      {cardItem.last4}
                                    </p>
                                    <span className="expDel">
                                      <span className="cardExpire">
                                        Expires {cardItem.exp_month}/
                                        {cardItem.exp_year}
                                      </span>

                                      {/* <a href="#"><i class="fa-solid fa-trash"></i></a> */}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}

                      <div
                        className="accordion"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingOne"
                          >
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                            >
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value="newCard"
                                  checked={paymentMethod == "newCard"}
                                  onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  <img src={cardIcon} />
                                </label>
                              </div>
                            </button>
                          </h2>
                          {paymentMethod == "newCard" && (
                            <div
                              id="panelsStayOpen-collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="panelsStayOpen-headingOne"
                            >
                              <div className="accordion-body">
                                {/* stripe card Details */}
                                <div className="form-group">
                                  <div className="row">
                                    <Elements stripe={stripePromise}>
                                      <ElementsConsumer>
                                        {({ stripe, elements }) => (
                                          <>
                                            {
                                              (setStripe(stripe),
                                              setElement(elements))
                                            }
                                            <CardSection />
                                          </>
                                        )}
                                      </ElementsConsumer>
                                    </Elements>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Card Name"
                                    value={userInformation?.userId?.firstName}
                                    onChange={(e) =>
                                      setUserInformation((prevData) => {
                                        return {
                                          ...prevData,
                                          userId: {
                                            ...prevData.userId,
                                            firstName: e.target.value,
                                          },
                                        };
                                      })
                                    }
                                  />
                                  <p>Name on card</p>
                                </div>
                                {!state.data && (
                                  <div className="form-group">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        onChange={() => {
                                          setSaveCard(!saveCard);
                                        }}
                                        checked={saveCard}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault"
                                      >
                                        Save Card
                                      </label>
                                    </div>
                                  </div>
                                )}
                                {/* <p>
                        Payments are currently in test mode. Use one of the{" "}
                        <a href="#">
                          sample card numbers
                        </a>{" "}
                        for testing.
                      </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div className="paymentOption">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" />
                        <label class="form-check-label" for="flexRadioDefault2">
                            <img src={payPal} />
                        </label>
                    </div>
                </div> */}

                      <button className="btn bnt-save mb-3">
                        Complete Purchase
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="summaryHead h-100vh">
                    <div className="summaryPage">
                      <h4>Summary</h4>
                      {addToCartList &&
                        addToCartList.product &&
                        addToCartList.product.length > 0 &&
                        addToCartList.product.map((item, index) => {
                          return (
                            <div className="d-flex" key={index}>
                              <div>
                                <p>{item.id.name}</p>
                                <p>
                                  <span>Qty</span> {item.quantity}
                                </p>
                              </div>
                              <div>
                                <div>
                                  <p>
                                    {currencyCheck()}
                                    {item.discountPrice
                                      ? (
                                          item.quantity * item.discountPrice
                                        ).toFixed(2)
                                      : (
                                          item.quantity *
                                          (state.from == "invoice"
                                            ? item.id.price
                                            : item.price)
                                        ).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {(addToCartList.paymentCredit ||
                        addToCartList.paymentDue) && (
                        <div className="d-flex my-4">
                          <div>
                            <p>
                              {addToCartList.paymentCredit
                                ? "Credit"
                                : "Payment Due Later"}
                            </p>
                            <p>
                              <p>
                                <span>Qty</span> 1
                              </p>
                            </p>
                          </div>
                          <div>
                            <p>
                              - {currencyCheck()}
                              {addToCartList.paymentCredit
                                ? addToCartList.paymentCredit.toFixed(2)
                                : addToCartList.paymentDue.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      )}
                      <hr />
                      <div className="d-flex mb-2">
                        <div>
                          <p>Total</p>
                          <p>
                            {paymentSettingData?.currency?.code
                              ? paymentSettingData?.currency?.code
                              : "USD"}
                          </p>
                        </div>
                        <div>
                          <div>
                            {addToCartList.paymentCredit ||
                            addToCartList.paymentDue ? (
                              <p>
                                {currencyCheck()}
                                {(grandTotal - partialPayment).toFixed(2)}
                              </p>
                            ) : (
                              <p>
                                {currencyCheck()}
                                {grandTotal?.toFixed(2)}
                              </p>
                            )}
                            {isMoreThanOneRecurring == 1 && (
                              <small
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {currencyCheck()}
                                {grandTotal.toFixed(2)} now, then{" "}
                                {currencyCheck()}
                                {(
                                  subTotalSection?.quantity *
                                  subTotalSection?.id?.price
                                ).toFixed(2)}
                                /
                                {subTotalSection.id.recurringOrder.durationTime}{" "}
                                {
                                  subTotalSection.id.recurringOrder
                                    .durationPeriod
                                }
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      {isTaxesEnabled &&
                        (taxableTaxRules.length ? (
                          taxableTaxRules.map((data, index) => {
                            return (
                              <div className="d-flex" key={index}>
                                <p>{data.taxRuleName}</p>
                                <p>
                                  {currencyCheck()}
                                  {calculateTaxAmount(data.taxValue)}
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <div className="d-flex">
                            <p>Taxes & Fees</p>
                            <p>
                              {currencyCheck()}
                              {calculateTaxAmount(10)}
                            </p>
                          </div>
                        ))}
                      <hr />
                      <div className="d-flex">
                        <h4>Grand Total</h4>
                        {addToCartList.paymentCredit ||
                        addToCartList.paymentDue ? (
                          <p className="grand-total">
                            {currencyCheck()}
                            {calculateTotalPayableAmount(
                              grandTotal - partialPayment
                            )}
                          </p>
                        ) : (
                          <p className="grand-total">
                            {currencyCheck()}
                            {calculateTotalPayableAmount(grandTotal)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

const Thankyou = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (props.completePurchaseResponse.docs.status == "succeeded") {
        let url =
          props.from == "invoice"
            ? "/invoice/paymentSuccess"
            : "/product/paymentSuccess";
        navigate(url, {
          state: {
            data: props.sendData,
          },
        });
      } else {
        let url =
          props.from == "invoice"
            ? "/invoice/paymentFailed"
            : "/product/paymentFailed";
        navigate(url);
      }
    }, 1000);
  }, []);

  return (
    <main className="wrapper">
      <div className="section-login">
        <div className="container">
          <div className="row h-80vh mx-0 align-items-center">
            <div className="col-lg-6 mx-auto">
              <div className="loginForm">
                <a className="brandName">OpFlow CRM</a>
                <h3>Thank you for choosing OpFlow CRM</h3>

                <p className="text-muted mb-3 text-center">
                  Your payment was successful, we are currently processing your
                  order.
                </p>
                <div className="text-center mb-2">
                  <div className="payment-loader"></div>
                </div>
                <p className="text-muted mb-5 text-center loaderText">
                  If you stay on this page you'll be logged in as soon as the
                  order is done processing. you will also receive an email with
                  your login details.
                </p>
                <div className="sectionalert">
                  <p>
                    <strong>You are currently in test mode.</strong> if this
                    page does not refresh automatically it means we're not
                    receiving notification from your payment processor. please
                    follow the instructions to them for <strong>PayPal</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Payment;