import React from "react";
import noData from "../../../assets/img/nodata-img.svg";
import { AllContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formatMonthNameAndDate,
  redirectToLogin,
} from "../../../shared/functions";
import PageLoader from "../../pageLoader";
import LoadingOverlay from 'react-loading-overlay';
import {apiPostMethod} from "../../../api/rest";
import PaginationNewUI from "../../PaginationNewUI";
/*import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import * as _ from "lodash";*/
LoadingOverlay.propTypes = undefined;

const TableSubscriptions = ({searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const navigate = useNavigate();

  /*const dispatch = useDispatch();
  const [isReset, setIsReset] = useState(false);
  const [multiDeleteId, setMultiDeleteId] = useState([]);
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");*/

  const context = useContext(AllContext);
  const { allApiUrl, toastMessage, apiDeleteMethod, apiGetMethod } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const [countTotalSubscription, setCountTotalSubscription] = useState(0);
  const [countActiveSubscription, setCountActiveSubscription] = useState(0);
  const [countCancelSubscription, setCountCancelSubscription] = useState(0);
  const [countPaidDueSubscription, setCountPaidDueSubscription] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  
  let tabs = [
    {title: 'Active', value: 'Active'},
    {title: 'Cancelled', value: 'Cancelled'},
    {title: 'Past Due', value: 'pastDue'},
    {title: 'All', value: 'All'},
  ]
  useEffect(() => {
    if (isLoadingSearch) {
      filterSubscriptions();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    filterSubscriptions();
  }, [activeTab, itemOffset, itemsPerPage]);
  const filterSubscriptions = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    let data = {};
    // let data = getFilterData();

    data.status = activeTab;
    data.page = itemOffset;
    data.limit = itemsPerPage;
    if (searchText) { data.searchText = searchText; }

    apiPostMethod(allApiUrl.FILTER_SUBSCRIPTIONS, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setSubscriptions([...res.data.subscriptions]);

          setCountTotalSubscription(res.data.countAll);
          setCountActiveSubscription(res.data.countActiveSubscription);
          setCountCancelSubscription(res.data.countCancelSubscription);
          setCountPaidDueSubscription(res.data.countPaidDueSubscription);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        // redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  }

  /*const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  useEffect(() => {
    setMultiDeleteId([])
  }, [activeTab])
  const checkedAll = () => {
    if (subscriptions.length === multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = subscriptions.map((e) => {
        return e._id;
      });
      setMultiDeleteId([...ids]);
    }
  }

  const onChangeCheckbox = (id, val) => {
    if (val) {
      multiDeleteId.push(id);
      setMultiDeleteId([..._.uniq(multiDeleteId)]);
    } else {
      _.remove(multiDeleteId, (o) => o === id);
      setMultiDeleteId([...multiDeleteId]);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  function deleteMultiple() {
    setIsLoading(true);
    multiDeleteId.map((row) => {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiDeleteMethod(allApiUrl.DELETE_SUBSCRIPTION, row, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          filterSubscriptions();
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
  }
  function clearCheckAll() {
    setIsSingleChecked(false);
    setIsReset(!isReset);
  }
  useEffect(() => {
    setMultiDeleteId([]);
  }, [isReset]);*/

  const getTabCount = (val) => {
    return (val === 'Active') ? countActiveSubscription :
      (val === 'Cancelled') ? countCancelSubscription :
        (val === 'pastDue') ? countPaidDueSubscription :
          countTotalSubscription;
  }

  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      tempArray.push(
        <li key={idx} className="nav-item" role="presentation">
          <button
            className={activeTab === item.value ? 'nav-link active' : 'nav-link'}
            id="pills-overdue-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overdue"
            type="button"
            role="tab"
            aria-controls="pills-overdue"
            aria-selected="true"
            onClick={() => {
              setActiveTab(item.value);
            }}
          >
            <span>{getTabCount(item.value)}{" "}</span>{item.title}
          </button>
        </li>
      )
    });
    return tempArray;
  }
  return (
    <>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section horizontalPadding">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Subscriptions</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-filter">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                  mainClassName="col-lg-12"
                />
              </div>
            </div>
          </div>
          <LoadingOverlay
            styles={{
              spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                  stroke: "#007D87",
              },
              }),
              overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.5)",
              }),
          }}
          active={isLoading}
          spinner
          text="Loading content..."
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <TabsList />
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="common-table clients-table rounded-1">
                      <table className="table">
                        <thead>
                        <tr>
                          {/*<th className="ps-4 w65-px">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="item1"
                                checked={ subscriptions.length && subscriptions.length === multiDeleteId.length }
                                onChange={checkedAll}
                              />
                              <label className="form-check-label" htmlFor="item1"></label>
                            </div>
                          </th>*/}
                          <th>Subscription Id</th>
                          <th>Client</th>
                          <th>Service</th>
                          <th>No of Payments</th>
                          <th>Last payment Date</th>
                          <th>Next Payment Date</th>
                          <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {subscriptions.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              {/*<td className="ps-4">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={"active" + item._id}
                                    checked={!!(_.includes(multiDeleteId, item._id))}
                                    onChange={(e) => {
                                      setIsSingleChecked(e.target.checked);
                                      onChangeCheckbox(
                                        item._id,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"active" + item._id}
                                  ></label>
                                </div>
                              </td>*/}

                              <td>
                                <a
                                  onClick={() => {
                                    document.getElementById('searchModalCloseId').click();
                                    navigate(
                                      `/view-subscription/${item.orderId}`,
                                      {
                                        state: item._id,
                                      }
                                    );
                                  }}
                                >
                                  {item.subscriptionId
                                    ? item.subscriptionId
                                    : item.orderId}
                                </a>
                              </td>
                              <td>
                                <div className="clientSection">
                                  <div className="clientName">
                                    <p>
                                      <a
                                        onClick={() => {
                                          document.getElementById('searchModalCloseId').click();
                                          navigate(
                                            `/view-client/${item.userDetails._id}`
                                          );
                                        }}
                                      >
                                        {item.userDetails &&
                                          item.userDetails &&
                                          item.userDetails.firstName &&
                                          `${item.userDetails.firstName} ${
                                            item.userDetails.lastName || " "
                                          }`}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </td>
                              {/*<td>{item?.product && item.product?.length ? item?.product[0].name : '-'}</td>*/}
                              <td>{item?.product && item?.product?.length ? item?.product.map((x) => x.name).join(', ') : '-'}</td>
                              <td>{item.paymentCount}</td>
                              <td>
                                {formatMonthNameAndDate(
                                  item.paidDate,
                                  companyData.timezone,
                                  true
                                )}
                              </td>
                              <td>
                                {" "}
                                {formatMonthNameAndDate(
                                  item.nextDueDate,
                                  companyData.timezone,
                                  true
                                )}
                              </td>
                              <td>
                                <span className="pill pill-green fs-12">
                                  Active
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        {subscriptions.length <= 0 && (
                          <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </div>
                    {/*{(multiDeleteId.length > 0 || isSingleChecked) && (
                      <div className="table-bottom-option">
                        <div className="row">
                          <div className="col-lg-3 col-md-4">
                            <div className="input-group">
                              <select
                                id="selectOption"
                                value={selectOption.target}
                                onChange={(e) => selectOption(e)}
                                className="form-control"
                              >
                                <option value="">With Selected...</option>
                                <option value="Delete">Delete</option>
                              </select>
                              <button
                                className="btn btn-apply"
                                onClick={() => {
                                  if (selectedOption === "Delete") {
                                    deleteMultiple();
                                  }
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}*/}
                  </div>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      )}
    </>
  );
};
export default TableSubscriptions;
