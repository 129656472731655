import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import {editorApiKey} from "../../shared/constants";

const EmailTemplates = (props) => {
  const [templateChanged, setTemplateChanged] = useState(false);
  useEffect(() => {
    fetchTemplate();
  }, [templateChanged]);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const [isLoading, setIsloading] = useState(false);
  const [isResetToDefault, setIsResetToDefault] = useState(false);
  const {
    allApiUrl,
    apiPostMethod,
    apiGetMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { EMAIL_TEMPLATE } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  function fetchTemplate() {
    setIsloading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(EMAIL_TEMPLATE + emailData[props.dataKey]?.url, "", header)
      .then((res) => {
        if (res.data.length) {
          setForm((prevData) => {
            return { ...prevData, subject: res.data[0]?.type };
          });
          setForm((prevData) => {
            return { ...prevData, content: res.data[0]?.template };
          });
        } else {
          setForm((prevData) => {
            return { ...prevData, subject: emailData[props.dataKey]?.type };
          });
          setForm((prevData) => {
            return {
              ...prevData,
              content: emailData[props.dataKey]?.template,
            };
          });
        }
        setIsloading(false);
      })
      .catch((err) => {

        setIsloading(false);
        toastMessage("error", err.data.message);
      });
  }

  function fetchNextTemplate() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let urlArr = emailData[props.dataKey]?.btnNextUrl.split("/");
    let urlNext = urlArr[urlArr.length - 1];
    apiGetMethod(EMAIL_TEMPLATE + emailData[urlNext]?.url, "", header)
      .then((res) => {
        if (res.data.length) {
          setForm((prevData) => {
            return { ...prevData, subject: res.data[0]?.type };
          });
          setForm((prevData) => {
            return { ...prevData, content: res.data[0]?.template };
          });
        } else {
          setForm((prevData) => {
            return { ...prevData, subject: emailData[props.dataKey]?.type };
          });
          setForm((prevData) => {
            return {
              ...prevData,
              content: emailData[props.dataKey]?.template,
            };
          });
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }

  function fetchPreviousTemplate() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let urlArr = emailData[props.dataKey]?.btnPreviousUrl.split("/");
    let urlPrev = urlArr[urlArr.length - 1];
    apiGetMethod(EMAIL_TEMPLATE + emailData[urlPrev]?.url, "", header)
      .then((res) => {
        if (res.data.length) {
          setForm((prevData) => {
            return { ...prevData, subject: res.data[0]?.type };
          });
          setForm((prevData) => {
            return { ...prevData, content: res.data[0]?.template };
          });
        } else {
          setForm((prevData) => {
            return { ...prevData, subject: emailData[props.dataKey]?.type };
          });
          setForm((prevData) => {
            return {
              ...prevData,
              content: emailData[props.dataKey]?.template,
            };
          });
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }

  let content = emailData[props.dataKey]?.template;
  const [form, setForm] = useState({
    subject: " ",
    content: "",
    fileName: " ",
    title: " ",
    category: " ",
    meta_title: " ",
    meta_tag: " ",
    popular: false,
    status: "",
    tags: " ",
    meta_description: " ",
    description: "",
  });

  function handleEditorChange(e) {
    setIsResetToDefault(false);
    setForm((prevData) => ({
      ...prevData,
      content: e,
    }));
  }
  const saveTemplateHandler = (event) => {
    event.preventDefault();
    setIsloading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {
      subject: form.subject,
      type: emailData[props.dataKey].type,
      template: form.content,
    };
    apiPostMethod(EMAIL_TEMPLATE, data, header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage(
            res.data.message === "similar data already exist"
              ? "warn"
              : "success",
            res.data.message
          );
          setIsloading(false);
          navigate("/settings/email");
        } else if (res.status == 201) {
          setIsloading(false);
          navigate("/settings/email");
          toastMessage("success", "New Template Added");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
        setIsloading(false);
      });
  };
  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} />
      <div className="commonSlidePl">

      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-back"
                onClick={() => navigate("/settings/email")}
              >
                <a href="#">
                  <i class="fa-solid fa-angle-left"></i> Email Settings{" "}
                </a>
              </div>
            </div>

            <div className="col-lg-9 mx-auto">
              <div className="setting-form p45-large">
                <h3 className="emailHeading fs-4 mb-4">
                  {emailData[props.dataKey].heading}
                  <div className="heading-btns">
                    <button
                      disabled={emailData[props.dataKey].btnPreviousUrl == "NA"}
                      className="btn btn-reset"
                      onClick={() => {
                        navigate(emailData[props.dataKey].btnPreviousUrl);
                        fetchPreviousTemplate();
                      }}
                    >
                      Previous
                    </button>
                    <button
                      disabled={emailData[props.dataKey].btnNextUrl == "NA"}
                      className="btn btn-reset"
                      onClick={() => {
                        navigate(emailData[props.dataKey].btnNextUrl);
                        fetchNextTemplate();
                      }}
                    >
                      Next
                    </button>
                  </div>
                </h3>
                <form className="" onSubmit={saveTemplateHandler}>
                  <div className="commonForm rounded-1">
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={form.subject}
                        onChange={(e) => {
                          setForm((prevData) => ({
                            ...prevData,
                            subject: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Message</label>
                      <Editor
                        required
                        apiKey={editorApiKey}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={isResetToDefault ? content : form.content}
                        init={{
                          selector: "textarea",
                          height: 500,
                          menu: {
                            custom: {
                              title: "custom",
                              items: "basicitem nesteditem toggleitem",
                            },
                          },
                          toolbar:
                            "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                            "fullpage" +
                            "indent | blockquote | codesample | fontselect ",
                          font_formats:
                            "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                          block_formats:
                            "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                          plugins: [
                            "image",
                            "help",
                            "link",
                            "codesample",
                            "lists",
                          ],
                          branding: false,
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                          menubar: false,
                          setup: function (editor) {
                            editor.ui.registry.addSplitButton("myButton", {
                              icon: "user",
                              onAction: function () {
                              },
                              onItemAction: function (api, value) {
                                editor.insertContent(value);
                              },
                              fetch: function (callback) {
                                var items = [
                                  {
                                    type: "choiceitem",

                                    text: "first Name",
                                    value: "&nbsp;<em>{{user.firstName}}</em>",
                                  },
                                  {
                                    type: "choiceitem",

                                    text: "last Name",
                                    value: "&nbsp;<em>{{user.lastName}}</em>",
                                  },
                                  {
                                    type: "choiceitem",

                                    text: "email",
                                    value: "&nbsp;<em>{{user.email}}</em>",
                                  },
                                  {
                                    type: "choiceitem",

                                    text: "Link",
                                    value:
                                      "&nbsp;<em><a href='{{link}}'>Click here to view</a></em>",
                                  },
                                ];
                                callback(items);
                              },
                            });
                          },
                        }}
                        onEditorChange={(e) => handleEditorChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      {/* <div className="previews-btn">
                      <button className="btn btn-default">Preview</button>
                      <button className="btn btn-default">Send test</button>
                      <button className="btn btn-default">Edit HTML</button>
                      <button className="btn btn-default">Disable</button>
                    </div> */}
                    </div>
                    <div className="col-lg-6">
                      <div className="btn-section text-end">
                        <button
                          className="btn btn-reset"
                          onClick={() => setIsResetToDefault(true)} //place it inside form ui changes
                        >
                          Reset to default
                        </button>
                        <button
                          disabled={!form.subject}
                          className="btn btn-save "
                          // onClick={saveTemplateHandler}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default EmailTemplates;

export const emailData = {
  client_welcome: {
    url: "/welcome-email",
    template: `<div>Hey {{ user.firstName }},</div><div>Thanks for signing up. Your login information is below:<br><br></div><br><div><strong>Login:</strong>&nbsp;{{ user.email }}<br><strong>Password:</strong> {{ password }}&nbsp;</div><pre>&nbsp;</pre><div>You can sign in here:<br>{{ link }}</div><br><div>Please bookmark the login link and add this email to your address book.<br><br></div><div>Thanks,<br>&nbsp;{{'site_name'}}<br>&nbsp;</div>`,
    type: "welcome-email",
    heading: "Client Welcome Email",
    btnPreviousUrl: "NA",
    btnNextUrl: "/settings/email/template/client_password_reset",
  },
  client_password_reset: {
    url: "/reset-password",
    template: `<div>Hey {{ user.firstName }},</div><div>You recently asked to reset the password for this account:<br><br></div><br><div><strong>Login:</strong>&nbsp;{{ user.email }}<br><div>To update your password, click the link below:<br>{{ link }}</div><br><div>Please bookmark the login link and add this email to your address book.<br><br></div><div>Thanks,<br>&nbsp;{{ site_name }}<br>&nbsp;</div>`,
    type: "reset-password",
    heading: "Client Password Reset",
    btnPreviousUrl: "/settings/email/template/client_welcome",
    btnNextUrl: "/settings/email/template/client_order_incomplete",
  },
  client_order_incomplete: {
    url: "/order-incomplete-email",
    template: `<div>Hey {{ user.firstName }},</div><div>It looks like you've tried to place an order with us yesterday. You can sign into your account and complete it when you're ready. Otherwise, just ignore this message and we'll delete the order.<br><br></div><br><div>{{ link }}</div><br><br><div>Regards,<br>Admin</div>`,
    type: "order-incomplete-email",
    heading: "Client Order Incomplete Email",
    btnPreviousUrl: "/settings/email/template/client_password_reset",
    btnNextUrl: "/settings/email/template/subscription_failed",
  },
  // client_receipt: {
  //   url: "/client_receipt",
  //   template: `<div>Hey {{ user.firstName }},</div><div>It looks like you've tried to place an order with us yesterday. You can sign into your account and complete it when you're ready. Otherwise, just ignore this message and we'll delete the order.<br><br></div><br><div>{{ link }}</div><br><br><div>Regards,<br>Admin</div>`,
  //   type: "client_receipt",
  //   heading: "client_receipt",
  //   btnPreviousUrl: "/settings/email/template/client_password_reset",
  //   btnNextUrl: "/settings/email/template/client_order_incomplete",
  // },
  // subscription_failed: {
  //   url: "/subscription_failed",
  //   template: `<div>Hi {{ user.firstName}},<br><br></div>
  //   <div>We couldn't bill your {{ payment_method }} for order&nbsp;#{{ invoice.id }}</a>. The error we got was:&nbsp;<em>{{ reason }}</em>. &nbsp;<br><br></div>
  //   <div>{{email.subscription_update_link}}</div>
  //   <div><br><br></div>
  //   <div>If you have any questions please reply here.<br><br></div>
  //   <div>Thanks,<br>{{ site_name }}</div>`,
  //   type: "subscription_failed",
  //   heading: "subscription_failed",
  //   btnPreviousUrl: "/settings/email/template/client_password_reset",
  //   btnNextUrl: "/settings/email/template/client_order_incomplete",
  // },
  subscription_failed: {
    url: "/subscription_failed",
    template: ` <div>
    <div>Hey {{ user.firstName }},<br><br></div>
    <div>A recurring subscription for invoice #{{ invoice.id }} has failed. Click the link below to see it:<br><br></div>
    <div>{{ link }}</div>
    </div>
    <div>
    <div>&nbsp;</div>
    </div>
    <div>&nbsp;</div>
     `,
    type: "subscription_failed",
    heading: "subscription_failed",
    btnPreviousUrl: "/settings/email/template/client_order_incomplete",
    btnNextUrl: "/settings/email/template/subscription_cancelled",
  },
  subscription_cancelled: {
    url: "/subscription_cancelled",
    template: `Hey {{ user.firstName }},<br><br></div>
    <div>It looks like your subscription for order #{{ invoice.id }} has been canceled and you&rsquo;ll no longer be receiving our services. If this is an error, please reply and let us know.<br><br></div>
    <div>{{ link }}<br></div>
    <div>Regards,<br>Admin</div>`,
    type: "subscription_cancelled",
    heading: "subscription_cancelled",
    btnPreviousUrl: "/settings/email/template/subscription_failed",
    btnNextUrl: "/settings/email/template/payment_refunded",
  },
  payment_refunded: {
    url: "/payment_refunded",
    template: ` <div>Hey {{  user.firstName }},<br><br></div>
    <div>You've received a refund for invoice #{{ invoice.id }}. Click the link below to see it in your account:<br><br></div>
    <div>{{ link }}<br></div>
    <div>Thanks,<br>&nbsp;{{ site_name }}</div>`,
    type: "payment_refunded",
    heading: "payment_refunded",
    btnPreviousUrl: "/settings/email/template/subscription_cancelled",
    btnNextUrl: "/settings/email/template/new_invoice",
  },
  new_invoice: {
    url: "/new_invoice",
    template: ` <div>
    <div>Hey {{ user.firstName}},<br><br></div>
    <div>You've got a new invoice in your account. You can pay it here:<br>&nbsp;{{ link }}<br></div>
    <div>Thanks,<br>{{ site_name }}</div>
    </div>`,
    type: "new_invoice",
    heading: "new_invoice",
    btnPreviousUrl: "/settings/email/template/payment_refunded",
    btnNextUrl: "/settings/email/template/order_updated",
  },
  order_updated: {
    url: "/order_updated",
    template: `<div>Hey {{ user.firstName }},<br><br></div>
    <div>You've got an unpaid invoice in your account. Please pay it here:<br><br></div>
    <div><a href="#">{{ link }}<br></a></div>
    <div>Thanks,<br>{{ site_name }}<br><br></div>
    `,
    type: "order_updated",
    heading: "order_updated",
    btnPreviousUrl: "/settings/email/template/new_invoice",
    btnNextUrl: "/settings/email/template/order_missing_project_data",
  },
  order_missing_project_data: {
    url: "/order_missing_project_data",
    template: ` <div>Hey {{user.firstName }},<br><br></div>
    <div>We need some information from you before we can begin working on your order. Please click the link below to sign in and provide your details:<br><br></div>
    <div>{{ link }}<br></div>
    <div>Thanks,<br>&nbsp;{{ site_name }}</div>`,
    type: "order_missing_project_data",
    heading: "order_missing_project_data",
    btnPreviousUrl: "/settings/email/template/order_updated",
    btnNextUrl: "/settings/email/template/order_needs_attention",
  },
  order_needs_attention: {
    url: "/order_needs_attention",
    template: `<div>You've got a new order #{{ order.id }} that needs your attention.<br><br></div>
    <div><strong>Client:</strong><br>{{ order.client.firstName }} {{ order.client.lastName }}&nbsp;<br><br></div>
    <div><strong>Service:</strong><br>{{ order.service }}&nbsp;<br><br></div>
    <div>&nbsp;Click here to view it:<br>&nbsp;{{ link }}<br></div>`,
    type: "order_needs_attention",
    heading: "order_needs_attention",
    btnPreviousUrl: "/settings/email/template/order_missing_project_data",
    btnNextUrl: "/settings/email/template/order_data_submitted",
  },
  order_data_submitted: {
    url: "/order_data_submitted",
    template: `<div>Hey  {{user.firstName }},<br><br></div>
    <div>Client has submitted data for order&nbsp;#{{ order.id }}.<br><br></div>
    <div><br><br></div>
    <div><strong>Client:</strong><br>{{ order.client.name_f }} {{ order.client.firstName }}&nbsp;<br><br></div>
    <div><strong>Service:</strong><br>{{ order.service }}&nbsp;<br><br></div>
    <div>&nbsp;</div>`,
    type: "order_data_submitted",
    heading: "order_data_submitted",
    btnPreviousUrl: "/settings/email/template/order_needs_attention",
    btnNextUrl: "/settings/email/template/order_canceled",
  },
  order_canceled: {
    url: "/order_canceled",
    template: `<div>
    <div>Hey  {{user.firstName }},<br><br></div>
    <div>Order #{{ order.id }} has been canceled due to a refund.<br><br></div>
    <div>Click here to view the order:<br>{{ link }}</div>
    </div>
    <div>&nbsp;</div>`,
    type: "order_canceled",
    heading: "order_canceled",
    btnPreviousUrl: "/settings/email/template/order_data_submitted",
    btnNextUrl: "/settings/email/template/recurring_payment",
  },
  recurring_payment: {
    url: "/recurring_payment",
    template: `<div>
    <div>Hey {{ user.firstName }},<br><br></div>
    <div>Recurring payment for order #{{ order.id }} was received. Click here to view the order:<br>{{ link }}</div>
    </div>
    <div>&nbsp;</div>`,
    type: "recurring_payment",
    heading: "recurring_payment",
    btnPreviousUrl: "/settings/email/template/order_canceled",
    btnNextUrl: "/settings/email/template/upcoming_payment_reminder",
  },
  upcoming_payment_reminder: {
    url: "/upcoming_payment_reminder",
    template: `<div>
    <div>Hey {{ user.firstName }},<br><br></div>
    <div>Recurring payment for order #{{ order.id }} was received. Click here to view the order:<br>{{ link }}</div>
    </div>
    <div>&nbsp;</div>`,
    type: "upcoming_payment_reminder",
    heading: "upcoming_payment_reminder",
    btnPreviousUrl: "/settings/email/template/recurring_payment",
    btnNextUrl: "/settings/email/template/unpaid_invoice_reminder",
  },
  unpaid_invoice_reminder: {
    url: "/unpaid_invoice_reminder",
    template: ` <div>
    <div>Hi {{ user.firstName }},<br><br></div>
    <div>Your subscription for {{ subsricptionId }} is about to renew in 7 days on&nbsp;<strong>{{ subscriptionDate}}</strong>.<br><br></div>
    <div>No action is needed from your side, we'll send you an email if there's a problem. To view your order or update your payment method click the link below:<br><a href="https://linkhr.spp.io/settings/email/template/%7B%7B%20link%20%7D%7D">{{ link }}<br></a></div>
    <div>If you have any questions feel free to reply here.<br><br></div>
    <div>Regards,<br>Admin</div>
    </div>
    <div>
    <div>&nbsp;</div>
    </div>
    <div>&nbsp;</div>`,
    type: "unpaid_invoice_reminder",
    heading: "unpaid_invoice_reminder",
    btnPreviousUrl: "/settings/email/template/upcoming_payment_reminder",
    btnNextUrl: "/settings/email/template/invoice_paid",
  },
  invoice_paid: {
    url: "/invoice_paid",
    template: `<div>
    <div>Hey {{ user.firstName }},<br><br></div>
    <div>You received a payment for invoice #{{ invoice.id }}.<br><br></div>
    </div>
    <div>&nbsp;</div>
    `,
    type: "invoice_paid",
    heading: "invoice_paid",
    btnPreviousUrl: "/settings/email/template/unpaid_invoice_reminder",
    btnNextUrl: "/settings/email/template/subscription_canceled_team",
  },
  subscription_canceled_team: {
    url: "/subscription_canceled_team",
    template: `<div>
    <div>Hey {{ user.firtName }},<br><br></div>
    <div>A recurring subscription for invoice #{{ invoice.id }} has been canceled. Click the link below to see it:<br><br></div>
    <div>{{ link }}<br></div>
    </div>
    <div>&nbsp;</div>
    `,
    type: "subscription_canceled_team",
    heading: "subscription_canceled_team",
    btnPreviousUrl: "/settings/email/template/invoice_paid",
    btnNextUrl: "/settings/email/template/subscription_failed_team",
  },
  subscription_failed_team: {
    url: "/subscription_failed_team",
    template: `  <div>
    <div>Hey {{ user.firstName }},<br><br></div>
    <div>A recurring subscription for invoice #{{ invoice.id }} has failed. Click the link below to see it:<br><br></div>
    <div>{{ link }}</div>
    </div>
    <div>
    <div>&nbsp;</div>
    </div>
    <div>&nbsp;</div>
     `,
    type: "subscription_failed_team",
    heading: "subscription_failed_team",
    btnPreviousUrl: "/settings/email/template/subscription_canceled_team",
    btnNextUrl: "NA",
  },
};