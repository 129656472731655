import { getCountries } from 'node-countries';

import { Select } from "antd";
import { useEffect, useState } from 'react';
const { Option } = Select;
const countriesData = getCountries();

const AdminBillingAddress = (props) => {
    const {companyName, address, homeCountry, homeProvince, city, postalCode, taxId, handleBillingAddressChange, handleSelectChange} = props;
    const [provinces, setProvinces] = useState([]);

    useEffect(() => {
        if(homeCountry) {
            const selectedCountry = countriesData.filter(data => data.name.toLowerCase() === homeCountry.toLowerCase());
            if(selectedCountry[0].provinces) {
                setProvinces(selectedCountry[0].provinces);
            } else {
                setProvinces([]);
            }
        }
    }, [])

    const handleOnChangeCountry = (event) => {
        const selectedCountry = countriesData.filter(data => data.name.toLowerCase() === event.toLowerCase());
        if(selectedCountry[0].provinces) {
            setProvinces(selectedCountry[0].provinces);
        } else {
            setProvinces([]);
        }
        handleSelectChange(selectedCountry[0].name, "homeCountry");
    }

    return (
        <div className="billing-address-wrapper">
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Company Name</label>
                        <input
                            required
                            name="companyName"
                            type="text"
                            value={companyName}
                            className="form-control"
                            onChange={handleBillingAddressChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6">   
                    <div className="form-group">
                        <label>Address</label>
                        <input
                            required
                            name="address"
                            type="text"
                            value={address}
                            className="form-control"
                            onChange={handleBillingAddressChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">     
                    <div className="form-group">
                        <label>Home Country</label>
                        <div className="mt-3">
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Search to Country"
                                value={homeCountry}
                                onChange={(e) => handleOnChangeCountry(e)}
                            >
                            {countriesData.map((item, idx) => {
                                return (
                                    <Option key={idx} value={item.name}>{item.name}</Option>
                                );
                            })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">    
                    <div className="form-group">
                        <label>Home Province</label>
                        <div className="mt-3">
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Search to Province"
                                value={homeProvince}
                                onChange={(e) => handleSelectChange(e, "homeProvince")}
                            >
                            {provinces.map((item, idx) => {
                                return (
                                    <Option key={idx} value={item.name}>{item.name}</Option>
                                );
                            })}
                            </Select>
                        </div>
                    </div>
                </div>    
            </div>
            <div className="row">
                <div className="col-lg-6">  
                    <div className="form-group">
                        <label>City</label>
                        <input
                            required
                            name="city"
                            type="text"
                            value={city}
                            className="form-control"
                            onChange={handleBillingAddressChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6">    
                    <div className="form-group">
                        <label>Postal Code</label>
                        <input
                            required
                            name="postalCode"
                            type="text"
                            value={postalCode}
                            className="form-control"
                            onChange={handleBillingAddressChange}
                        />
                    </div>
                </div>    
            </div>
            <div className="row">
                <div className="col-lg-6">  
                    <div className="form-group">
                        <label>Tax Id</label>
                        <input
                            required
                            name="taxId"
                            type="text"
                            value={taxId}
                            className="form-control"
                            onChange={handleBillingAddressChange}
                        />
                    </div>
                </div>    
            </div>    
        </div>    
    )
}
export default AdminBillingAddress;