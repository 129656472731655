import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DummyAvatar from "../../assets/img/avatar.png";
import ReactTooltip from "react-tooltip";
import PageLoader from "../../components/pageLoader";
import { convertToUpperCase } from "../../shared/functions";
import LoadingOverlay from "react-loading-overlay";
import * as _ from "lodash";
import openIcon from "../../assets/img/icons/openSide.svg";
import sortIcon from "../../assets/img/icons/sortIcon.svg";
import { SpinnerCircularFixed } from "spinners-react";
import PaginationNewUI from "../../components/PaginationNewUI";
import SelectAsync from "../../components/SelectAsync";
LoadingOverlay.propTypes = undefined;

const Tickets = () => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [activeTab, setActiveTab] = useState("open");

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const roleList = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const [countStatus, setCountStatus] = useState({});
  const [ticketsList, setTicketsList] = useState([]); // currentPage
  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords

  const [tabs, setTabs] = useState([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const dispatch = useDispatch();
  const [selectedTag, setSelectedTag] = useState({} | null);
  const [selectedClient, setSelectedClient] = useState([] | null);
  const [selectedTeam, setSelectedTeam] = useState([] | null);

  useEffect(() => {
    filterTickets();
  }, [activeTab, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (!filterCount && !initialLoading) {
      filterTickets();
    }
  }, [filterCount]);

  useEffect(() => {
    setBulkDeleteId([]);
  }, [activeTab]);

  const viewTickets = (_id, data) => {
    navigate("/view-ticket/" + _id, { state: { data: data } });
  };
  const businessId = useSelector(
    (state) => state.loginReducer.loginUserInfo.businessId
  );
  const filterTickets = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let count = 0;
    const data = {};

    if (selectedTag && selectedTag.value) {
      data.tags = selectedTag.value;
    }

    if (selectedTeam.length > 0) {
      let userlist = [...selectedTeam];
      userlist.map((list, index) => {
        userlist[index] = list.value;
      });
      data.assignedUsers = userlist;
    }

    if (selectedClient.length > 0) {
      let clientSelectedList = [...selectedClient];
      clientSelectedList.map((list, index) => {
        clientSelectedList[index] = list.value;
      });
      data.client = clientSelectedList;
    }

    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    for (let key in data) {
      count += 1;
    }
    if (count !== filterCount) {
      setFilterCount(count);
    }

    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.status = activeTab;
    data.businessId= businessId;

    apiPostMethod(allApiUrl.FILTER_TICKETS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setTicketsList([...res.data.filteredTickets]);
          setCountStatus({ ...res.data.countStatus });
          setTabs(res.data.statusListNames);
          setInitialLoading(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
        setIsLoading(false);
      });
  };

  function deleteMultiple() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.DELETE_TICKET_BULK, { ids: bulkDeleteId }, header)
      .then((res) => {
        if (res.status === 200) {
          setBulkDeleteId([...[]]);
          toastMessage("success", res.data.message);
          filterTickets();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  function checkedAll() {
    if (ticketsList.length === bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = ticketsList.map((e) => {
        return e._id;
      });
      setBulkDeleteId([...ids]);
    }
  }

  const onChangeCheckbox = (id, val) => {
    if (val) {
      bulkDeleteId.push(id);
      setBulkDeleteId([..._.uniq(bulkDeleteId)]);
    } else {
      _.remove(bulkDeleteId, (o) => o === id);
      setBulkDeleteId([...bulkDeleteId]);
    }
  };
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  function updateMultipleStatus(status) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(
      allApiUrl.UPDATE_TICKET_STATUS_BULK,
      {
        ids: bulkDeleteId,
        status,
      },
      header
    )
      .then((res) => {
        setIsLoading(false);
        setBulkDeleteId([...[]]);
        toastMessage("success", res.data.message);
        filterTickets();
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  const getTabCount = (val) => {
    return countStatus[val];
  };
  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      tempArray.push(
        <li key={idx} className="nav-item" role="presentation">
          <button
            className={
              activeTab.toLowerCase() === item ? "nav-link active" : "nav-link"
            }
            id="pills-overdue-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overdue"
            type="button"
            role="tab"
            aria-controls="pills-overdue"
            aria-selected="true"
            onClick={() => {
              setActiveTab(item);
            }}
          >
            {convertToUpperCase(item)}
            <span>({getTabCount(item)})</span>
          </button>
        </li>
      );
    });
    return tempArray;
  };

  return (
    <div>
      <Sidebar
        activePage="tickets"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Tickets
                    <span className="userCounter">&nbsp;</span>
                    {roleList?.ticketManagement?.add_delete && (
                      <a
                        className="btn btn-addOrder"
                        onClick={() =>
                          navigate("/add-ticket", {
                            state: {
                              mailTo: "",
                              from: "add-ticket",
                            },
                          })
                        }
                      >
                        Add Tickets
                      </a>
                    )}
                    {/* <span className="userCounter">&nbsp;</span> */}
                    {/* <a
                    className="btn btn-addOrder dropdown-toggle btn-filter"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={getFilters}
                  >
                    <i className="fa-solid fa-filter"></i> Filter |{" "}
                    {filterCount}
                  </a>
                  <div className="dropdown-menu filter-drowdown">
                    <div className="filter-btns">

                    </div>
                  </div> */}
                  </h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>

              <PaginationNewUI
                itemsPerPage={itemsPerPage}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                pageCount={pageCount}
                totalRecordsCount={totalRecordsCount}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </div>
        {!initialLoading && (
          <div className="orders-section commonPadding horizontalPadding">
            <div className="container-fluid">
              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                      stroke: "#007D87",
                    },
                  }),
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
                active={isLoading}
                spinner
                text="Loading content..."
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="topFilters flex-wrap">
                      <p>Filter By:</p>
                      <SelectAsync
                        placeholder="Any Tags"
                        searchType="tags"
                        selectedValue={selectedTag}
                        onChange={(e) => {
                          setSelectedTag({
                            label: e?.label,
                            value: e?.label, // tags search by name
                          });
                        }}
                      />

                      <SelectAsync
                        isMulti={true}
                        placeholder="Any Assigned User"
                        searchType="teams"
                        selectedValue={selectedTeam}
                        onChange={(list) => {
                          const data = list.map((e) => {
                            return {
                              label:
                                typeof e.label === "object" && e.label !== null
                                  ? e?.label?.props?.children[0]
                                  : e?.label,
                              value: e.value,
                            };
                          });
                          setSelectedTeam(data);
                        }}
                      />

                      <SelectAsync
                        isMulti={true}
                        placeholder="Any Client"
                        searchType="multiClients"
                        selectedValue={selectedClient}
                        onChange={(list) => {
                          const data = list.map((e) => {
                            return {
                              label:
                                typeof e.label === "object" && e.label !== null
                                  ? e?.label?.props?.children[0]
                                  : e?.label,
                              value: e.value,
                            };
                          });
                          setSelectedClient(data);
                        }}
                      />

                      {isLoadingFilters && (
                        <button className="btn">
                          <SpinnerCircularFixed
                            size="25"
                            secondaryColor=""
                            color="#201b50"
                            thickness="200"
                          />
                        </button>
                      )}
                      <button
                        className="btn btn-clear"
                        onClick={() => {
                          setSelectedTag(null);
                          setSelectedClient([]);
                          setSelectedTeam([]);
                          setFilterCount(0);
                        }}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-update"
                        onClick={() => filterTickets()}
                      >
                        Apply
                      </button>
                    </div>
                    <div className="common-tabs">
                      <ul
                        className="nav nav-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <TabsList />
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-overdue"
                          role="tabpanel"
                          aria-labelledby="pills-overdue-tab"
                          tabIndex="0"
                        >
                          <div className="common-table clients-table rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        className="selectAll form-check-input"
                                        type="checkbox"
                                        id="selectAll"
                                        checked={
                                          ticketsList.length ===
                                          bulkDeleteId.length
                                        }
                                        onChange={() => checkedAll()}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="selectAll"
                                      ></label>
                                    </div>
                                  </th>
                                  <th>
                                    Client{" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                  <th>
                                    Assigned User{" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                  <th>
                                    Status{" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                  <th>
                                    Setup(Waiting since){" "}
                                    <span>
                                      {" "}
                                      <img src={sortIcon} />{" "}
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {ticketsList?.map((value, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td className="ps-4 w65-px">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="item2"
                                            checked={
                                              !!_.includes(
                                                bulkDeleteId,
                                                value._id
                                              )
                                            }
                                            onChange={(e) => {
                                              onChangeCheckbox(
                                                value._id,
                                                e.target.checked
                                              );
                                            }}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="item2"
                                          ></label>
                                        </div>
                                      </td>
                                      <td
                                        onClick={() =>
                                          viewTickets(value._id, value)
                                        }
                                      >
                                        <div className="ticketDetail">
                                          <p>
                                            <a className="ticketClientEmail">
                                              {value.to}
                                            </a>
                                            <span className="pill pill-grey ms-2">
                                              {value.message.length}
                                            </span>
                                            {value.tags.map((tag, idx) => (
                                              <span
                                                key={idx}
                                                className="pill pill-blue mx-1" //   className="serviceDescription"
                                                dangerouslySetInnerHTML={{
                                                  __html: tag,
                                                }}
                                              ></span>
                                            ))}
                                          </p>

                                          <p>
                                            <span>{value.subject}</span>
                                          </p>
                                        </div>
                                      </td>
                                      <td
                                        onClick={() =>
                                          viewTickets(value._id, value)
                                        }
                                      >
                                        <div className="assignedImg">
                                          {value.assignedUsers.map(
                                            (item, idx) => {
                                              return (
                                                <>
                                                  <img
                                                    key={idx}
                                                    className="ms-n1"
                                                    src={
                                                      item.image
                                                        ? item.image
                                                        : DummyAvatar
                                                    }
                                                    data-tip={
                                                      item.firstName +
                                                      " " +
                                                      item.lastName
                                                    }
                                                  />
                                                  <ReactTooltip />
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </td>

                                      <td
                                        onClick={() =>
                                          viewTickets(value._id, value)
                                        }
                                      >
                                        <span className="pill pill-grey fw-normal fs-12">
                                          {value.status}
                                        </span>
                                      </td>
                                      <td
                                        onClick={() =>
                                          viewTickets(value._id, value)
                                        }
                                      >
                                        {moment(value.date).format("MMM DD")}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {ticketsList.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {bulkDeleteId.length > 0 &&
                        roleList?.ticketManagement?.add_delete && (
                          <div className="table-bottom-option">
                            <div className="row">
                              <div className="col-lg-3 col-md-4">
                                <div className="input-group">
                                  <select
                                    id="selectOption"
                                    value={selectOption.target}
                                    onChange={(e) => {
                                      selectOption(e);
                                    }}
                                    className="form-control"
                                  >
                                    <option>With Selected...</option>
                                    {tabs.map((item, idx2) => {
                                      return (
                                        <option
                                          key={idx2}
                                          value={item}
                                          className="text-capitalize"
                                        >
                                          Set as {item}
                                        </option>
                                      );
                                    })}
                                    <option value="Delete">Delete</option>
                                  </select>
                                  <button
                                    className="btn btn-apply"
                                    onClick={() => {
                                      if (selectedOption === "Delete") {
                                        deleteMultiple();
                                      }
                                      if (
                                        tabs.find(
                                          (item) => item === selectedOption
                                        )
                                      ) {
                                        updateMultipleStatus(selectedOption);
                                      }
                                    }}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Tickets;
