import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllContext } from "../../context";
import { handleGetTime, formatMonthNameAndDate } from "../../shared/functions";
import PageLoader from "../../components/pageLoader";
const ViewSubscription = () => {
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [orderByList, setOrderByList] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [orderstatusName,setOrderStatusName] = useState("");
  const [modalData, setModalData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState(false);
  const {
    allApiUrl,
    apiPostMethod,
    redirectToLogin,
    toastMessage,
    apiPutMethod,
    apiGetMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const userInfoRoles = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );

  const { companyData, paymentSettingData } = useSelector(
    (state) => state.sideMenuReducer
  );
  const { currency } = paymentSettingData;

  useEffect(() => {
    getSpecific();
  }, []);
  async function getSpecific() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.SUB + "/" + state,
        "",
        header
      );
      if (response.status === 200) {
        setIsLoading(false);
        setSubscriptionDetail(response.data);
        getOrderListingOrderBy(response.data.orderId);
        getInvoiceDetail(response.data.orderId);
        getLogs(response.data.orderId);
        setInitialLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  async function getOrderListingOrderBy(id) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.ORDERBYID + "/",
        id,
        header
      );
      if (response.status === 200) {
        setOrderByList(response.data.order);
        setOrderStatusName(response.data.statusName)
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data.message);
    }
  }
  async function getInvoiceDetail(id) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.GET_ORDER_DETAILS + "/" + id,
        "",
        header
      );
      if (response.status === 200) {
        setInvoiceDetail(response.data.invoice);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data.message);
    }
  }

  const getLogs = (id) => {
    const data = {
      resourceId: id,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.FILTER_LOGS, data, header)
      .then((res) => {
        if (res.status === 200) {
          setLogs(res.data);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  };
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }

  function cancelSubscriptionHandler() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {
      userId: subscriptionDetail.userDetails._id,
    };
    setIsLoading(true);
    if (isSubscriptionCancelled == false) {
      toastMessage("error", "The cancel field is required.");
    } else {
      apiPutMethod(
        allApiUrl.CANCEL_SUBSCRIPTION + "/" + subscriptionDetail._id,
        data,
        header
      )
        .then((res) => {
          if (res.status === 200) {
            toastMessage("success", res.data.message);
            getSpecific();
            setIsLoading(false);
          }
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  }

  return (
    <div>
      <Sidebar
        activePage="subscription"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="commonSlidePl">
        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-back">
                  <a
                    href="#"
                    onClick={() => {
                      navigate("/subscription");
                    }}
                  >
                    {" "}
                    <i className="fa-solid fa-angle-left"></i> Subscriptions{" "}
                  </a>
                </div>
              </div>

              <div className="col-xxl-8 col-lg-12 mx-auto">
                <div className="client-heading mt-3">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="d-flex align-items-center">
                        {/* <div className="userImg">
                                        <img src={DummyAvatar} />
                                    </div> */}
                        <div className="userName m-0">
                          <h3 className="fs-4">
                            {subscriptionDetail &&
                              subscriptionDetail.product &&
                              subscriptionDetail.product.length > 0 &&
                              subscriptionDetail.product[0].name}

                            <span
                              className={`pill ${
                                subscriptionDetail.serviceStatus == true &&
                                subscriptionDetail.paymentStatus == "paid"
                                  ? "pill-green"
                                  : subscriptionDetail.serviceStatus == true &&
                                    subscriptionDetail.paymentStatus ==
                                      "past due"
                                  ? "pill-blue "
                                  : "pill-red"
                              } mx-2`}
                            >
                              {capitalizeFirstLetter(
                                subscriptionDetail.serviceStatus == true &&
                                  subscriptionDetail.paymentStatus == "paid"
                                  ? "Active"
                                  : subscriptionDetail.serviceStatus == true &&
                                    subscriptionDetail.paymentStatus ==
                                      "past due"
                                  ? "past due"
                                  : "Cancelled"
                              )}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>

                    {(userInfoRoles.invoiceAndSubscriptionAccess.ownInvoices ||
                      userInfoRoles.invoiceAndSubscriptionAccess
                        .allInvoices) && (
                      <div className="col-lg-6 col-md-6">
                        <div className="btns">
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle btn-info px-3"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul className="dropdown-menu">
                              {/* <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#paymentLinkModal"
                              >
                                Payment Link
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                              >
                                Change Plan
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                              >
                                Charge Now
                              </a>
                            </li> */}
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                >
                                  Cancel Subscription
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="client-details rounded-1">
                  <h4 className="subsInfo">General Information</h4>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <p className="clientLabel">Subscription ID</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="clientText">
                        {subscriptionDetail.subscriptionId}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <p className="clientLabel">Client</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="clientText">
                        <a
                          onClick={() => {
                            navigate(
                              `/view-client/${subscriptionDetail.userDetails._id}`
                            );
                          }}
                        >
                          {(subscriptionDetail.userDetails &&
                            subscriptionDetail.userDetails.firstName &&
                            (subscriptionDetail.userDetails.firstName || " ") +
                              " " +
                              subscriptionDetail.userDetails.lastName) ||
                            " "}
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <p className="clientLabel">Started Date</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="clientText">
                        {formatMonthNameAndDate(
                          subscriptionDetail.startDate,
                          companyData.timezone
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <p className="clientLabel">Next payment</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="clientText">
                        {formatMonthNameAndDate(
                          subscriptionDetail.nextDueDate,
                          companyData.timezone,
                          true
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <p className="clientLabel">Payment method</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="clientText">
                        {subscriptionDetail.paymentMethod}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <p className="clientLabel">Origin</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="clientText">{subscriptionDetail.origin}</p>
                    </div>
                  </div>
                </div>

                <div className="payments mt-5 mb-5">
                  <div className="heading">
                    <h2 className="fs-4 mb-3">Payments</h2>
                  </div>
                  <div className="payment-table rounded-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Invoice</th>
                          <th>Date</th>
                          <th>Total Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              onClick={() =>
                                navigate(
                                  `/view-invoice/${invoiceDetail.orderId}`,
                                  {
                                    state: {
                                      _id: invoiceDetail._id,
                                    },
                                  }
                                )
                              }
                            >
                              {invoiceDetail.orderId}
                            </a>
                          </td>
                          <td>
                            {formatMonthNameAndDate(
                              invoiceDetail.createdDate,
                              companyData.timezone
                            )}
                          </td>
                          <td>
                            {currency?.symbol ? currency?.symbol : "$"}
                            {invoiceDetail?.totalPrice?.toFixed(2)}
                          </td>
                          <td>
                            <span className="pill pill-green">
                              {capitalizeFirstLetter(invoiceDetail.status)}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="payments mt-5 mb-5">
                  <div className="heading">
                    <h2 className="fs-4 mb-3">Orders</h2>
                  </div>
                  <div className="payment-table rounded-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Title</th>
                          <th>Created Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderByList &&
                          orderByList?.map((order, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <a
                                    onClick={() => {
                                      navigate(`/view-orders/${order.orderId}`);
                                    }}
                                  >
                                    {order.orderId}
                                  </a>
                                </td>
                                <td>{order.title}</td>
                                <td>
                                  {formatMonthNameAndDate(
                                    order.createdDate,
                                    companyData.timezone
                                  )}
                                </td>
                                <td>
                                  <span className="pill pill-blue">
                                    {capitalizeFirstLetter(orderstatusName)}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="heading">
                  <h2 className="fs-4 mb-3">History</h2>
                </div>

                <div className="history-table mb-5 rounded-1">
                  {logs &&
                    logs
                      .map((item, idx) => {
                        return (
                          <div key={idx} className="row">
                            <div className="col-lg-2  col-md-2">
                              <div className="history-date">
                                <p>
                                  {" "}
                                  {formatMonthNameAndDate(
                                    item.timestamp,
                                    companyData.timezone
                                  )}
                                </p>
                              </div>
                            </div>
                            <div
                              className="col-lg-8  col-md-8"
                              onClick={() => {
                                setModalData(item);
                              }}
                            >
                              <div className="history-detail">
                                <p
                                  data-bs-toggle="modal"
                                  // data-bs-target="#historyModal"
                                >
                                  <span className="fs-12 fw-normal">
                                    {item &&
                                      item?.accountId &&
                                      item?.accountId?.firstName &&
                                      `${item.accountId.firstName || " "} ${
                                        item.accountId.lastName
                                      }`}
                                  </span>{" "}
                                  {item.eventDescription}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2  col-md-2">
                              <div className="history-date">
                                <p>
                                  {handleGetTime(
                                    item.timestamp,
                                    companyData.timezone
                                  )}
                                </p>
                                {/* <p>{formatAMPM(item.timestamp)}</p> */}
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .reverse()}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}

      {/* history modal */}
      <div
        className="modal fade history-modal"
        id="historyModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalData.eventDescription}{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <div className="form-group">
                  <label>Time</label>
                  <p>
                    {formatMonthNameAndDate(
                      modalData.timestamp,
                      companyData.timezone,
                      true
                    )}
                  </p>
                </div>

                <div className="form-group">
                  <label>User</label>
                  <p>
                    <a
                      onClick={() => {
                        navigate(`/view-client/${modalData.accountId._id}`);
                      }}
                      href="#"
                    >
                      {modalData?.accountId?.firstName +
                        " " +
                        modalData?.accountId?.firstName}
                    </a>
                  </p>
                </div>

                <div className="form-group">
                  <label>
                    {modalData.eventDescription == "Order Created"
                      ? "Order"
                      : modalData.eventDescription?.split(" ")[0] == "Invoice"
                      ? "Invoice"
                      : modalData.eventDescription?.split(" ")[0] ==
                        "Subscription"
                      ? "Subscription"
                      : ""}
                  </label>
                  <p>
                    <a
                      style={{ color: "#0d6efd" }}
                      //   onClick={() => {
                      //     let redirect = "";
                      //     // {modalData.eventDescription == "Order Created"
                      //     // ? "Order"
                      //     // : modalData.eventDescription?.split(" ")[0] == "Invoice"
                      //     // ? `${modalData.resourceId}`
                      //     // : modalData.eventDescription?.split(" ")[0] ==
                      //     //   "Subscription"
                      //     // ? "Subscription"
                      //     // : ""}
                      //     if (
                      //       modalData.eventDescription?.split(" ")[0] == "Invoice"
                      //     ) {
                      //       redirect = `/view-invoice/${modalData.resourceId}`;
                      //     }
                      //     navigate(redirect);
                      //   }}
                    >
                      {modalData.eventDescription == "Order Created"
                        ? "Order"
                        : modalData.eventDescription?.split(" ")[0] == "Invoice"
                        ? `${modalData.resourceId}`
                        : modalData.eventDescription?.split(" ")[0] ==
                          "Subscription"
                        ? "Subscription"
                        : ""}
                    </a>
                  </p>
                </div>

                <div className="form-group">
                  <label>Data</label>
                  <p>
                    {formatMonthNameAndDate(
                      modalData.timestamp,
                      companyData.timezone,
                      true
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* history modal */}

      {/* payment link modal */}
      <div
        className="modal fade history-modal"
        id="paymentLinkModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Payment link for F9D8B48E
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  A new invoice for this subscription will be generated before
                  the next billing period. The client will receive an invoice
                  link in their email, or you can send them to the unpaid
                  invoice directly.
                </p>
              </div>
            </div>
            {/* <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Assign</button>
                    </div> */}
          </div>
        </div>
      </div>
      {/* payment link modal */}

      {/* cancel subscription modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Cancel subscription #{subscriptionDetail.subscriptionId}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={isSubscriptionCancelled}
                        onChange={(e) => {
                          setIsSubscriptionCancelled(e.target.checked);
                        }}
                      />
                      <label
                        className="form-check-label checkbox-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Cancel Now <br />
                        <small>
                          Subscription orders will get a "Recurring Stopped" tag
                          and subscription status will switch to Canceled.
                        </small>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setIsSubscriptionCancelled(false)}
              >
                Don't Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss={`${isSubscriptionCancelled && "modal"}`}
                onClick={cancelSubscriptionHandler}
              >
                Cancel Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* cancel subscription modal */}
    </div>
  );
};
export default ViewSubscription;