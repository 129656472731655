import React, { useContext, useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import noData from "../../../assets/img/nodata-img.svg";
import { AllContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DummyAvatar from "../../../assets/img/avatar.png";
import ReactTooltip from "react-tooltip";
import PageLoader from "../../../components/pageLoader";
import {convertToUpperCase} from "../../../shared/functions";
import LoadingOverlay from 'react-loading-overlay';
import PaginationNewUI from "../../PaginationNewUI";
/*import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import * as _ from 'lodash';*/
LoadingOverlay.propTypes = undefined;

const TableTickets = ({searchText, isLoadingSearch, setIsLoadingSearch}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /*const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [tag, setTag] = useState("");
  const [allClient, setAllClient] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [tags, setTags] = useState([]);
  const [allTeam, setAllTeam] = useState([]);
  const [isActive, setActive] = useState(false);
  const [isAssignedActive, setAssignedActive] = useState(false);*/

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const [activeTab, setActiveTab] = useState("open");
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [countStatus, setCountStatus] = useState({});
  const [ticketsList, setTicketsList] = useState([]); // currentPage
  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (isLoadingSearch) {
      filterTickets();
    }
  }, [isLoadingSearch])
  useEffect(() => {
    filterTickets();
  }, [activeTab, itemOffset, itemsPerPage]);

  const context = useContext(AllContext);
  const { allApiUrl, apiPostMethod, apiPutMethod, redirectToLogin, toastMessage } = context;

  const viewTickets = (_id, data) => {
    document.getElementById('searchModalCloseId').click();
    navigate("/view-ticket/" + _id, { state: { data: data } });
  };
  const filterTickets = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    // let data = getFilterData();
    let data = {};

    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.status = activeTab;
    if (searchText) { data.searchText = searchText; }

    apiPostMethod(allApiUrl.FILTER_TICKETS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setTicketsList([...res.data.filteredTickets]);
          setCountStatus({...res.data.countStatus});
          setTabs(res.data.statusListNames);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        // redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {setIsLoadingSearch(false)}
      });
  };

  /*useEffect(() => {
    if (!filterCount && !initialLoading) {
      filterTickets();
    }
  }, [filterCount]);
  const getFilterData = () => {
    let count = 0;
    const data = {};

    if (tag !== "") data.tags = tag;
    if (assignedList.length > 0) {
      let userlist = [...assignedList];
      userlist.map((list, index) => {
        userlist[index] = list.value;
      });
      data.assignedUsers = userlist;
    }

    if (clientList.length > 0) {
      let clientSelectedList = [...clientList];
      clientSelectedList.map((list, index) => {
        clientSelectedList[index] = list.value;
      });
      data.client = clientSelectedList;
    }


    for (let key in data) {
      count += 1;
    }
    if (count !== filterCount) {
      setFilterCount(count);
    }
    return data;
  }
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const ToggleClass = () => {
    setActive(!isActive);
  };
  const ToggleAssignedClass = () => {
    setAssignedActive(!isAssignedActive);
  };
  useEffect(() => {
    setBulkDeleteId([]);
  }, [activeTab]);
  useEffect(() => {
    getTag();
    getAllTeam();
  }, []);
  const getTag = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.TAGS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTags(res.data.tags);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  };
  function getAllTeam() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllTeam(res.data.team);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  function deleteMultiple() {
    bulkDeleteId.map((row) => {
      deleteTicket(row);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
    filterTickets();
  }
  function checkedAll() {
    if (ticketsList.length === bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = ticketsList.map((e) => {
        return e._id;
      });
      setBulkDeleteId([...ids]);
    }
  }
  const onChangeCheckbox = (id, val) => {
    if (val) {
      bulkDeleteId.push(id);
      setBulkDeleteId([..._.uniq(bulkDeleteId)]);
    } else {
      _.remove(bulkDeleteId, (o) => o === id);
      setBulkDeleteId([...bulkDeleteId]);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  const deleteTicket = (id) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.DELETE_TICKET, id, header)
      .then((res) => {
        if (res.status === 200) {
          filterTickets();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  function updateMultiple(status) {
    let data = {
      status: status,
    };
    bulkDeleteId.map((row) => {
      updateTicket(row, data);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }
  function updateTicket(id, data) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(allApiUrl.EDIT_TICKET + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        filterTickets();
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  function updateAssignedUser(Details) {
    if (assignedList.find((row) => row.value === Details._id)) {
      let index = assignedList.findIndex((row) => row.value === Details._id);
      assignedList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      assignedList.push(data);
    }
    setAssignedList(assignedList);
  }
  function updateClient(Details) {
    if (clientList.find((row) => row.value === Details._id)) {
      let index = clientList.findIndex((row) => row.value === Details._id);
      clientList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      clientList.push(data);
    }
    setClientList(clientList);
  }
  function listSelectedClient() {
    let selectedList = "";
    clientList.map((list, index) => {
      selectedList +=
        list.label + `${index !== clientList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function listSelectedAssignedUser() {
    let selectedList = "";
    assignedList.map((list, index) => {
      selectedList +=
        list.label + `${index !== assignedList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }*/

  const TabsList = () => {
    const tempArray = [];
    tabs.map((item, idx) => {
      tempArray.push(
        <li key={idx} className="nav-item" role="presentation">
          <button
            className={activeTab.toLowerCase() === item ? 'nav-link active' : 'nav-link'}
            id="pills-overdue-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-overdue"
            type="button"
            role="tab"
            aria-controls="pills-overdue"
            aria-selected="true"
            onClick={() => {
              setActiveTab(item);
            }}
          >
            <span>{countStatus[item]}{" "}</span>{convertToUpperCase(item)}
          </button>
        </li>
      )
    });
    return tempArray;
  }

  return (
    <div>
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section horizontalPadding">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Tickets</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-filter">
                <PaginationNewUI
                  itemsPerPage={itemsPerPage}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  pageCount={pageCount}
                  totalRecordsCount={totalRecordsCount}
                  setItemsPerPage={setItemsPerPage}
                  mainClassName="col-lg-12"
                />
              </div>
            </div>
          </div>
          <LoadingOverlay
            styles={{
              spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                  stroke: "#007D87",
              },
              }),
              overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 0.5)",
              }),
          }}
          active={isLoading}
          spinner
          text="Loading content..."
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <TabsList />
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabIndex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                          <tr>
                            {/*<th className="ps-4 w65-px">
                              <div className="form-check">
                                <input
                                  className="selectAll form-check-input"
                                  type="checkbox"
                                  id="selectAll"
                                  checked={ticketsList.length === bulkDeleteId.length}
                                  onChange={() => checkedAll()}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="selectAll"
                                ></label>
                              </div>
                            </th>*/}
                            <th>Client</th>
                            <th>Assigned User</th>
                            <th>Status</th>
                            <th>Setup(Waiting since)</th>
                          </tr>
                          </thead>
                          <tbody>
                          {ticketsList?.map((value, idx) => {
                            return (
                              <tr key={idx}>
                                {/*<td className="ps-4 w65-px">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="item2"
                                      checked={!!(_.includes(bulkDeleteId, value._id))}
                                      onChange={(e) => {
                                        setIsSingleChecked(e.target.checked);
                                        onChangeCheckbox(
                                          value._id,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="item2"
                                    ></label>
                                  </div>
                                </td>*/}
                                <td
                                  onClick={() =>
                                    viewTickets(value._id, value)
                                  }
                                >
                                  <div className="ticketDetail">
                                    <p>
                                      <a className="ticketClientEmail">
                                        {value.to}
                                      </a>
                                      <span className="pill pill-grey ms-2">
                                          {value.message.length}
                                        </span>
                                      {value.tags.map((tag, idx) => (
                                        <span
                                          key={idx}
                                          className="pill pill-blue mx-1" //   className="serviceDescription"
                                          dangerouslySetInnerHTML={{
                                            __html: tag,
                                          }}
                                        ></span>
                                      ))}
                                    </p>

                                    <p>
                                      <span>{value.subject}</span>
                                    </p>
                                  </div>
                                </td>
                                <td
                                  onClick={() =>
                                    viewTickets(value._id, value)
                                  }
                                >
                                  <div className="assignedImg">
                                    {value.assignedUsers.map((item, idx) => {
                                      return (
                                        <>
                                          <img
                                            key={idx}
                                            className="ms-n1"
                                            src={
                                              item.image
                                                ? item.image
                                                : DummyAvatar
                                            }
                                            data-tip={
                                              item.firstName +
                                              " " +
                                              item.lastName
                                            }
                                          />
                                          <ReactTooltip />
                                        </>  
                                      );
                                    })}
                                  </div>
                                </td>

                                <td
                                  onClick={() =>
                                    viewTickets(value._id, value)
                                  }
                                >
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {value.status}
                                    </span>
                                </td>
                                <td
                                  onClick={() =>
                                    viewTickets(value._id, value)
                                  }
                                >
                                  {moment(value.date).format("MMM DD")}
                                </td>
                              </tr>
                            );
                          })}
                          {ticketsList.length <= 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/*{(bulkDeleteId.length > 0 || isSingleChecked) && (
                    <div className="table-bottom-option">
                      <div className="row">
                        <div className="col-lg-3 col-md-4">
                          <div className="input-group">
                            <select
                              id="selectOption"
                              value={selectOption.target}
                              onChange={(e) => { selectOption(e); }}
                              className="form-control"
                            >
                              <option>With Selected...</option>
                              {tabs.map((item, idx2) => {
                                return (
                                  <option key={idx2} value={item.name} className="text-capitalize">
                                    Set as {item.name}
                                  </option>
                                );
                              })}
                              <option value="Delete">Delete</option>
                            </select>
                            <button
                              className="btn btn-apply"
                              onClick={() => {
                                if (selectedOption === "Delete") {
                                  deleteMultiple();
                                }
                                if (tabs.find(
                                  (item) => item === selectedOption
                                )) {
                                  updateMultiple(selectedOption);
                                }
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}*/}
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      )}
    </div>
  );
};
export default TableTickets;
