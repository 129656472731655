import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useSelector, useDispatch } from "react-redux";
import { AllContext } from "../../context";
import {} from "../../api/rest";
import SettingTabs from "../../components/settings/tabs";
import { Select } from "antd";
import AddWorkflowModal from "./AddWorkflowModal";
import EditDefaultWorkflowModal from "./EditDeafultWorkflowModal";
import AddOrderStatusModal from "./AddOrderStatusModal";
import EditOrderStatusModal from "./EditOrderStatusModal";
import WorkflowCrmFields from "./WorkflowCrmFields";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import openIcon from "../../assets/img/icons/openSide.svg";
const { Option } = Select;

const OrderSettings = () => {
  //Constants
  const dispatch = useDispatch();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const context = useContext(AllContext);
  const {
    toastMessage,
    apiGetMethod,
    redirectToLogin,
    apiPostMethod,
    allApiUrl,
    apiPutMethod,
    apiDeleteMethod,
  } = context;
  const navigate = useNavigate();

  //states
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState(0);
  const [defaultWorkflow, setDefaultWorkflow] = useState({});
  const [isAddWorkflowModalOpen, setIsAddWorkflowModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [deleteWorkflowId, setDeleteWorkflowId] = useState("");
  const [workflowData, setWorkflowData] = useState([]);
  const [isEditDefaultWorkflowModalOpen, setIsEditDefaultWorkflowModalOpen] =
    useState(false);
  const [isAddOrderStatusModalOpen, setIsAddOrderStatusModalOpen] =
    useState(false);
  const [isEditOrderStatusModalOpen, setIsEditOrderStatusModalOpen] =
    useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState({});
  // Functions
  useEffect(() => {
    getOrderStatus();
    getWorkFlows(true);
  }, []);

  function getOrderStatus() {
    apiGetMethod(allApiUrl.GET_ALL_ACCOUNT_STATUS, "", header)
      .then(async (res) => {
        if (res.status === 200) {
          setChecked1(res.data[0].lockOrder);
          setChecked(res.data[0].rating);
        }
      })
      .catch((err) => {
        console.log("err", err);
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }

  const getWorkFlows = (isSetDefaultWorkflow) => {
    apiGetMethod(allApiUrl.GET_WORKFLOWS, "", header)
      .then(async (res) => {
        if (res.status === 200) {
          if (isSetDefaultWorkflow) {
            processWorkFlowData(res.data);
          } else {
            setWorkflowData(res.data);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  };

  const processWorkFlowData = (workflowValues) => {
    if (workflowValues.length) {
      setWorkflowData(workflowValues);
      const getDefaultWorkflow = workflowValues.findIndex(
        (data) => data.isDefaultWorkflow
      );
      if (getDefaultWorkflow !== -1) {
        setSelectedWorkflow(getDefaultWorkflow);
        setDefaultWorkflow(workflowValues[getDefaultWorkflow]);
      } else {
        setDefaultWorkflow(workflowValues[selectedWorkflow]);
      }
    }
  };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    const data = {
      rating: nextChecked,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPutMethod(allApiUrl.EDIT_ACCOUNT_STATUS, data, header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", "Rating Enabled");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleChange1 = (nextChecked) => {
    setChecked1(nextChecked);
    const data = {
      lockOrder: nextChecked,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPutMethod(allApiUrl.EDIT_ACCOUNT_STATUS, data, header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", "Lock order status Changed");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const handleWorkflowChange = (e) => {
    setSelectedWorkflow(e);
  };

  const handleAddWorkflow = (workflowNameValue) => {
    if (workflowNameValue === "") {
      toastMessage("warn", "Workflow Name is required");
      return false;
    }
    apiPostMethod(
      allApiUrl.ADD_WORKFLOW,
      { workflowName: workflowNameValue },
      header
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", "Workflow added successfully");
          apiGetMethod(allApiUrl.GET_WORKFLOWS, "", header)
            .then(async (res) => {
              if (res.status === 200) {
                const workflowDataArr = res.data;
                if (workflowDataArr.length > 0) {
                  setWorkflowData(workflowDataArr);
                  setSelectedWorkflow(workflowDataArr.length - 1);
                }
              }
            })
            .catch((err) => {
              console.log("err", err);
              redirectToLogin(dispatch, navigate, err);
              toastMessage("error", err.data.message);
            });
          setIsAddWorkflowModalOpen(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleEditDefaultWorkflow = (workflowNameValue) => {
    if (workflowNameValue === "") {
      toastMessage("warn", "Workflow Name is required");
      return false;
    }
    apiPutMethod(
      allApiUrl.UPDATE_WORKFLOW + `/${defaultWorkflow._id}`,
      { ...defaultWorkflow, workflowName: workflowNameValue },
      header
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", "Workflow Updated Successfully");
          setIsEditDefaultWorkflowModalOpen(false);
          getWorkFlows(true);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleDeleteWorkflow = () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.DELETE_WORKFLOW, deleteWorkflowId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        setIsLoading(false);
        setDeleteWorkflowId("");
        setIsDeleteConfirmModalOpen(false);
        getWorkFlows(true);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        setIsDeleteConfirmModalOpen(false);
      });
  };

  const handleCancelDeleteWorkflowModal = () => {
    setIsDeleteConfirmModalOpen(false);
    setDeleteWorkflowId("");
  };

  const handleAddOrderStatus = (formValues) => {
    apiPutMethod(
      allApiUrl.ADD_WORKFLOW_STATUS + `/${workflowData[selectedWorkflow]._id}`,
      { ...formValues },
      header
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", "Workflow order status added successfully");
          getWorkFlows(false);
          setIsAddOrderStatusModalOpen(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleWorkflowCrmfieldsData = (data) => {
    apiPutMethod(
      allApiUrl.SAVE_WORKFLOW_CRMFIELDS +
        `/${workflowData[selectedWorkflow]._id}`,
      data,
      header
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          getWorkFlows(false);
          setIsAddOrderStatusModalOpen(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleEditOrderStatus = (formValues) => {
    const currentSelectedWorkflow = workflowData[selectedWorkflow];
    currentSelectedWorkflow.orderStatuses =
      currentSelectedWorkflow.orderStatuses.map((data) => {
        if (data._id === formValues._id) {
          return { ...data, ...formValues };
        }
        return data;
      });

    apiPutMethod(
      allApiUrl.UPDATE_WORKFLOW + `/${workflowData[selectedWorkflow]._id}`,
      { ...currentSelectedWorkflow },
      header
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", "Workflow order status added successfully");
          getWorkFlows(false);
          setIsEditOrderStatusModalOpen(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleCancelAddWorkflowModal = () => {
    setIsAddWorkflowModalOpen(false);
  };

  const handleSetAsDefaultWorkflow = (toggleChecked, workflowId) => {
    if (toggleChecked) {
      apiPutMethod(
        allApiUrl.SET_DEFAULT_WORKFLOW + `/${workflowId}`,
        {},
        header
      )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setIsLoading(false);
            toastMessage("success", "Default Workflow set successfully");
            getWorkFlows(true);
          }
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  };

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">Orders Settings</h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SettingTabs currentTab="orders" />

      <div className="commonSlidePl">
        <div className="setting-form-section mt-4 mb-4">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-xxl-10 col-lg-10 mx-auto">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {/* Order settings */}
                    <div className="setting-form">
                      <form className="commonForm rounded-1">
                        <div className="d-flex align-items-center">
                          <h3 className="me-3">Lock Completed Orders</h3>
                          <Switch
                            onChange={handleChange1}
                            checked={checked1}
                            className="react-switch"
                            onHandleColor="#007D87"
                            handleDiameter={28}
                            onColor="#007D87"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={18}
                            width={45}
                          />
                        </div>
                        <p>
                          (If toggle on, then it will lock the completed orders
                          and will not allow clients to send a message or do any
                          action on the same order)
                        </p>

                        <div className="d-flex align-items-center">
                          <h3 className="me-3">Enable Rating</h3>
                          <Switch
                            onChange={handleChange}
                            checked={checked}
                            className="react-switch"
                            onHandleColor="#007D87"
                            handleDiameter={28}
                            onColor="#007D87"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={18}
                            width={45}
                          />
                        </div>
                        <p>
                          (If toggle on, then it will show the rating option to
                          client once Order status is completed)
                        </p>

                        {/* Workflow settings */}
                        <div className="row">
                          <div className="col-lg-7">
                            <h3 className="mb-3">Select WorkFlow</h3>
                            <div className="d-flex align-items-center">
                              <div className="w-100">
                                <Select
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Search to Worflow"
                                  onChange={(e) => handleWorkflowChange(e)}
                                  value={selectedWorkflow}
                                  required
                                >
                                  {workflowData.map((item, idx) => {
                                    return (
                                      <Option key={idx} value={idx}>
                                        {item.workflowName}
                                      </Option>
                                    );
                                  })}
                                  <Option value={"Create a new Workflow"}>
                                    <button
                                      className="btn text-primary"
                                      onClick={(e) => {
                                        setIsAddWorkflowModalOpen(true);
                                        e.stopPropagation();
                                      }}
                                    >
                                      Create a new Workflow +
                                    </button>
                                  </Option>
                                  <Option value={"Rename Default Workflow"}>
                                    <button
                                      className="btn text-primary"
                                      onClick={(e) => {
                                        setIsEditDefaultWorkflowModalOpen(true);
                                        e.stopPropagation();
                                      }}
                                    >
                                      Rename Default Workflow
                                    </button>
                                  </Option>
                                </Select>
                              </div>
                              <div className="w-100 ms-2">
                                <button
                                  className="btn btn-link"
                                  disabled={
                                    workflowData[selectedWorkflow]
                                      ?.isDefaultWorkflow
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsDeleteConfirmModalOpen(true);
                                    setDeleteWorkflowId(
                                      workflowData[selectedWorkflow]?._id
                                    );
                                  }}
                                >
                                  Delete Selected Workflow{" "}
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </div>

                            <div className="my-4">
                              <h5>Order Statuses</h5>
                              <div className="px-3 py-2">
                                {workflowData.length > 0 &&
                                  workflowData[
                                    selectedWorkflow
                                  ].orderStatuses.map((statuses, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="py-2 d-flex align-items-center justify-content-between"
                                      >
                                        <div className="order-status">
                                          <span
                                            className="badge"
                                            style={{
                                              backgroundColor: statuses.bgColor,
                                              color: statuses.textColor,
                                            }}
                                          >
                                            {statuses.name}
                                          </span>
                                          <div>
                                            <small className="workflow-des">
                                              {statuses.description}
                                            </small>
                                          </div>
                                        </div>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-secondary dropdown-toggle btn-light"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                          </button>
                                          <ul className="dropdown-menu">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => {
                                                  setIsEditOrderStatusModalOpen(
                                                    true
                                                  );
                                                  setSelectedOrderStatus(
                                                    statuses
                                                  );
                                                }}
                                              >
                                                Edit
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>

                              {workflowData.length > 0 &&
                                !workflowData[selectedWorkflow]
                                  .isDefaultWorkflow && (
                                  <div className="d-flex align-items-center px-3 mb-4">
                                    <h6 className="me-3 mb-0">
                                      Select as default Workflow
                                    </h6>
                                    <Switch
                                      checked={
                                        workflowData.length > 0 &&
                                        workflowData[selectedWorkflow]
                                          .isDefaultWorkflow
                                      }
                                      className="react-switch"
                                      onHandleColor="#007D87"
                                      handleDiameter={20}
                                      onColor="#007D87"
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={12}
                                      width={32}
                                      onChange={(toggleChecked) =>
                                        handleSetAsDefaultWorkflow(
                                          toggleChecked,
                                          workflowData[selectedWorkflow]._id
                                        )
                                      }
                                    />
                                  </div>
                                )}

                              <div className="add-statuses mb-2">
                                <button
                                  className="btn text-primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsAddOrderStatusModalOpen(true);
                                  }}
                                >
                                  + Add Status
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      {/* Workflow crmFields */}
                      {workflowData.length > 0 ? (
                        <WorkflowCrmFields
                          crmFieldsData={
                            workflowData[selectedWorkflow].workFlowCrmFields
                          }
                          handleWorkflowCrmfieldsData={(crmfields) =>
                            handleWorkflowCrmfieldsData(crmfields)
                          }
                        />
                      ) : (
                        ""
                      )}

                      {isAddWorkflowModalOpen ? (
                        <AddWorkflowModal
                          isAddWorkflowModalOpen={isAddWorkflowModalOpen}
                          handleAddWorkflow={handleAddWorkflow}
                          handleCancelModal={handleCancelAddWorkflowModal}
                        />
                      ) : (
                        ""
                      )}

                      {isDeleteConfirmModalOpen ? (
                        <DeleteConfirmModal
                          isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
                          handleDelete={handleDeleteWorkflow}
                          handleCancelModal={handleCancelDeleteWorkflowModal}
                          modalContent={
                            "Are you sure, you want to delete this workflow"
                          }
                        />
                      ) : (
                        ""
                      )}

                      {isEditDefaultWorkflowModalOpen ? (
                        <EditDefaultWorkflowModal
                          isEditDefaultWorkflowModalOpen={
                            isEditDefaultWorkflowModalOpen
                          }
                          handleEditDefaultWorkflow={handleEditDefaultWorkflow}
                          handleCancelModal={() =>
                            setIsEditDefaultWorkflowModalOpen(false)
                          }
                          defaultWorkflowName={defaultWorkflow.workflowName}
                        />
                      ) : (
                        ""
                      )}

                      {isAddOrderStatusModalOpen ? (
                        <AddOrderStatusModal
                          isAddOrderStatusModalOpen={isAddOrderStatusModalOpen}
                          handleAddOrderStatus={handleAddOrderStatus}
                          handleCancelModal={() =>
                            setIsAddOrderStatusModalOpen(false)
                          }
                        />
                      ) : (
                        ""
                      )}

                      {isEditOrderStatusModalOpen ? (
                        <EditOrderStatusModal
                          isEditOrderStatusModalOpen={
                            isEditOrderStatusModalOpen
                          }
                          handleEditOrderStatus={handleEditOrderStatus}
                          selectedStatusData={selectedOrderStatus}
                          handleCancelModal={() =>
                            setIsEditOrderStatusModalOpen(false)
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderSettings;
