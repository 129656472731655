import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { formatMonthNameAndDate } from "../../shared/functions";
import PageLoader from "../../components/pageLoader";
import openIcon from "../../assets/img/icons/openSide.svg";
import sortIcon from "../../assets/img/icons/sortIcon.svg";
import LoadingOverlay from "react-loading-overlay";
import { SpinnerCircularFixed } from "spinners-react";
import PaginationNewUI from "../../components/PaginationNewUI";
import SelectAsync from "../../components/SelectAsync";
LoadingOverlay.propTypes = undefined;

const Files = () => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [users, setUsers] = useState([]);
  const [filterCount, setFilterCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(1); // currentPage
  const [itemsPerPage, setItemsPerPage] = useState(10); // limit
  const [pageCount, setPageCount] = useState(0); // totalPages
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [initialLoading, setInitialLoading] = useState(true);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { allApiUrl, redirectToLogin, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const [orderList, setOrderList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({} | null);
  const [selectedTicket, setSelectedTicket] = useState({} | null);
  const [selectedClient, setSelectedClient] = useState({} | null);
  const [selectedDatePicker, setSelectedDatePicker] = useState({} | null);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  useEffect(() => {
    filterFiles();
  }, [itemOffset, itemsPerPage]);
  useEffect(() => {
    if (!filterCount && !initialLoading) {
      filterFiles();
    }
  }, [filterCount]);
  const businessId = useSelector(
    (state) => state.loginReducer.loginUserInfo.businessId
  );

  const filterFiles = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    const data = {};
    if (selectedClient && selectedClient.value) {
      data.userId = selectedClient.value;
    }
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;
    if (
      (selectedOrder && selectedOrder.value) ||
      (selectedTicket && selectedTicket.value)
    ) {
      let resourceId = [];
      if (selectedOrder && selectedOrder.value)
        resourceId.push(selectedOrder.value);
      if (selectedTicket && selectedTicket.value)
        resourceId.push(selectedTicket.value);
      data.resourceId = resourceId;
    }
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let count = 0;
    for (let key in data) {
      if (key === "resourceId" && data[key].length > 1) {
        count += 1;
      }
      count += 1;
    }
    setFilterCount(count);
    console.log(" businessId",businessId)

    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.businessId = businessId;
    console.log("data 123",data)
    apiPostMethod(allApiUrl.FILTER_FILES, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.countAll);
          setFiles([...res.data.filteredFiles]);
          setInitialLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
        setInitialLoading(false);
        setIsLoading(false);
      });
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  async function getOrderListing() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.ORDER, "", header);
      if (response.status === 200) {
        setOrderList([...response.data.order]);
      }
      setIsLoadingFilters(false);
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data.message);
      setIsLoadingFilters(false);
    }
  }

  const getTickets = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_TICKET, "", header).then((res) => {
      if (res.status === 200) {
        setTicketList(res.data);
      }
    });
  };

  useEffect(() => {
    getTickets();
    getOrderListing();
  }, []);

  return (
    <div>
      <Sidebar
        activePage="files"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} propSetAllClientList={setUsers} />
      <PageLoader isLoading={initialLoading} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Files <span className="userCounter">&nbsp;</span>
                  </h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
              <PaginationNewUI
                itemsPerPage={itemsPerPage}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                pageCount={pageCount}
                totalRecordsCount={totalRecordsCount}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </div>
        {!initialLoading && (
          <div className="orders-section commonPadding horizontalPadding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="topFilters flex-wrap">
                    <p>Filter By:</p>
                    <SelectAsync
                      placeholder="Any Order"
                      searchType="orders"
                      selectedValue={selectedOrder}
                      onChange={(e) => {
                        setSelectedOrder({
                          label: e?.label?.props?.children[0],
                          value: e.value,
                        });
                      }}
                    />
                    <SelectAsync
                      placeholder="Any Ticket"
                      searchType="tickets"
                      selectedValue={selectedTicket}
                      onChange={(e) => {
                        setSelectedTicket({
                          label: e?.label?.props?.children[0],
                          value: e.value,
                        });
                      }}
                    />
                    <SelectAsync
                      placeholder="Any Client"
                      searchType="clients"
                      selectedValue={selectedClient}
                      onChange={(e) => {
                        setSelectedClient({
                          label: e?.label?.props?.children[0],
                          value: e.value,
                        });
                      }}
                    />
                    <SelectAsync
                      placeholder="Any Date"
                      searchType="date"
                      selectedValue={selectedDatePicker}
                      onChange={(e) => {
                        setSelectedDatePicker({
                          label: e?.label,
                          value: e.value,
                        });
                      }}
                    />
                    {!!(selectedDatePicker && selectedDatePicker?.value) && (
                      <div className="d-flex dateBetween-filter align-items-center">
                        <input
                          type="date"
                          name="startDate"
                          className="form-control date-input"
                          onChange={(event) =>
                            setstartDateFilter(
                              new Date(event.target.value).getTime()
                            )
                          }
                          id=""
                        />
                        <span className="mx-2">to</span>
                        <input
                          className="form-control date-input"
                          type="date"
                          name="endDate"
                          onChange={(event) =>
                            setendDateFilter(
                              new Date(event.target.value).getTime()
                            )
                          }
                          id=""
                        />
                      </div>
                    )}
                    {isLoadingFilters && (
                      <button className="btn">
                        <SpinnerCircularFixed
                          size="25"
                          secondaryColor=""
                          color="#201b50"
                          thickness="200"
                        />
                      </button>
                    )}
                    <button
                      className="btn btn-clear"
                      onClick={() => {
                        setstartDateFilter(0);
                        setendDateFilter(0);
                        setSelectedOrder(null);
                        setSelectedTicket(null);
                        setSelectedClient(null);
                        setSelectedDatePicker(null);
                        setFilterCount(0);
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-update"
                      onClick={() => filterFiles()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
                {/* <div className="col-lg-12 col-6 mt-2">
                  <div className="section-filter">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle btn-filter"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={getFilters}
                      >
                        <i className="fa-solid fa-filter"></i> Filter |{" "}
                        {filterCount}
                      </button>
                      <ul className="dropdown-menu filter-drowdown">
                        <div className="filter-btns">

                        </div>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                      stroke: "#007D87",
                    },
                  }),
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
                active={isLoading}
                spinner
                text="Loading content..."
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive common-table mt-3 files-table rounded-1">
                      <table className="table logs-table mb-0">
                        <thead>
                          <tr>
                            <th>
                              Name{" "}
                              <span>
                                <img src={sortIcon} />
                              </span>
                            </th>
                            <th>
                              Size{" "}
                              <span>
                                <img src={sortIcon} />
                              </span>
                            </th>
                            <th>
                              Created Date{" "}
                              <span>
                                <img src={sortIcon} />
                              </span>
                            </th>
                            <th>
                              Resource Type{" "}
                              <span>
                                <img src={sortIcon} />
                              </span>
                            </th>
                            <th>
                              Owner{" "}
                              <span>
                                <img src={sortIcon} />
                              </span>
                            </th>
                            <th>
                              Resource ID{" "}
                              <span>
                                <img src={sortIcon} />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {files &&
                            files.length > 0 &&
                            files.map((value, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>
                                    <a href={value?.url} target="_blank">
                                      {value?.fileName}
                                    </a>
                                  </td>
                                  <td>{formatBytes(value?.fileSize)}</td>
                                  <td>
                                    {formatMonthNameAndDate(
                                      value?.createdOn,
                                      companyData.timezone
                                    )}
                                  </td>
                                  <td>{value?.eventType}</td>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        navigate(
                                          `/${
                                            value.userId.group === 1
                                              ? "accounts/team"
                                              : "view-client"
                                          }/${value.userId._id}`
                                        )
                                      }
                                    >
                                      {value &&
                                      value.userId &&
                                      value.userId.firstName &&
                                      value.userId.lastName
                                        ? value.userId.firstName +
                                          " " +
                                          value.userId.lastName
                                        : value.userId.email}
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        navigate(
                                          `/${
                                            value?.eventType === "Order"
                                              ? "view-orders"
                                              : "view-ticket"
                                          }/${value.resourceId}`
                                        )
                                      }
                                    >
                                      {value?.resourceId}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          {files && files.length <= 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        )}

        {/* add order modal stasts */}
        <div
          className="modal fade add-order-modals"
          id="addorderModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add order
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="add-order-form">
                  <div className="add-alert">
                    <p>
                      Adding an order manually will not trigger any
                      notifications or payments. To add a paid order you can
                      create a new invoice and mark it as paid.
                    </p>
                  </div>
                  <form>
                    <div className="form-group">
                      <label>Client</label>
                      <select className="form-control">
                        <option>Client Name</option>
                        <option>Client Name</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Service</label>
                      <select className="form-control">
                        <option>Service 1</option>
                        <option>Service 2</option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary close-btn"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Add order
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* add order modal ends */}
      </div>
    </div>
  );
};
export default Files;
