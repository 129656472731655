import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Select from "react-select";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import PageLoader from "../../components/pageLoader";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Row } from "react-bootstrap";
const AddInvoice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const [serviceListArr, setServiceListArr] = useState([]);
  const [serviceListArrBkup, setServiceListArrBkup] = useState([]);
  const [multiAddInvoiceList, setMultiAddInvoiceList] = useState([
    {
      service: [],
      quantity: 1,
      id: {
        originalPrice: 0.0,
        discount: 0.0,
        name: "",
        description: "",
        price: 0,
      },
    },
  ]);
  const [isRecurringServiceExist, setRecurringServiceExist] = useState(false);
  const [multiAddInvoiceCount, setMultiAddInvoiceCount] = useState(1);
  const increaseAddInvoiceCount = (event, index) => {
    event.preventDefault();
    setMultiAddInvoiceCount(multiAddInvoiceCount + 1);
    setMultiAddInvoiceList((prevData) => {
      prevData[index + 1] = {
        service: [],
        quantity: 1,
        id: {
          _id: "",
          name: "",
          price: "",
          originalPrice: "",
          discount: 0,
          isRecurring: "",
          description: "",
        },
      };
      return [...prevData];
    });
  };

  useEffect(() => {
    serviceListArr.map((row, index) => {
      serviceListArr[index].isDisabled = false;
    });
    if (multiAddInvoiceList.length > 0) {
      let discountList = [...multiAddInvoiceList];

      discountList.map((row) => {
        if (row && row.service) {
          let data = serviceListArr.findIndex((data) => data == row.service);
          if (data > -1) serviceListArr[data].isDisabled = true;
        }
      });
      setServiceListArr(serviceListArr);
    }
    const recurringService = Object.values(multiAddInvoiceList).some(
      (item) => item.id.isRecurring === true
    );
    setRecurringServiceExist(recurringService);
  }, [multiAddInvoiceList]);

  const decreaseAddInvoiceCount = (event, index) => {
    event.preventDefault();
    if (multiAddInvoiceCount > 1 && multiAddInvoiceList.length > 0) {
      let multiAddInvoiceListCopy = [...multiAddInvoiceList];
      multiAddInvoiceListCopy.splice(index, 1);
      setMultiAddInvoiceList(multiAddInvoiceListCopy);
      setMultiAddInvoiceCount(multiAddInvoiceCount - 1);
    }
  };

  const {
    allApiUrl,
    toastMessage,
    redirectToLogin,
    apiGetMethod,
    apiPostMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { state } = useLocation();
  const [invoiceCount, setInvoiceCount] = useState(1);
  const [invoiceList, setInvoiceList] = useState([
    {
      id: {
        originalPrice: 0.0,
        discount: 0.0,
        price: 0,
        description: "",
      },
      quantity: 1,
    },
  ]);
  const [allClientList, setAllClientList] = useState([]);
  const [show, setShow] = useState(false);

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  useEffect(() => {
    getClientList();
    if (state?.page == "subscription") {
      setShow(true);
    }
  }, []);

  // const increaseItems = (event) => {
  //   event.preventDefault();
  //   if (
  //     invoiceList &&
  //     invoiceList[0] &&
  //     invoiceList[0].id &&
  //     invoiceList[0].id._id
  //   ) {
  //     setInvoiceCount(invoiceCount + 1);
  //     let add = [
  //       ...invoiceList,
  //       {
  //         id: {
  //           originalPrice: 0.0,
  //           discount: 0.0,
  //           price: 0.0,
  //         },
  //         quantity: 1,
  //       },
  //     ];
  //     setInvoiceList(add);
  //   } else {
  //     toastMessage("error", "Select any service");
  //   }
  // };

  // const decreaseInvoiceCount = (event, index) => {
  //   event.preventDefault();
  //   if (invoiceCount > 1) {
  //     setInvoiceCount(invoiceCount - 1);
  //     let update = [];
  //     update = invoiceList.filter((item, indexItem) => indexItem != index);
  //     setInvoiceList(update);
  //     // onClear();
  //   }
  // };

  function getClientList() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_ALL_CLIENT, "", header)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.team) {
            setAllClientList(() => {
              res.data.team[0] = {
                id: "--",
                userId: "Any client",
              };

              return [...res.data.team];
            });
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    getAllServicesList();
    getAllClientList();
  }, []);

  async function getAllServicesList() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.SERVICES, "", header);
      if (response.status === 200) {
        let options = [];
        response.data.data.map((item) => {
          options.push({
            value: item._id,
            price: item.price,
            name: item.name,
            recurringOrder: item.recurringOrder,
            label: (
              <>
                {item.name || " "} {"\n"}{" "}
                <small>
                  {" "}
                  {item.oneTimeService == true
                    ? item.price.toFixed(2)
                    : item.price.toFixed(2) +
                      "/" +
                      item.recurringOrder.durationTime +
                      " " +
                      item.recurringOrder.durationPeriod}
                </small>{" "}
              </>
            ),
          });
        });
        options.unshift({
          value: "custom",
          label: "custom",
          name: "custom",
          _id: "custom",
        });
        setServiceListArrBkup(options);
        setServiceListArr(options);
        setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  const [duedate, setDueDate] = useState(0);
  const [noteToClient, setNotetoClient] = useState("");
  const [recurringInvoice, setRecurringInvoice] = useState(false);
  const [recurringInvoiceValue, setRecurringInvoiceValue] = useState(1);
  const [recurringInvoiceType, setRecurringInvoiceType] = useState("months");
  const [sendEmail, setSendEmail] = useState(false);
  const [partialUpfront, setPartialUpfront] = useState(false);
  const [partialUpfrontValue, setPartialUpfrontValue] = useState(0);
  const [clientListArr, setClientListArr] = useState([]);
  const [clientListArrBkup, setClientListArrBkup] = useState([]);
  const [addClientList, setAddClientList] = useState({
    client: "",
  });
  async function addInvoiceHandler(event) {
    event.preventDefault();
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let a = new Date();
    let dueDateNew = new Date(a.setDate(a.getDate() + 1));
    const data = {
      product: multiAddInvoiceList,
      note: noteToClient,
      partialUpfront: {
        status: partialUpfront,
        amount: partialUpfrontValue ? partialUpfrontValue : 0,
        // discount: multiAddInvoiceList ? multiAddInvoiceList : 0
      },
      recurringInvoice: {
        status: recurringInvoice,
        durationTime: recurringInvoiceValue || "", //value
        durationPeriod: recurringInvoiceType || "", //type (month,year)
      },

      sendEmail,
      dueDate: duedate ? duedate : dueDateNew,
      userId: addClientList.client.value,
    };
    const amount = multiAddInvoiceList.reduce((acc, cv) => {
      return acc + cv.id.price;
    }, 0);
    //recurring service exist
    const recurringServiceExist = Object.values(data.product).some(
      (item) => item.id.isRecurring === true
    );
    if (recurringServiceExist == true && partialUpfront == true) {
      toastMessage(
        "error",
        "Partial payments cannot be used with recurring services"
      );
    }
    // else if(discount > multiAddInvoiceList) {
    //   toastMessage(
    //     "error",
    //     "discount payment cannot be greater than actual payment"
    //   );
    // }
    else if (amount < partialUpfrontValue) {
      if (partialUpfront == true)
        toastMessage(
          "error",
          "Partial payment cannot be greater than actual payment"
        );
      else
        toastMessage("error", "Discount cannot be greater than actual payment");
    } else {
      if (data?.product[0]?.id?._id) {
        setIsLoading(true);
        try {
          const response = await apiPostMethod(allApiUrl.INVOICE, data, header);
          if (response.status === 200) {
            setIsLoading(false);
            navigate("/invoices");
            toastMessage("success", response.data.message);
          }
        } catch (err) {
          console.log("err", err);
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        }
      } else {
        toastMessage("error", "Select any service");
      }
    }
  }

  async function getAllClientList() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.GET_ALL_CLIENT, "", header);
      if (response.status === 200) {
        let options = [];
        response.data.team.map((item) => {
          let label = `${item.userId.firstName || " "} ${
            item.userId.lastName || ""
          } 
${item.userId.email}`;

          options.push({
            value: item.userId._id,
            label: (
              <>
                {item.userId.firstName || " "} {item.userId.lastName || ""}{" "}
                {"\n"} <small>{item.userId.email}</small>{" "}
              </>
            ),
            firstName: item.userId.firstName,
            lastName: item.userId.lastName || " ",
            email: item.userId.email,
          });
          if (
            state &&
            state.selectedUser &&
            state.selectedUser.length > 0 &&
            item.userId._id == state.selectedUser
          ) {
            setAddClientList((PrevData) => {
              return {
                ...PrevData,
                client: {
                  value: item.userId._id,
                  label: (
                    <>
                      {item.userId.firstName || " "}{" "}
                      {item.userId.lastName || ""} {"\n"}{" "}
                      <small>{item.userId.email}</small>{" "}
                    </>
                  ),
                  firstName: item.userId.firstName,
                  lastName: item.userId.lastName || " ",
                  email: item.userId.email,
                },
              };
            });
          }
        });
        // options.unshift({ value: "custom", label: "custom" });
        setClientListArr(options);
        setClientListArrBkup(options);
        setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  const today = new Date();
  const numberOfDaysToAdd = 1;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "grey" : "#333",
    }),
    control: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  function checkStatusOfDiscount() {
    let status = false;
    [...Array(multiAddInvoiceCount)].filter((value, index) => {
      if (
        multiAddInvoiceList[index]?.id?.originalPrice <
        multiAddInvoiceList[index]?.id?.discount
      ) {
        status = true;
      } else if (
        multiAddInvoiceList[index]?.id?.originalPrice < partialUpfrontValue
      ) {
        status = true;
      }
    });
    return status;
  }
  return (
    <div>
      <Sidebar
        activePage="invoices"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={isLoading} />
      <div className="commonSlidePl">
      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-back">
                <a href="#" onClick={() => navigate("/invoices")}>
                  <i className="fa-solid fa-angle-left"></i> Invoices
                </a>
              </div>
            </div>

            <div className="col-lg-12 col-xxl-9 mx-auto">
              <div className="setting-form invoiceForm p55-large">
                <h3 className="fs-4 mb-4 fs-4">Add Invoice</h3>
                <form>
                  <div className="commonForm rounded-1">
                    <div className="row mb-5">
                      <div className="col-lg-8">
                        <div className="form-group">
                          <label>
                            <small style={{ marginLeft: "2px" }}>
                              Optional
                            </small>
                          </label>
                          <Select
                            className="my-react-select my-react-select--compact"
                            classNamePrefix="lp-copy-sel"
                            isSearchable
                            styles={customStyles}
                            value={addClientList.client}
                            options={clientListArr}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "0.375rem",
                              colors: {
                                ...theme.colors,
                                // text: 'orangered',
                                // primary25: 'hotpink',
                                primary: "#ddd",
                                // primary75: 'hotpink',
                                // primary50: 'hotpink',
                              },
                            })}
                            onInputChange={(e) => {
                              if (e.length > 0) {
                                let list = [];
                                list = clientListArrBkup.filter((data) => {
                                  if (
                                    data.firstName.includes(e) ||
                                    data.lastName.includes(e) ||
                                    data.email.includes(e)
                                  ) {
                                    return data;
                                  }
                                });
                                setClientListArr(list);
                              } else {
                                setClientListArr(clientListArrBkup);
                              }
                            }}
                            filterOption={false}
                            onChange={(e) =>
                              setAddClientList((prev) => {
                                return { ...prev, client: e };
                              })
                            }
                          />
                          <small>
                            If you don't select a client here anyone can pay
                            this invoice. An account will be created for them
                            automatically.
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>Date Due</label>
                          {/* <input
                            type="date"
                            defaultValue={defaultValue}
                            className="form-control"
                            onChange={(event) =>
                              setDueDate(new Date(event.target.value).getTime())
                            }
                          /> */}
                          <ReactDatePicker
                            id="myDate"
                            className="form-control"
                            onChange={(event) => {
                              setDueDate(event.getTime());
                            }}
                            value={
                              duedate > 0
                                ? moment(duedate).format("YYYY-MM-DD")
                                : moment().format("YYYY-MM-DD")
                            }
                            selected={
                              duedate > 0 ? new Date(duedate) : new Date()
                            }
                            minDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                    {[...Array(multiAddInvoiceCount)].map((value, index) => {
                      return (
                          <div className="row" key={index}>
                            <div className="col-lg-7 col-xxl-7">
                              <div className="form-group itemGroup">
                                <label>Item</label>
                                <Select
                                  className="my-react-select my-react-select--compact"
                                  classNamePrefix="lp-copy-sel"
                                  id={"select" + index}
                                  value={multiAddInvoiceList[index]?.service}
                                  options={serviceListArr}
                                  onInputChange={(e) => {
                                    if (e.length > 0) {
                                      let list = [];
                                      list = serviceListArrBkup.filter(
                                        (data) => {
                                          if (data.name.includes(e)) {
                                            return data;
                                          }
                                        }
                                      );
                                      setServiceListArr([...list]);
                                    } else {
                                      setServiceListArr(serviceListArrBkup);
                                    }
                                  }}
                                  filterOption={false}
                                  placeholder="All Services"
                                  onChange={(e) => {
                                    if (e.value && e.value == "custom") {
                                      setMultiAddInvoiceList((prevData) => {
                                        prevData[index] = {
                                          ...prevData[index],
                                          service: e,
                                          id: {
                                            ...prevData[index]?.id,
                                            _id: e.value,
                                            name: "",
                                            price: 0,
                                            originalPrice: 0,
                                            discount: 0,
                                            isRecurring:
                                              e.recurringOrder?.status,
                                          },
                                        };
                                        return [...prevData];
                                      });
                                    } else {
                                      setMultiAddInvoiceList((prevData) => {
                                        prevData[index] = {
                                          ...prevData[index],
                                          service: e,
                                          id: {
                                            ...prevData[index]?.id,
                                            _id: e.value,
                                            name: e.name,
                                            price: e.price,
                                            originalPrice: e.price,
                                            isRecurring:
                                              e.recurringOrder?.status,
                                          },
                                        };
                                        return [...prevData];
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                {multiAddInvoiceList &&
                                  multiAddInvoiceList[index] &&
                                  multiAddInvoiceList[index].id &&
                                  multiAddInvoiceList[index].id._id &&
                                  multiAddInvoiceList[index].id._id ===
                                    "custom" && (
                                    <input
                                      type="text"
                                      className="form-control mb-2"
                                      placeholder="Item Name"
                                      value={
                                        multiAddInvoiceList[index]?.id.name
                                      }
                                      onChange={(e) => {
                                        setMultiAddInvoiceList((prevData) => {
                                          prevData[index] = {
                                            ...prevData[index],
                                            id: {
                                              ...prevData[index]?.id,
                                              name: e.target.value,
                                            },
                                          };
                                          return [...prevData];
                                        });
                                      }}
                                    />
                                  )}

                                <textarea
                                  className="form-control"
                                  placeholder="Description"
                                  value={
                                    multiAddInvoiceList[index]?.id?.description
                                  }
                                  onChange={(e) => {
                                    setMultiAddInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        id: {
                                          ...prevData[index]?.id,
                                          description: e.target.value,
                                        },
                                      };
                                      return [...prevData];
                                    });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-lg-2 col-xxl-2">
                              <div className="form-group itemGroup">
                                <label>Price</label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  className="form-control"
                                  onWheel={(e) => e.target.blur()}
                                  value={
                                    multiAddInvoiceList[index]?.id
                                      ?.originalPrice
                                  }
                                  onChange={(e) => {
                                    setMultiAddInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        id: {
                                          ...prevData[index].id,
                                          originalPrice: parseInt(
                                            e.target.value
                                          ),
                                          price: parseInt(e.target.value),
                                        },
                                      };

                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-2 col-xxl-2">
                              <div className="form-group itemGroup">
                                <label>Discount</label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  className="form-control"
                                  onWheel={(e) => e.target.blur()}
                                  value={
                                    multiAddInvoiceList[index]?.id?.discount
                                  }
                                  onChange={(e) => {
                                    setMultiAddInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        id: {
                                          ...prevData[index].id,
                                          discount: parseInt(e.target.value),
                                        },
                                      };
                                      prevData[index].id.price =
                                        multiAddInvoiceList[index].id
                                          .originalPrice -
                                        multiAddInvoiceList[index].id.discount;
                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                              {multiAddInvoiceList[index]?.id?.originalPrice <
                                multiAddInvoiceList[index]?.id?.discount && (
                                <small>
                                  Discount cannot be greater than price
                                </small>
                              )}
                              {multiAddInvoiceList[index]?.id?.isRecurring ===
                                true && <small>recurring Discount</small>}
                            </div>
                            <div className="col-lg-1 col-xxl-1">
                              <div className="form-group itemGroup">
                                <label>QTY</label>
                                <input
                                  placeholder="1"
                                  type="number"
                                  className="form-control"
                                  value={multiAddInvoiceList[index]?.quantity}
                                  onChange={(e) => {
                                    setMultiAddInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        quantity: parseInt(e.target.value),
                                      };

                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                {multiAddInvoiceCount > 1 && (
                                  <button
                                    type="button"
                                    className="btn btn-generate ms-3 shadow-sm delIcon"
                                    onClick={(event) =>
                                      decreaseAddInvoiceCount(event, index)
                                    }
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                            {index == multiAddInvoiceCount - 1 && (
                              <div className="text-end">
                                <a
                                  className="addItem-text"
                                  onClick={(event) =>
                                    increaseAddInvoiceCount(event, index)
                                  }
                                >
                                  + Add Item
                                </a>
                              </div>
                            )}
                            {/* <div className="col-lg-2 col-xxl-2"> */}

                            {/* </div> */}
                          </div>
                      );
                    })}

                    <Modal
                      show={show}
                      onHide={() => setShow(false)}
                      dialogClassName="modal-90w"
                      aria-labelledby="contained-modal-title-vcenter"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Note
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="text-center justify-content-center">
                          {/* <Col>
                            <Button>USER</Button>
                        </Col>
                        <Col>
                            <Button>AGENT</Button>
                        </Col> */}
                          To create a subscription first add on invoice with
                          your recurring service, then charge the invoice(if
                          customer has an active payment method on file)
                        </Row>
                      </Modal.Body>
                    </Modal>

                    {/* {invoiceList.map((value, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-lg-7 col-xxl-7">
                              <div className="form-group itemGroup">
                                <label>Item</label>
                                <Select
                                  key={`my_unique_select_key__${value._id}`}
                                  // ref={selectInputRef}
                                  classNamePrefix="lp-copy-sel"
                                  isSearchable
                                  value={value._id}
                                  preventDefault
                                  options={serviceListArr}
                                  onChange={(e) => {
                                    let name = "";
                                    let isRecurring = false;
                                    serviceListArr.forEach((item) => {
                                      if (e.value == item.value) {
                                        name = item.name;
                                        isRecurring =
                                          item.recurringOrder.status;
                                        if (item.value == "custom") {
                                          value.id.originalPrice = 0;
                                        } else {
                                          value.id.originalPrice = item.price;
                                          value.id.price = item.price;
                                        }
                                      }
                                    });
                                    setInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        id: {
                                          ...prevData[index]?.id,
                                          _id: e.value,
                                          name,
                                          isRecurring,
                                        },
                                      };
                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                {invoiceList &&
                                  invoiceList[index] &&
                                  invoiceList[index].id &&
                                  invoiceList[index].id._id &&
                                  invoiceList[index].id._id === "custom" && (
                                    <input
                                      type="text"
                                      className="form-control mb-2"
                                      placeholder="Item Name"
                                      value={invoiceList[index]?.name}
                                      onChange={(e) => {
                                        setInvoiceList((prevData) => {
                                          prevData[index] = {
                                            ...prevData[index],
                                            id: {
                                              ...prevData[index]?.id,
                                              name: e.target.value,
                                            },
                                          };
                                          return [...prevData];
                                        });
                                      }}
                                    />
                                  )}

                                <textarea
                                  className="form-control"
                                  placeholder="Description"
                                  value={invoiceList[index]?.id?.description}
                                  onChange={(e) => {
                                    setInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        id: {
                                          ...prevData[index]?.id,
                                          description: e.target.value,
                                        },
                                      };
                                      return [...prevData];
                                    });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-lg-2 col-xxl-2">
                              <div className="form-group itemGroup">
                                <label>Price</label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  className="form-control"
                                  value={invoiceList[index]?.id?.originalPrice}
                                  onChange={(e) => {
                                    setInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        id: {
                                          ...prevData[index].id,
                                          originalPrice: parseInt(
                                            e.target.value
                                          ),
                                        },
                                      };

                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-2 col-xxl-2">
                              <div className="form-group itemGroup">
                                <label>Discount</label>
                                <input
                                  type="number"
                                  placeholder="0.00"
                                  className="form-control"
                                  value={invoiceList[index]?.id?.discount}
                                  onChange={(e) => {
                                    setInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        id: {
                                          ...prevData[index].id,
                                          discount: parseInt(e.target.value),
                                        },
                                      };
                                      prevData[index].id.price =
                                        invoiceList[index].id.originalPrice -
                                        invoiceList[index].id.discount;
                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                              {invoiceList[index]?.id?.originalPrice <
                                invoiceList[index]?.id?.discount && (
                                <small>
                                  Discount cannot be greater than price
                                </small>
                              )}
                              {invoiceList[index].id.isRecurring === true && (
                                <small>recurring Discount</small>
                              )}
                            </div>
                            <div className="col-lg-1 col-xxl-1">
                              <div className="form-group itemGroup">
                                <label>QTY</label>
                                <input
                                  placeholder="1"
                                  type="number"
                                  className="form-control"
                                  value={invoiceList[index]?.quantity}
                                  onChange={(e) => {
                                    setInvoiceList((prevData) => {
                                      prevData[index] = {
                                        ...prevData[index],
                                        quantity: parseInt(e.target.value),
                                      };

                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                {invoiceList.length > 1 && (
                                  <button
                                    type="button"
                                    className="btn btn-generate ms-3"
                                    onClick={(event) =>
                                      decreaseInvoiceCount(event, index)
                                    }
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          {index == invoiceList.length - 1 && (
                            <a
                              className="addItem"
                              href="#"
                              onClick={(event) => increaseItems(event)}
                            >
                              + Add Item
                            </a>
                          )}
                        </>
                      );
                    })} */}

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Note to Client</label>
                          <textarea
                            className="form-control"
                            value={noteToClient}
                            onChange={(e) => setNotetoClient(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={() =>
                                setRecurringInvoice(!recurringInvoice)
                              }
                              checked={recurringInvoice}
                              readOnly
                            />
                            <label
                              className="form-check-label checkbox-label"
                              // for="welcomeEmail"
                            >
                              Recurring invoice <br />
                              <small>
                                Recurring invoices are generated by OpFlow-CRM
                                and will show up as one-time payments in your
                                payment processor. This is useful if invoice
                                items change month to month, or if using a
                                payment method that does not support
                                subscriptions (e.g. bank transfer).
                              </small>
                              {recurringInvoice && (
                                <small
                                  style={{
                                    display: "block",
                                    marginTop: "14px",
                                  }}
                                >
                                  Recurring invoices are issued 5 days before
                                  they are due and charged automaically on the
                                  due date, if client has an active payment
                                  method in Stripe..{" "}
                                  <b>
                                    For other payment methods clients will be
                                    emailed a payment link for each new
                                    invoice..
                                  </b>
                                </small>
                              )}
                              {recurringInvoice && (
                                <div className="col-lg-5">
                                  <></>
                                  <div className="input-group align-items-center recurring-section">
                                    <label className="mr-2 checkbox-label">
                                      Issue every
                                    </label>
                                    <input
                                      type="number"
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      placeholder=""
                                      aria-label="Recipient's username"
                                      aria-describedby="basic-addon2"
                                      value={recurringInvoiceValue}
                                      onChange={(e) =>
                                        setRecurringInvoiceValue(e.target.value)
                                      }
                                    />
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    >
                                      <select
                                        className="recurringDropdown"
                                        value={recurringInvoiceType}
                                        onChange={(e) =>
                                          setRecurringInvoiceType(
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="months">Months</option>
                                        <option value="weeks">Weeks</option>
                                        <option value="days">Days</option>
                                      </select>
                                    </span>
                                  </div>
                                </div>
                              )}
                            </label>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="welcomeEmail"
                              onClick={() => {
                                if (addClientList.client) {
                                  setSendEmail(!sendEmail);
                                } else {
                                  toastMessage("error", "Select email address");
                                }
                              }}
                              checked={sendEmail}
                              readOnly
                            />
                            <label
                              className="form-check-label checkbox-label"
                              // for="welcomeEmail"
                            >
                              Send email notification <br />
                              <small>
                                If you don't notify the client they will still
                                see the invoice in their account.
                              </small>
                            </label>
                          </div>
                        </div>

                        {/* <div className="form-group mb-0">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              // id="welcomeEmail"
                              disabled={isRecurringServiceExist}
                              onClick={() => setPartialUpfront(!partialUpfront)}
                              checked={partialUpfront}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              // for="welcomeEmail"
                            >
                              Partial upfront payment <br />
                              <small>
                                If you want to collect a deposit upfront and
                                charge the remaining amount later.
                              </small>
                              {partialUpfront && (
                                <div className="col-lg-5">
                                  <div className="form-group mt-2 mb-0">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="0.00"
                                      value={partialUpfrontValue}
                                      onChange={(e) =>
                                        setPartialUpfrontValue(
                                          parseInt(e.target.value)
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="btn-section text-end">
                    <button
                      className="btn btn-save"
                      disabled={checkStatusOfDiscount()}
                      onClick={(event) => {
                        addInvoiceHandler(event);
                      }}
                    >
                      Add Invoice
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default AddInvoice;