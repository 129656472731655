import React, { useEffect, useContext, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useParams } from "react-router-dom";
import { AllContext } from "../../context";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import openIcon from "../../assets/img/icons/openSide.svg";
import "react-datepicker/dist/react-datepicker.css";
const EditCoupon = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchCoupon();
  }, [id]);
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiPutMethod,
    redirectToLogin,
    toastMessage,
  } = context;
  const { COUPON, SERVICES } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [coupon, setCoupon] = useState();
  const [description, setDescription] = useState();
  const [multipleDiscountCount, setMultipleDiscount] = useState(1);
  const [usePerCustomer, setOneUsePerCustomer] = useState(false);
  const [newCustomerOnly, setNewCustomerOnly] = useState(false);
  const [expiryDate, setExpiryDate] = useState();
  const [fixedAmount, setFixedAmount] = useState(false);
  const [percentage, setPercentage] = useState(false);
  const [durationForever, setDurationForever] = useState(true);
  const [firstPayment, setFirstPayment] = useState(false);
  const [multipleDiscountList, setMultipleDiscountList] = useState([
    {
      service: [],
      discount: 0,
    },
  ]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllServices();
  }, []);
  function getAllServices() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setLoader(true);
    apiGetMethod(SERVICES, "", header)
      .then((res) => {
        let serviceList = res.data.data;
        setServices(
          serviceList.map((row) => {
            return {
              label: row.name,
              value: row._id,
              isDisabled: false,
            };
          })
        );
        // setLoader(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function fetchCoupon() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setLoader(true);
    apiGetMethod(COUPON + "/" + id, "", header)
      .then((res) => {
        setCoupon(res.data.tags[0].couponCode);
        setDescription(res.data.tags[0].description);
        res.data.tags[0].applies_to.map((prevData, index) => {
          prevData.service.map((data, indexing) => {
            res.data.tags[0].applies_to[index].service[indexing] = {
              label: data.name,
              value: data._id,
            };
          });
        });
        setMultipleDiscountList(res.data.tags[0].applies_to);
        setMultipleDiscount(res.data.tags[0].applies_to.length);
        setOneUsePerCustomer(
          res.data.tags[0].redemption_limit_one_use_per_customer
        );
        setNewCustomerOnly(res.data.tags[0].redemption_limit_to_new_customer);
        setExpiryDate(res.data.tags[0].expiryDate);
        setFixedAmount(res.data.tags[0].fixedAmount);
        if (res.data.tags[0].fixedAmount == false) {
          setPercentage(true);
        }
        if (!res.data.tags[0].durationForever) {
          setFirstPayment(true);
        }
        setDurationForever(res.data.tags[0].durationForever);
        if (res.data.tags[0].expiryDate) {
          setOpen(true);
        }
        setLoader(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }
  const increaseDiscount = (event) => {
    event.preventDefault();
    setMultipleDiscount(multipleDiscountCount + 1);
    multipleDiscountList[multipleDiscountCount] = {
      service: [],
      discount: 0,
    };
    setMultipleDiscountList(multipleDiscountList);
  };
  const decreaseDiscountCount = (event) => {
    event.preventDefault();
    if (multipleDiscountCount > 1)
      setMultipleDiscount(multipleDiscountCount - 1);
    multipleDiscountList.pop();
    setMultipleDiscountList(multipleDiscountList);
  };

  function handleFormSubmit(e) {
    e.preventDefault();
    multipleDiscountList.map((row, index) => {
      multipleDiscountList[index].service = row.service.map((service) => {
        if (service && service.value) return service.value;
      });
    });
    const data = {
      couponCode: coupon,
      description: description,
      fixedAmount: fixedAmount,
      durationForever: durationForever,
      applies_to: multipleDiscountList,
      redemption_limit_one_use_per_customer: usePerCustomer,
      redemption_limit_to_new_customer: newCustomerOnly,
      expiryDate: expiryDate,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(COUPON + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        navigate("/coupons");
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function openCalender(e) {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  function generateRandomNumber(e) {
    e.preventDefault();
    const n = 8;
    let randomString = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (let i = 0; i < n; i++) {
      randomString += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCoupon(randomString);
  }
  useEffect(() => {
    services.map((row, index) => {
      services[index].isDisabled = false;
    });
    if (multipleDiscountList.length > 0) {
      multipleDiscountList.map((row) => {
        if (row && row.service && row.service.length > 0)
          row.service.map((service) => {
            let data = services.findIndex((row) => row == service);
            if (data > -1) services[data].isDisabled = true;
          });
      });
      setServices(services);
    }
  }, [multipleDiscountList]);

  return (
    <div>
      <Sidebar
        activePage="coupons"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Edit Coupon
                    <span className="userCounter">&nbsp;</span>
                  </h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid">
            <form className="couponForm">
              <div className="row">
                <div className="col-xxl-9 col-lg-9">
                  <div className="setting-form ticketForm formOuter">
                    <div className="commonForm px-4 rounded-3">
                      <h3 className="fs-4 m-0 mb-4 p-3">Edit Coupon</h3>
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group">
                            <label>Coupon code</label>
                            <div className="input-group">
                              <input
                                type="text"
                                disabled="true"
                                className="form-control text-uppercase"
                                placeholder="E.G. 25OFF"
                                value={coupon}
                                onChange={(e) => {
                                  setCoupon(e.target.value);
                                }}
                              />
                              <button
                                className="btn btn-generate"
                                disabled="true"
                                onClick={(e) => generateRandomNumber(e)}
                              >
                                Generate
                              </button>
                            </div>
                            <small>
                              This is what clients will use to get the discount.
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              Description <small>optional</small>
                            </label>
                            <textarea
                              className="form-control"
                              value={description}
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                            ></textarea>
                            <small>
                              Not visible to clients, helps you remember what
                              the coupon is for.
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Discount type</label>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fixedAmount"
                                    id="fixedAmount"
                                    // checked={fixedAmount}
                                    checked={fixedAmount}
                                    onChange={(e) => {
                                      setFixedAmount(e.target.checked);
                                      setPercentage(false);
                                    }}
                                  />
                                  <label
                                    className="form-check-label checkbox-label"
                                    htmlFor="fixedAmount"
                                  >
                                    Fixed amount
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="percentage"
                                    id="percentage"
                                    checked={percentage}
                                    onChange={(e) => {
                                      setPercentage(e.target.checked);
                                      setFixedAmount(false);
                                    }}
                                  />
                                  <label
                                    className="form-check-label checkbox-label"
                                    htmlFor="Percentage"
                                  >
                                    Percentage
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>
                              Discount duration for recurring services
                            </label>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="forever"
                                    id="forever"
                                    checked={durationForever}
                                    onChange={(e) => {
                                      setDurationForever(e.target.checked);
                                      setFirstPayment(false);
                                    }}
                                  />
                                  <label
                                    className="form-check-label checkbox-label"
                                    htmlFor="fixedAmount"
                                  >
                                    Forever
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="firstPayment"
                                    id="firstPayment"
                                    checked={firstPayment}
                                    onChange={(e) => {
                                      setFirstPayment(e.target.checked);
                                      setDurationForever(false);
                                    }}
                                  />
                                  <label
                                    className="form-check-label checkbox-label"
                                    htmlFor="Percentage"
                                  >
                                    First payment
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="addMore-discount">
                        {multipleDiscountList &&
                          multipleDiscountList[0] &&
                          multipleDiscountList[0].service &&
                          [...Array(multipleDiscountCount)].map(
                            (value, index) => {
                              return (
                                <div className="row" key={index}>
                                  <div className="col-lg-8 col-md-8">
                                    <div className="form-group">
                                      <label>Applies to...</label>
                                      <Select
                                        className="my-react-select my-react-select--compact"
                                        id={"select" + index}
                                        isMulti
                                        value={[
                                          ...multipleDiscountList[index]
                                            .service,
                                        ]}
                                        placeholder="All Services"
                                        options={services}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        onChange={(e) => {
                                          setMultipleDiscountList(
                                            (prevData) => {
                                              if (
                                                prevData[index] == undefined
                                              ) {
                                                prevData[index] = {
                                                  service: e,
                                                  discount: 0,
                                                };
                                              } else {
                                                prevData[index].service = e;
                                              }
                                              return [...prevData];
                                            }
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Discount{" "}
                                        {fixedAmount == false ? "%" : "$"}
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="Number"
                                          className="form-control text-uppercase"
                                          required
                                          id={"discount" + index}
                                          value={
                                            multipleDiscountList[index]
                                              ? multipleDiscountList[index]
                                                  .discount
                                              : 0
                                          }
                                          onChange={(e) => {
                                            setMultipleDiscountList(
                                              (prevData) => {
                                                if (
                                                  prevData[index] == undefined
                                                ) {
                                                  prevData[index] = {
                                                    service: [],
                                                    discount: e.target.value,
                                                  };
                                                } else {
                                                  prevData[index].discount =
                                                    e.target.value;
                                                }
                                                return [...prevData];
                                              }
                                            );
                                          }}
                                        />
                                        {multipleDiscountCount > 1 && (
                                          <button
                                            type="button"
                                            className="btn btn-generate ms-3"
                                            onClick={(event) =>
                                              decreaseDiscountCount(event)
                                            }
                                          >
                                            <i className="fa-solid fa-trash"></i>
                                          </button>
                                        )}
                                      </div>
                                      {index == multipleDiscountCount - 1 && (
                                        <a
                                          className="discountLink"
                                          onClick={(event) =>
                                            increaseDiscount(event)
                                          }
                                        >
                                          + Add discount
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Redemption limits</label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="purchasingcompany"
                                checked={usePerCustomer}
                                onChange={(e) => {
                                  setOneUsePerCustomer(e.target.checked);
                                }}
                              />
                              <label
                                className="form-check-label checkbox-label"
                                htmlFor="purchasingcompany"
                              >
                                Limit to one use per customer
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="newCustomers"
                                checked={newCustomerOnly}
                                onChange={(e) => {
                                  setNewCustomerOnly(e.target.checked);
                                }}
                              />
                              <label
                                className="form-check-label checkbox-label"
                                htmlFor="newCustomers"
                              >
                                Limit to new customers only
                              </label>
                            </div>

                            <div className="form-check expiryDate-field">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={open}
                                id="expiryDate"
                                onChange={(e) => {
                                  openCalender(e);
                                }}
                              />
                              <label
                                className="form-check-label checkbox-label"
                                htmlFor="expiryDate"
                              >
                                Set expiry date
                              </label>
                              <div className="col-lg-4">
                                {open === true ? (
                                  <DatePicker
                                    // maxDate={moment().toDate()}
                                    selected={new Date(expiryDate).getTime()}
                                    minDate={new Date()}
                                    dateFormat="MMMM dd,yyyy"
                                    className="form-control expiryDate"
                                    onChange={(e) => setExpiryDate(e)}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-section text-end"></div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="commonBtn-section">
                    <button
                      className="btn btn-cancel"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/coupons");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-save"
                      disabled={coupon == "" || coupon == undefined}
                      onClick={(e) => {
                        e.preventDefault();
                        let check = true;
                        [...Array(multipleDiscountCount)].map((row, index) => {
                          if (
                            multipleDiscountList[index] &&
                            multipleDiscountList[index].discount <= 0
                          ) {
                            check = false;
                          }
                          if (multipleDiscountList[index] == undefined) {
                            check = false;
                          }
                        });
                        if (check) {
                          handleFormSubmit(e);
                        } else {
                          toastMessage(
                            "error",
                            "Discount value must be greater than 0"
                          );
                        }
                      }}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* delete modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Are you sure you want to delete coupon{" "}
                  <strong>IJ0P2E (Copy) (Copy)</strong>?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal */}
    </div>
  );
};
export default EditCoupon;
