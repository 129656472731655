import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useSelector, useDispatch } from "react-redux";
import { AllContext } from "../../context";
import {} from "../../api/rest";
import SettingTabs from "../../components/settings/tabs";
import copy from "copy-to-clipboard";
import openIcon from "../../assets/img/icons/openSide.svg";

const ShopSettings = () => {
  //Constants
  const dispatch = useDispatch();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const context = useContext(AllContext);
  const {
    toastMessage,
    apiGetMethod,
    redirectToLogin,
    allApiUrl,
    apiPutMethod,
  } = context;
  const navigate = useNavigate();

  //states
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [checked, setChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState({
    public: false,
    private: false,
  });
  const [publicLink] = useState("https://opflow.softuvo.click/shop ");

  const copyToClipBoard = (event, from) => {
    event.preventDefault();
    setIsLinkCopied({ ...isLinkCopied, public: true, private: false });
    copy(publicLink);
  };
  // Functions
  useEffect(() => {
    getOrderStatus();
  }, []);
  function getOrderStatus() {
    apiGetMethod(allApiUrl.SHOP_STATUS, "", header)
      .then(async (res) => {
        if (res.status === 200) {
          setChecked(res.data.Data.publicUrlStatus);
        }
      })
      .catch((err) => {
        console.log("err", err);
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    const data = {
      publicUrlStatus: nextChecked,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPutMethod(allApiUrl.EDIT_SHOP_STATUS, data, header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(!res.data.Data.publicUrlStatus);
          toastMessage(
            "success",
            `Public Url is set to ${nextChecked ? "Active" : "Inactive"}`
          );
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <SettingTabs currentTab="shop" />
      {/* <PageLoader isLoading={initialLoading} /> */}
      {/* {!initialLoading && (
        <> */}
      <div className="commonSlidePl">
        <div className="setting-form-section mt-4 mb-4">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-xxl-10 col-lg-10 mx-auto">
                <div className="d-flex justify-content-between">
                  <h3 className="mt-0">Shop Settings</h3>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {/* Teams */}
                    <div className="setting-form">
                      <form className="commonForm rounded-1">
                        <div className="d-flex align-items-center">
                          <h3 className="me-3">Show Public</h3>
                          <Switch
                            onChange={handleChange}
                            checked={checked}
                            className="react-switch"
                            onHandleColor="#5a4cdc"
                            handleDiameter={28}
                            onColor="#5a4cdc"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={18}
                            width={45}
                          />
                        </div>
                        <p>
                          If Toggle On, the Embed URL can be used which will be the public url of <a onClick={(event) => {
                            copyToClipBoard(event, "public");
                            toastMessage("success", `Copied To clipboard`);
                          }} >https://opflow.softuvo.click/shop</a>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
};
export default ShopSettings;
