import React, { useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../widgets/sidebar";
import { AllContext } from "../../../context";
import { useSelector } from "react-redux";
import Header from "../../../widgets/header";
import SettingTabs from "../tabs";
import originalTimeZone from "../../../api/timezone.json";
import { saveCompanyData } from "../../../redux/action/sidemenu";
import openIcon from "../../../assets/img/icons/openSide.svg";
import { Select } from "antd";
const { Option } = Select;

const Company = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [loader, setLoader] = useState(false);
  const context = useContext(AllContext);
  const [timeZone, setTimeZone] = useState([]);
  const { allApiUrl, apiGetMethod, apiPostMethod, toastMessage } = context;
  const { COMPANY } = allApiUrl;

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    fetchCompanyDetails();
    let expandedTimeZone = [];
    originalTimeZone.map((data) => {
      let dataClone = { ...data };
      data.utc.map((utc) => {
        let newTimeZoneData = { ...dataClone };
        newTimeZoneData.utc = utc;
        expandedTimeZone.push(newTimeZoneData);
      });
    });
    setTimeZone(expandedTimeZone);
  }, []);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    companyName: "",
    lightLogo: "",
    darkLogo: "",
    favicon: "",
    timezone: {},
    contactLink: "",
    primaryColor: "",
    secondaryColor: "",
  });
  let url = window.location.origin;
  function fetchCompanyDetails() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setLoader(true);
    apiGetMethod(COMPANY, "", header).then((res) => {
      if (res.status === 200) {
        setForm(res.data);
        const companyDetail = {...res.data};
        dispatch(saveCompanyData(companyDetail));
        // setForm.contactLink(url + "/" + res.data.contactLink);
      } else {
        // console.log("error in apis==>", res);
      }
    });
  }
  function submitForm() {
    let formData = new FormData();
    for (var key in form) {
      formData.append(key, form[key]);
    }

    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setLoader(true);
    apiPostMethod(COMPANY, formData, header).then((res) => {
      if (res.status == 201) {
        toastMessage("success", res.data.message);
        fetchCompanyDetails();
      }
    });
  }
  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 col-12">
                  <div className="heading position-relative">
                    <h2 className="mb-0">
                      General
                    </h2>
                    <span className="openArrowIcon" onClick={() => { setToggleSidebar(!toggleSideBar) }}>
                      <img src={openIcon} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <SettingTabs currentTab="general" />

      {/*<div className="setting-tab">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-content">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <div
                      className="section-back"
                      onClick={() => navigate("/settings")}
                    >
                      <a href="#" className="nav-link">
                        <i className="fa-solid fa-chevron-left"></i> Settings
                      </a>
                    </div>
                  </li>
                   <li className="nav-item" role="presentation">
                    <button
                    className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Company
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => navigate("/settings/payment")}
                  >
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Payments
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => navigate("/settings/team")}
                  >
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Team
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-back pl-240 mt-3">
        <a onClick={() => {navigate('/settings')}} href="#">
          <i className="fa-solid fa-chevron-left"></i> Settings
        </a>
      </div>*/}
      <div className="commonSlidePl">
        <div className="setting-form-section mt-5">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-xxl-10 col-lg-10 mx-auto">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabIndex="1"
                  >
                    <div className="setting-form">
                      <form className="commonForm">
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <div className="preview">
                                {form.lightLogo && (
                                  <img
                                    src={
                                      typeof form.lightLogo == "string"
                                        ? form.lightLogo
                                        : URL.createObjectURL(form.lightLogo)
                                    }
                                    accept="image/png, image/x-icon, image/svg"
                                  />
                                )}
                              </div>
                              <div className="d-flex">
                                <button type="button" className="btn btn-default">
                                  Upload Logo{" "}
                                  <input
                                    type="file"
                                    accept="image/png, image/x-icon, image/svg"
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.lightLogo = e.target.files[0];
                                        return { ...prevData };
                                      });
                                    }}
                                  />
                                </button>
                                {form.lightLogo && (
                                  <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                      form.lightLogo = "";
                                      setForm({ ...form });
                                    }}
                                  >
                                    Remove Logo
                                  </button>
                                )}
                              </div>
                              <small>For light backgrounds</small>
                            </div>
                          </div>

                          {/* <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="preview">
                              {form.darkLogo && (
                                <img
                                  src={
                                    typeof form.darkLogo == "string"
                                      ? form.darkLogo
                                      : URL.createObjectURL(form.darkLogo)
                                  }
                                />
                              )}
                            </div>
                            <div className="d-flex">
                              <button type="button" className="btn btn-default">
                                Upload Logo{" "}
                                <input
                                  type="file"
                                  accept="image/png, image/x-icon, image/svg"
                                  onChange={(e) => {
                                    setForm((prevData) => {
                                      prevData.darkLogo = e.target.files[0];
                                      return { ...prevData };
                                    });
                                  }}
                                />
                              </button>
                              {form.darkLogo && (
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={() => {
                                    form.darkLogo = "";
                                    setForm({ ...form });
                                  }}
                                >
                                  Remove Logo
                                </button>
                              )}
                            </div>
                            <small>For dark backgrounds</small>
                          </div>
                        </div> */}
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label>Company name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Your Agency"
                                value={form.companyName}
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    prevData.companyName = e.target.value;
                                    return { ...prevData };
                                  });
                                }}
                              />
                              {/* <small>Displayed in header throughout SPP.</small> */}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="preview">
                                {form.favicon && (
                                  <img
                                    src={
                                      typeof form.favicon == "string"
                                        ? form.favicon
                                        : URL.createObjectURL(form.favicon)
                                    }
                                  />
                                )}
                              </div>
                              <div className="d-flex">
                                <button type="button" className="btn btn-default">
                                  Upload favicon{" "}
                                  <input
                                    type="file"
                                    accept="image/png, image/x-icon, image/svg"
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.favicon = e.target.files[0];
                                        return { ...prevData };
                                      });
                                    }}
                                  />
                                </button>
                                {form.favicon && (
                                  <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                      form.favicon = "";
                                      setForm({ ...form });
                                    }}
                                  >
                                    Remove favicon
                                  </button>
                                )}
                              </div>
                              <small>For light backgrounds</small>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <div className="form-group">
                                <div className="preview">
                                  <label>Color Theme</label>
                                </div>
                                <div className="d-flex">
                                  <input
                                    type="color"
                                    id="color-picker"
                                    value={form.primaryColor}
                                    onChange={(e) => {
                                      setForm((prevData) => {
                                        prevData.primaryColor = e.target.value;
                                        return { ...prevData };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="form-group">
                              <div className="preview">
                                <label>Secondary Theme color</label>
                              </div>
                              <div className="d-flex">
                                <input
                                  type="color"
                                  id="color-picker"
                                  value={form.secondaryColor}
                                  onChange={(e) => {
                                    setForm((prevData) => {
                                      prevData.secondaryColor = e.target.value;
                                      return { ...prevData };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <label>Timezone</label>
                              <div className="mt-3">
                                <Select
                                  showSearch
                                  style={{ width: 200 }}
                                  placeholder="Search to Select"
                                  value={form.timezone}
                                  onChange={(e) => {
                                    setForm((prevData) => {
                                      prevData.timezone = e;
                                      return { ...prevData };
                                    });
                                  }}
                                >
                                  {timeZone.map((data, idx) => {
                                    return (
                                      <Option
                                        key={idx}
                                        value={JSON.stringify(data)}
                                      >
                                        {data.utc}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label>Contact Link</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Link"
                                value={form.contactLink}
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    prevData.contactLink = e.target.value;
                                    return { ...prevData };
                                  });
                                }}
                              />
                              <small>
                                Where clients can contact you, shown in order
                                forms and receipts.
                              </small>
                            </div>
                          </div>

                          {/* <div className="col-lg-12">
                          <div className="form-group checkbox-group">
                            <input className="" type="checkbox" id="item1" />
                            <label for="item1">
                              SPP linkback <br />{" "}
                              <small>Powered by SPP link in the footer.</small>
                            </label>
                          </div>
                        </div> */}
                        </div>
                      </form>
                      <div className="btn-section text-end">
                        <button
                          className="btn btn-save"
                          onClick={() => submitForm()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Company;
