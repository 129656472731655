import { useState } from "react";
import { Modal } from "react-bootstrap";
import {fontAwesomeUrl} from "../../../shared/constants";

const AddCustomSideMenu = ({ isAddMenuModelOpen, handleToggle, addmenuHandler, menuDataLength }) => {

    const [formValues, setFormValues] = useState({ name: "", url: "", icon: "", newTab: false, show: true, orderId: menuDataLength });

    const handleChangeValue = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    return (
        <Modal show={isAddMenuModelOpen} onHide={handleToggle} className="modal fade history-modal">
            <div className="modal-content">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    addmenuHandler(formValues);
                }}>
                    <div className="modal-body">
                        <div className="delete-content">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                    <label className="mb-3">Link Text</label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder=""
                                        value={formValues.name}
                                        required
                                        onChange={handleChangeValue}
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                    <label className="mb-3">Link URL</label>
                                    <input
                                        type="text"
                                        name="url"
                                        className="form-control"
                                        placeholder=""
                                        value={formValues.url}
                                        required
                                        onChange={handleChangeValue}
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                    <label className="mb-3">Link icon class</label>
                                        <input
                                            type="text"
                                            name="icon"
                                            className="form-control"
                                            placeholder=""
                                            value={formValues.icon}
                                            required
                                            onChange={handleChangeValue}
                                        />
                                        <small>Choose any icon from <a target="_blank" href={fontAwesomeUrl}>FontAwesome</a> and copy its class here</small>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="newTab"
                                            onChange={(e) => setFormValues({ ...formValues, newTab: e.target.checked })}
                                        />&nbsp;
                                        <label>Open in new tab</label>
                                        <div>
                                            <small>Please use relative URLs to link to your internal pages (e.g. /portal/services) but use a full URL for external resources (e.g. https://demo.agency/blog/)</small>
                                        </div>    
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleToggle}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                        >
                            Add
                        </button>
                    </div>
                </form>    
            </div>
        </Modal>
    )
}

export default AddCustomSideMenu;