import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/avatar.png";
import openIcon from "../../assets/img/icons/openSide.svg";
import { formatMonthNameAndDate } from "../../shared/functions";
import {
  saveLoginUserAccessToken,
  saveLoginUserInfo,
} from "../../redux/action/login";
import PageLoader from "../../components/pageLoader";
import { AllContext } from "../../context";
import FormElementView from "../../components/FormElementsEdit/FormElementView";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";

const ViewClient = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const dispatch = useDispatch();
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  //constants vars
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const {
    apiPostMethod,
    apiPutMethod,
    apiGetMethod,
    allApiUrl,
    apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const {
    GET_SPECIFIC_CLIENT,
    DELETE_CLIENT,
    GET_ALL_TEAM,
    EDIT_CLIENT,
    CLIENT_DATA_LISTING,
  } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const rolesList = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const userInfoRoles = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);

  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const specificClientId = window.location.pathname.split("/")[2];
  //useState
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [clientId, setClientId] = useState("");
  const [teamAccountList, setTeamAccountList] = useState([]);
  const [teamMemberName, setTeamMemberName] = useState("");
  const [assignTeamMember, setAssignTeamMember] = useState("");
  const [additionalData, setAdditionalData] = useState([]);
  const [apiResponse, setApiResponse] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [crmData, setCrmData] = useState([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  //   //functions
  useEffect(() => {
    if (specificClientId) {
      setIsLoading(true);
      setInitialLoading(true);
      getSpecificClientDataHandler();
      getAllTeam();
      getClientAdditionalInfo();
      setIsLoading(false);
    }
  }, [specificClientId]);

  function getClientAdditionalInfo() {
    setIsLoading(true);
    apiGetMethod(`${CLIENT_DATA_LISTING}/${specificClientId}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAdditionalData(res.data);
          setIsLoading(false);
          setApiResponse((prev) => {
            let number = Object.keys(prev).length;
            return {
              ...prev,
              [number]: "success",
            };
          });
        }
      })
      .catch((err) => {
        // navigate("/clients");
        setInitialLoading(false);
        setIsLoading(false);
      });
  }
  // all apis and api success
  useEffect(() => {
    let total = 3;
    let apiSuccessFullyCalled = Object.keys(apiResponse).length;
    if (apiSuccessFullyCalled == total) {
      setInitialLoading(false);
    }
  }, [apiResponse]);
  function getSpecificClientDataHandler() {
    setIsLoading(true);
    apiGetMethod(`${GET_SPECIFIC_CLIENT}/${specificClientId}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          setClientData(res.data.user[0]);
          setAssignTeamMember(res.data.user[0]?.accountManager);
          setCrmData(res.data.user[0]?.crmFields);
          setIsLoading(false);
          setInitialLoading(false);
          setApiResponse((prev) => {
            let number = Object.keys(prev).length;
            return {
              ...prev,
              [number]: "success",
            };
          });
        }
      })
      .catch((err) => {
        navigate("/clients");
        setInitialLoading(false);
        setIsLoading(false);
      });
  }
  const date = new Date(clientData?.userId?.createdOn);
  const monthName = date.toLocaleString("default", {
    month: "short",
  });
  const day = date.getDate();
  const createdOn = `${monthName} ${day}`;

  function deleteClient(couponId) {
    setDisabledBtn(true);
    apiDeleteMethod(DELETE_CLIENT, couponId, header)
      .then((res) => {
        navigate("/clients");
        toastMessage("success", res.data.message);
        setIsLoading(false);
        setDisabledBtn(false);
        setIsDeleteConfirmModalOpen(false);
      })
      .catch((err) => {
        toastMessage("error", err.data.toastMessage);
        setIsLoading(false);
        setDisabledBtn(false);
        setIsDeleteConfirmModalOpen(false);
      });
  }
  function getAllTeam() {
    setIsLoading(true);
    apiGetMethod(GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTeamAccountList(res.data.team);
          setApiResponse((prev) => {
            let number = Object.keys(prev).length;
            return {
              ...prev,
              [number]: "success",
            };
          });

          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }
  const assignTeamMemberHandler = (event) => {
    event.preventDefault();
    const data = {
      accountManager: assignTeamMember,
    };
    apiPutMethod(`${EDIT_CLIENT}/${specificClientId}`, data, header)
      .then((res) => {
        toastMessage("success", `Client Assigned to ${teamMemberName}`);
        getSpecificClientDataHandler();
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        console.log(err);
      });
  };
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
  //sign client handler
  function signInClientHandler() {
    const data = {
      email: clientData?.userId?.email,
      adminEmail: userInfo.email,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPostMethod(allApiUrl.SIGN_AS_CLIENT, data, header)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          navigate("/home");
          dispatch(saveLoginUserAccessToken(res.data.token));
          dispatch(saveLoginUserInfo({ ...res.data, showButton: true }));
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  return (
    <div>
      <Sidebar
        activePage="clients"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      <div className="commonSlidePl">
        <div className="orders-section commonPadding dashboardTop">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="heading position-relative">
                  <h2 className="mb-0">
                    Clients <span className="userCounter">&nbsp;</span>
                  </h2>
                  <span
                    className="openArrowIcon"
                    onClick={() => {
                      setToggleSidebar(!toggleSideBar);
                    }}
                  >
                    <img src={openIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid">
            {!initialLoading && (
              <>
                <div className="row">
                  <div className="col-xxl-6 col-lg-12">
                    <div className="client-heading mt-3">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="d-flex align-items-center">
                            <div className="userImg">
                              <img
                                src={
                                  clientData?.userId && clientData.userId?.image
                                    ? clientData.userId?.image
                                    : DummyAvatar
                                }
                              />
                            </div>
                            <div className="userName">
                              {clientData?.userId?.firstName &&
                              clientData?.userId?.lastName ? (
                                <h3 className="fs-4">
                                  {clientData?.userId?.firstName +
                                    " " +
                                    clientData?.userId?.lastName}
                                </h3>
                              ) : (
                                <h3 className="fs-4">
                                  {clientData?.userId?.firstName}
                                </h3>
                              )}
                            </div>
                          </div>
                        </div>

                        {rolesList.clients.add_edit_login && (
                          <div className="col-lg-6 col-md-6">
                            <div className="btns">
                              {/* <a
                                className="btn-edit"
                                // href="#"
                                onClick={signInClientHandler}
                              >
                                Sign in as user
                              </a> */}
                              {userInfoRoles.clients.add_edit_login && (
                                <a
                                  className="btn-edit"
                                  // href=""
                                  onClick={signInClientHandler}
                                >
                                  Sign in as user
                                </a>
                              )}
                              <div className="dropdown">
                                <button
                                  className="btn btn-secondary dropdown-toggle btn-info px-3"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() => {
                                        setIsLoading(true);
                                        navigate(`/add-invoice`, {
                                          state: {
                                            selectedUser: specificClientId,
                                          },
                                        });
                                        setIsLoading(false);
                                      }}
                                    >
                                      New invoice
                                    </a>
                                  </li>
                                  <li>
                                    {rolesList?.ticketManagement
                                      ?.add_delete && (
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => {
                                          setIsLoading(true);
                                          navigate("/add-ticket", {
                                            state: {
                                              mailTo: clientData.userId,
                                              from: "client",
                                            },
                                          });
                                          setIsLoading(false);
                                        }}
                                      >
                                        New ticket
                                      </a>
                                    )}
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#assignModal"
                                    >
                                      Assign a manager
                                    </a>
                                  </li>
                                  {/* <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#mergeModal"
                              >
                                Merge
                              </a>
                            </li> */}
                                  {rolesList.clients._delete && (
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => {
                                          setClientId(specificClientId);
                                          setIsDeleteConfirmModalOpen(true);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="client-details rounded-1">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-4">
                          <p className="clientLabel">Status</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8">
                          <p className="clientText text-capitalize">
                            {clientData?.accountStatusData?.status
                              ? clientData?.accountStatusData?.status?.name
                              : clientData?.role}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-4">
                          <p className="clientLabel">Email</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8">
                          <p className="clientText">
                            {clientData?.userId?.email}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-4">
                          <p className="clientLabel">Created On</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8">
                          <p className="clientText">{createdOn}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-4">
                          <p className="clientLabel">Last login</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8">
                          <p className="clientText">
                            {clientData?.userId?.lastLogin ? (
                              <>
                                {clientData?.userId?.lastLogin.date ? (
                                  <>
                                    {" "}
                                    {clientData?.userId?.lastLogin.date
                                      ? formatMonthNameAndDate(
                                          clientData?.userId?.lastLogin.date,
                                          companyData.timezone
                                        )
                                      : ""}
                                    <br />
                                    {clientData?.userId?.lastLogin.ipAddress}
                                  </>
                                ) : (
                                  clientData?.userId?.lastLogin
                                )}
                              </>
                            ) : (
                              "--"
                            )}{" "}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-4">
                          <p className="clientLabel">Phone</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8">
                          <p className="clientText">{clientData?.phone}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-4">
                          <p className="clientLabel">Company</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8">
                          <p className="clientText text-capitalize">
                            {clientData?.company}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-4">
                          <p className="clientLabel">Tax ID</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8">
                          <p className="clientText">{clientData?.taxId}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-4">
                          <p className="clientLabel">Address</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-8">
                          <p className="clientText text-capitalize">
                            {clientData?.billingAddress}
                          </p>
                        </div>
                      </div>

                      {/* <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <p className="clientLabel">Balance</p>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <p className="clientText">$0.00</p>
                  </div>
                </div> */}
                    </div>
                  </div>

                  {/* <div className="notes mt-5  mb-5">
                <p>Notes</p>
                <div className="edid-note">
                  <a href="#">Edit</a>
                </div>
              </div> */}

                  {/* <div className="heading">
                <h2>History</h2>
              </div>

              <div className="history-table mb-5">
                <div className="row">
                  <div className="col-lg-2  col-md-2">
                    <div className="history-date">
                      <p>Sep 20</p>
                    </div>
                  </div>
                  <div className="col-lg-8  col-md-8">
                    <div className="history-detail">
                      <p data-bs-toggle="modal" data-bs-target="#historyModal">
                        <span>Client name</span> Order created.
                      </p>
                      <p data-bs-toggle="modal" data-bs-target="#historyModal">
                        <span>Client name</span> Order created.
                      </p>
                      <p data-bs-toggle="modal" data-bs-target="#historyModal">
                        <span>Client name</span> Order created.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2  col-md-2">
                    <div className="history-date">
                      <p>1:47 pm</p>
                    </div>
                  </div>
                </div>
              </div> */}
                  <FormElementView crmData={crmData} />
                </div>
                <div className="col-xxl-6 col-lg-6 mb-4">
                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Invoices</h2>
                    </div>
                    {additionalData &&
                    additionalData?.invoices &&
                    additionalData?.invoices?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Invoice</th>
                              <th>Date</th>
                              <th>Total ($)</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.invoices
                                .map((item, idk) => {
                                  return (
                                    <tr key={idk}>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(
                                              `/view-invoice/${item.orderId}`
                                            )
                                          }
                                        >
                                          {item.invoiceId}
                                        </a>
                                      </td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.createdDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>${item.totalPrice.toFixed(2)}</td>
                                      <td autoCapitalize="none">
                                        <span className="pill pill-grey fw-normal fs-12">
                                          {item.status}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                                .reverse()}
                            {/* <button onClick={() => setShowMore(!showMore)}>
                              {showMore ? "Show Less" : "Show More"}
                            </button> */}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>There is no invoice added</div>
                    )}
                  </div>

                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Orders</h2>
                    </div>
                    {additionalData &&
                    additionalData?.orders &&
                    additionalData?.orders?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Order No.</th>
                              <th>Title</th>
                              <th>Paid Date</th>
                              {/* <th>Assigned Team Member</th> */}
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.orders
                                ?.map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(
                                              `/view-orders/${item?.orderId}`,
                                              {
                                                state: {
                                                  _id: item._id,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          {item?.orderId}
                                        </a>
                                      </td>
                                      <td>{item?.title}</td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.createdDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>
                                        <span className="pill pill-grey orderStatus fw-normal">
                                          {item?.orderStatusData.length
                                            ? item?.orderStatusData[0]?.orderStatuses?.name.toUpperCase()
                                            : item?.status.toUpperCase()}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                                .reverse()}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>There is no order added</div>
                    )}
                  </div>

                  <div className="tickets-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Tickets</h2>
                    </div>
                    {additionalData &&
                    additionalData?.tickets &&
                    additionalData?.tickets?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Subject</th>
                              <th>Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.tickets
                                .map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(`/view-ticket/${item._id}`)
                                          }
                                        >
                                          {item.subject}
                                        </a>
                                      </td>
                                      <td>
                                        {" "}
                                        {formatMonthNameAndDate(
                                          item.date,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>
                                        <span className="pill pill-grey orderStatus fw-normal">
                                          {item.status}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                                .reverse()}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>There is no ticket added</div>
                    )}
                  </div>

                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Subscriptions</h2>
                    </div>
                    {additionalData &&
                    additionalData?.subscriptions &&
                    additionalData?.subscriptions?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Service</th>
                              <th>Started</th>
                              <th>Next Payment</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.subscriptions
                                .map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a
                                          onClick={() => {
                                            navigate(
                                              `/view-subscription/${item?.orderId}`
                                            );
                                          }}
                                        >
                                          {item?.subscriptionId
                                            ? item?.subscriptionId
                                            : item.orderId}
                                        </a>
                                      </td>
                                      <td>
                                        <a>
                                          {item?.product &&
                                            item?.product[0] &&
                                            item.product[0]?.name}
                                        </a>
                                      </td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.startDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.nextDueDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          className={`pill ${
                                            item.paymentStatus === "past due"
                                              ? "pill-blue"
                                              : item.serviceStatus == true
                                              ? "pill-green"
                                              : "pill-red"
                                          } fs-12`}
                                        >
                                          {item.paymentStatus === "past due"
                                            ? "Past Due"
                                            : item.serviceStatus == true
                                            ? "Active"
                                            : "Cancelled"}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                                .reverse()}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>There is no subscription added</div>
                    )}
                  </div>

                  <div className="files-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Files</h2>
                    </div>
                    {additionalData &&
                    additionalData?.files &&
                    additionalData?.files?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Size</th>
                              <th>Created Date</th>
                              <th>resource type</th>
                              <th>owner</th>
                              <th>resource Id</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.files
                                .map((value, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a href={value?.url} target="_blank">
                                          {value?.fileName}
                                        </a>
                                      </td>
                                      <td>{formatBytes(value?.fileSize)}</td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          value?.createdOn,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>{value?.eventType}</td>
                                      <td>
                                        <a
                                          href="#"
                                          onClick={() =>
                                            navigate(
                                              `/${
                                                value.userId.group == 1
                                                  ? "accounts/team"
                                                  : "view-client"
                                              }/${value.userId._id}`
                                            )
                                          }
                                        >
                                          {value &&
                                          value.userId &&
                                          value.userId.firstName &&
                                          value.userId.lastName
                                            ? value.userId.firstName +
                                              " " +
                                              value.userId.lastName
                                            : value.userId.email}
                                        </a>
                                      </td>
                                      <td>
                                        <a
                                          href="#"
                                          onClick={() =>
                                            navigate(
                                              `/${
                                                value?.eventType == "Order"
                                                  ? "view-orders"
                                                  : "view-ticket"
                                              }/${value.resourceId}`
                                            )
                                          }
                                        >
                                          {value?.resourceId}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                                .reverse()}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>There is no subscription added</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* history modal */}
      <div
        className="modal fade history-modal"
        id="historyModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Order Completed
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <div className="form-group">
                  <label>Time</label>
                  <p>Sep 19th at 12:37 pm BST</p>
                </div>

                <div className="form-group">
                  <label>User</label>
                  <p>
                    <a href="#">Client Name</a>
                  </p>
                </div>

                <div className="form-group">
                  <label>Order</label>
                  <p>
                    <a href="#">6B3500E7</a>
                  </p>
                </div>

                <div className="form-group">
                  <label>Data</label>
                  <p>Data</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* history modal */}

      {/* assign manager modal */}
      <div
        className="modal fade history-modal"
        id="assignModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Assign {clientData?.userId?.firstName}`to a team member
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  {/* <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>Country</label>
                          <select
                            className="form-control"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            {countries.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}

                  <div className="form-group">
                    <label>Assign this customer's orders to </label>
                    <select
                      className="form-control"
                      value={assignTeamMember}
                      onChange={(e) => {
                        setAssignTeamMember(e.target.value);
                        teamAccountList.forEach((item) => {
                          if (e.target.value == item?.userId?._id) {
                            setTeamMemberName(item?.userId?.firstName);
                          }
                        });
                      }}
                    >
                      {teamAccountList.map((account, idx) => {
                        return (
                          <option key={idx} value={account?.userId?._id}>
                            <div>
                              <span>{account?.userId?.firstName}</span>
                            </div>
                          </option>
                        );
                      })}
                    </select>
                    <small>
                      This customer's orders will be assigned to selected team
                      member.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={assignTeamMemberHandler}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* assign manager modal */}

      {/* merge modal */}
      <div
        className="modal fade history-modal"
        id="mergeModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Merge Gamanjit Singh
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>Select an account to merge into </label>
                    <select className="form-control">
                      <option>Select a team member</option>
                      <option>Team member</option>
                      <option>Team member</option>
                    </select>
                    <small>
                      All orders, messages, invoices, and tickets from Gamanjit
                      Singh will be moved to selected account.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-danger">
                Merge Account
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* merge modal */}

      {/* merge modal */}

      {isDeleteConfirmModalOpen ? (
        <DeleteConfirmModal
          isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
          handleDelete={() => deleteClient(clientId)}
          handleCancelModal={() => setIsDeleteConfirmModalOpen(false)}
          modalContent={`Are you sure you want to delete this account along with all
          their orders and messages:
          ${clientData?.userId?.firstName}? Their
          invoices will still be preserved for accounting purposes.`}
          disabledBtn={disabledBtn}
        />
      ) : (
        ""
      )}
      {/* merge modal */}
    </div>
  );
};
export default ViewClient;
