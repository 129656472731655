import React, { useContext, useState } from "react";
import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import noData from "../../../assets/img/nodata-img.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  convertToUpperCase,
  formatMonthNameAndDate,
  getOrderStatus,
  pendingStatusTypeCheck,
} from "../../../shared/functions";
import { AllContext } from "../../../context";
import PageLoader from "../../pageLoader";
import PaginationNewUI from "../../PaginationNewUI";
import TableTabList from "../TabList";
import LoadingOverlay from "react-loading-overlay";
LoadingOverlay.propTypes = undefined;

const TablePurchases = ({
  isPage,
  searchText,
  isLoadingSearch,
  setIsLoadingSearch,
}) => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const context = useContext(AllContext);
  const dispatch = useDispatch();
  const { allApiUrl, toastMessage, redirectToLogin, apiPostMethod } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { companyData } = useSelector((state) => state.sideMenuReducer);

  const [countStatus, setCountStatus] = useState({});
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("all");

  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0); // totalRecords
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [initialLoading, setInitialLoading] = useState(true);

  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  useEffect(() => {
    if (isLoadingSearch) {
      getOrderDetail();
    }
  }, [isLoadingSearch]);
  useEffect(() => {
    getOrderDetail();
  }, [activeTab, itemOffset, itemsPerPage]);

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const getOrderDetail = () => {
    if (!initialLoading) {
      setIsLoading(true);
    }
    let data = {};
    data.page = itemOffset;
    data.limit = itemsPerPage;
    data.status = activeTab;

    if (searchText) {
      data.searchText = searchText;
    }

    apiPostMethod(allApiUrl.DISPLAY_ORDERS_V2, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPageCount(res.data.totalPage);
          setTotalRecordsCount(res.data.totalItems);
          setOrderList([...res.data.orders]);
          setCountStatus({ ...res.data.countStatus });
          setTabs(res.data.statusListNames);
        }
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {
          setIsLoadingSearch(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err?.data?.message);
        setInitialLoading(false);
        setIsLoading(false);
        if (setIsLoadingSearch !== undefined) {
          setIsLoadingSearch(false);
        }
      });
  };
  return (
    <div>
      {!!isPage && (
        <>
          <Sidebar
            activePage="purchases"
            setToggleSidebar={setToggleSidebar}
            toggleSideBar={toggleSideBar}
          />
          <Header handleSidebar={handleSidebar} />
        </>
      )}
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div
          className={
            isPage
              ? "orders-section commonPadding horizontalPadding"
              : "shopSection horizontalPadding"
          }
        >
          <div className={isPage ? "commonSlidePl" : ""}>
            <div className="container-fluid ">
              <div className="row">
                <div className="col-lg-6">
                  <div className="heading">
                    <h2>My Purchases</h2>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="section-filter">
                    <PaginationNewUI
                      itemsPerPage={itemsPerPage}
                      itemOffset={itemOffset}
                      setItemOffset={setItemOffset}
                      pageCount={pageCount}
                      totalRecordsCount={totalRecordsCount}
                      setItemsPerPage={setItemsPerPage}
                      mainClassName="col-lg-12"
                    />
                  </div>
                </div>
              </div>

              <LoadingOverlay
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                      stroke: "#007D87",
                    },
                  }),
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
                active={isLoading}
                spinner
                text="Loading content..."
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-tabs orderTabs">
                      <ul
                        className="nav nav-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <TableTabList
                          tabs={tabs}
                          activeTab={activeTab}
                          countStatus={countStatus}
                          setActiveTab={setActiveTab}
                        />
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-overdue"
                          role="tabpanel"
                          aria-labelledby="pills-overdue-tab"
                          tabIndex="0"
                        >
                          <div className="common-table table-responsive rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Order ID</th>
                                  <th>Title</th>
                                  <th>Paid Date</th>
                                  <th>Completed Date</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderList.map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a
                                          onClick={() => {
                                            navigate(
                                              `/view-purchase/${item?.orderId}`,
                                              {
                                                state: {
                                                  _id: item._id,
                                                },
                                              }
                                            );
                                            document
                                              .getElementById(
                                                "searchModalCloseId"
                                              )
                                              .click();
                                          }}
                                        >
                                          {item?.orderId}
                                        </a>
                                      </td>
                                      <td>{item?.title}</td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.createdDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>
                                        {item?.completedDate
                                          ? item.completedDate
                                          : "--"}
                                      </td>
                                      <td>
                                        {pendingStatusTypeCheck(item) &&
                                        item.inTakeForm ? (
                                          <span
                                            className="pill pill-grey fs-12 fw-normal"
                                            style={{
                                              cursor: "pointer",
                                              background: "#c5bff9",
                                              color: "#5a4cdc",
                                            }}
                                            onClick={() => {
                                              navigate(
                                                "/portal/customIntakeForm",
                                                {
                                                  state: {
                                                    fieldInfo: item.customForm,
                                                    mode: "Add",
                                                    orderId: item._id,
                                                  },
                                                }
                                              );
                                              document
                                                .getElementById(
                                                  "searchModalCloseId"
                                                )
                                                .click();
                                            }}
                                          >
                                            Start Order
                                          </span>
                                        ) : (
                                          <>{getOrderStatus(item)}</>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {orderList.length <= 0 && (
                                  <tr>
                                    <td colSpan={5}>
                                      <div className="noData-section">
                                        <img alt="no data" src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TablePurchases;
