import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import { useRef } from "react";
import PageLoader from "../../components/pageLoader";
import Good from "../../assets/img/good.png";
import Great from "../../assets/img/great.png";
import Okay from "../../assets/img/okay.png";
import Sad from "../../assets/img/sad.png";
import Terrible from "../../assets/img/terrible.png";
import { useNavigate, useParams } from "react-router-dom";
import { handleOffSetDateTime, redirectToLogin } from "../../shared/functions";
import moment from "moment";
import { editorApiKey } from "../../shared/constants";

const ViewTickets = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const { companyData } = useSelector((state) => state.sideMenuReducer);
  const context = useContext(AllContext);
  const { allApiUrl, apiPutMethod, toastMessage } = context;
  const [ccUser, setCcUser] = useState([]);
  const [checked, setChecked] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [ratingResponse, setRatingResponse] = useState(false);
  const [ratingNumber, setRatingNumber] = useState({
    img: "",
    name: "",
  });
  const { GET_TICKET, EDIT_TICKET, ADD_CLIENT } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    getTickets();
    getAllTicketStatus();
  }, []);
  const editorRef = useRef(null);
  const [messageCotent, setMessageCotent] = useState("");
  const [ticket, setTicket] = useState({
    assignedUsers: [],
    cc: [],
    date: "",
    message: [],
    note: "",
    relatedOrder: [],
    status: "open",
    subject: "",
    tags: [],
    to: "",
  });
  const [selectedFile, setSelectedFile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTickets = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_TICKET + "/" + id, "", header).then((res) => {
      if (res.status === 200) {
        setRatingResponse(res.data.details[0].rating);
        if (res.data.details[0].rating) {
          if (res.data.details[0].rating == 1) {
            setRatingNumber({ img: Terrible, name: "Terrible" });
          } else if (res.data.details[0].rating == 2) {
            setRatingNumber({ img: Sad, name: "Sad" });
          } else if (res.data.details[0].rating == 3) {
            setRatingNumber({ img: Okay, name: "Okay" });
          } else if (res.data.details[0].rating == 4) {
            setRatingNumber({ img: Good, name: "Good" });
          } else {
            setRatingNumber({ img: Great, name: "Great" });
          }
        }

        setTicket(res.data.details[0]);
        if (res.data.details[0].cc) {
          getSelectedCC(res.data.details[0].cc);
        }
        setInitialLoading(false);
      }
    });
  };
  useEffect(() => {
    if (!ticket.rating) {
      if (ticket.status == "Closed" && checked) {
        setShowRating(true);
      } else {
        setShowRating(false);
      }
    }
  }, [ticket]);
  function getAllTicketStatus() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_ALL_TICKET_STATUS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setChecked(res.data[0].rating);

          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function updateTicket(data) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(EDIT_TICKET + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        setMessageCotent("");
        setShowRating(false);
        getTickets();
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  // function formatAMPM(date) {
  //   const monthNames = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "July",
  //     "Aug",
  //     "Sept",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   var dateString = monthNames[date.getMonth()] + " " + date.getDate() + "th";
  //   var hours = date.getHours();
  //   var minutes = date.getMinutes();
  //   var ampm = hours >= 12 ? "pm" : "am";
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   var strTime = dateString + " at " + hours + ":" + minutes + " " + ampm;
  //   return strTime;
  // }

  function handleMessageEditorChange(e) {
    // var str = e.target.getContent();
    setMessageCotent(e);
  }
  function getSelectedCC(cc) {
    let listCc = [];
    cc.map((row) => {
      let label = "";
      if (row.firstName && row.lastName) {
        label = row.firstName + " " + row.lastName;
      } else {
        if (row.firstName || row.lastName) {
          if (row.firstName) {
            label = row.firstName;
          } else {
            label = row.lastName;
          }
        } else {
          label = row.email;
        }
      }
      let data = {
        label: label,
        value: row._id,
      };
      listCc.push(data);
    });
    setCcUser(listCc);
  }

  function CheckNewCC() {
    ccUser.map((cc, index) => {
      if (cc.value == "newCC") {
        const autoFirstName = cc.label.substring(0, cc.label.indexOf("@"));
        const data = {
          accountStatus: "contact",
          role: "client",
          createdBy: userInfo.id,
          firstName: autoFirstName,
          lastName: "",
          email: cc.label,
        };
        let header = {
          Authorization: `Bearer ${loginUserAccessToken}`,
        };
        apiPostMethod(ADD_CLIENT, data, header)
          .then((res) => {
            if (res.status === 201) {
              ccUser[index].value = res.data.id;
              toastMessage("success", res.data.message);
              if (index == ccUser.length - 1) {
                if (ccUser.length > 0) {
                  ccUser.map((row, index) => {
                    ccUser[index] = row.value;
                  });
                }
                updateTicket({ cc: ccUser });
              }
            }
          })
          .catch((err) => {
            if (err.data.message == "Account already exist") {
              ccUser[index].value = err.data.id;
              if (index == ccUser.length - 1) {
                if (ccUser.length > 0) {
                  ccUser.map((row, index) => {
                    ccUser[index] = row.value;
                  });
                }
                updateTicket({ cc: ccUser });
              }
            } else {
              toastMessage("error", err.data.message);
            }
          });
      } else {
        if (index == ccUser.length - 1) {
          if (ccUser.length > 0) {
            ccUser.map((row, index) => {
              ccUser[index] = row.value;
            });
          }
          updateTicket({ cc: ccUser });
        }
      }
    });
  }

  function AttachmentFiles() {
    if (selectedFile && selectedFile.length > 0) {
      let files = [...selectedFile];
      return files.map((selected, index) => {
        return (
          <span>
            <a>
              {" "}
              <span className="innerIcon attachIcon me-1">
                <i className="fa-solid fa-link"></i>{" "}
              </span>
              {selected.name}{" "}
              <span className="innerIcon crossIcon ms-2">
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => {
                    var dt = new DataTransfer();
                    let input = document.getElementById("myFileInput");
                    var { files } = input;
                    for (var i = 0; i < files.length; i++) {
                      var file = files[i];
                      if (index !== i) dt.items.add(file);
                      input.files = dt.files;
                    }
                    setSelectedFile(input.files);
                  }}
                ></i>
              </span>
            </a>
          </span>
        );
      });
    }
    return "";
  }
  function updateTicketMessage(message) {
    setIsLoading(true);
    if (selectedFile.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("files", selectedFile[i]);
      }
      formData.append("from", userInfo.id);
      formData.append("message", message);
      formData.append("date", new Date().getTime());
      formData.append("to", "Client");
      formData.append("type", "Ticket");
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
        "Content-Type": "multipart/form-data",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, formData, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          setSelectedFile({});
          getTickets();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    } else {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      let data = {
        from: userInfo.id,
        message: message,
        date: new Date().getTime(),
        to: "Client",
        type: "Ticket",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, data, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          setSelectedFile({});
          getTickets();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  }
  return (
    <div>
      <Sidebar
        activePage="help"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="viewOrders pt-3 pb-3 horizontalPadding">
          <div className="commonSlidePl">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-lg-6 col-6">
                <div className="section-back">
                  <a
                    onClick={() => {
                      navigate("/help");
                    }}
                    href="#"
                  >
                    {" "}
                    <i className="fa-solid fa-angle-left"></i> Help
                  </a>
                </div>
                {/* <div className="col-lg-6 col-6">
              <div className="section-back text-end">
                <a href="#" className="mx-2">
                  <i className="fa-solid fa-angle-up"></i>
                </a>
                <a href="#" className="mx-2">
                  <i className="fa-solid fa-angle-down"></i>
                </a>
              </div>
            </div> */}
              </div>

              <div className="container">
                <div className="row mb-2">
                  <div className="col-xxl-9 col-lg-10 mx-auto">
                    <div className="sectionHeading">
                      <h3 className="d-flex justify-content-between fs-4">
                        {ticket.subject}
                      </h3>
                    </div>

                    <div className="client-details rounded-1">
                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientLabel">Subject</p>
                        </div>

                        <div className="col-lg-6 col-8">
                          <p className="clientText">{ticket.subject}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientLabel">Status</p>
                        </div>

                        <div className="col-lg-6 col-8">
                          <p className="clientText">
                            <span className="pill pill-orange fs-14 fw-normal">
                              {ticket.status}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientLabel">Date</p>
                        </div>

                        <div className="col-lg-6 col-8">
                          <p className="clientText"> {handleOffSetDateTime(moment().toISOString(), companyData.timezone)}</p>
                        </div>
                      </div>
                    </div>

                    <div className="sectionMessage mt-4 client-sectionMessage">
                      <h3>Messages</h3>
                      {ticket.message.map((value,index) => {
                        if (value.to == "Client")
                          return (
                            <div key={index} className="clientMessage">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="messageUser">
                                    <div className="userImg">
                                      <img
                                        src={
                                          value.from && value.from.image
                                            ? value.from.image
                                            : Avatar
                                        }
                                      />
                                    </div>
                                    <div className="userName">
                                      <h4>
                                        {value.from && (
                                          <>
                                            {value.from.firstName ||
                                              value.from.lastName ? (
                                              <>
                                                {value.from.firstName &&
                                                  value.from.lastName ? (
                                                  value.from.firstName +
                                                  " " +
                                                  value.from.lastName
                                                ) : (
                                                  <>
                                                    {value.from.firstName
                                                      ? value.from.firstName
                                                      : value.from.lastName}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              value.from.email
                                            )}
                                          </>
                                        )}
                                      </h4>
                                      {/* <p>{value.date}</p> */}
                                      <p>{handleOffSetDateTime(value.date)}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="message-content">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: value.message,
                                  }}
                                ></div>
                              </div>
                              <div id="attachment" className="messageAttachment">
                                {value.attachments.map((attachment,index) => {
                                  return (
                                    <span key={index} id="addedfiles">
                                      <a>
                                        {" "}
                                        <span className="innerIcon attachIcon">
                                          <i className="fa-solid fa-link"></i>{" "}
                                        </span>
                                        {attachment.split("/uploads/")[1]}
                                      </a>
                                      {/* <span className="innerIcon downloadIcon mx-2 ms-3" onClick={() => { onButtonClick(attachment) }}><i class="fa-solid fa-download"></i></span> */}
                                      <a href={attachment} target="_blank">
                                        <span className="innerIcon mx-2 ms-3">
                                          <i className="fa-solid fa-eye"></i>
                                        </span>
                                      </a>
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          );
                      })}
                      {/* <div className="col-lg-6">
                         <div className="dropdown text-end">
                           <button
                             className="btn btn-secondary dropdown-toggle btn-info"
                         type="button"
                         data-bs-toggle="dropdown"
                         aria-expanded="false"
                       >
                         <i className="fa-solid fa-ellipsis-vertical"></i>
                       </button>
                       <ul className="dropdown-menu">
                         <li>
                           <a
                             className="dropdown-item"
                             href="#"
                           >
                             Edit
                           </a>
                         </li>
                         <li>
                           <a
                             className="dropdown-item"
                             href="#"
                             data-bs-toggle="modal"
                             data-bs-target="#deletemessageModal"
                           >
                             Delete
                           </a>
                         </li>
                       </ul>
                     </div>
                   </div>
                      </div>*/}

                      {/* </div> */}

                      {/* <div className="clientMessage">
                    <div className="row">
                       <div className="col-lg-6">
                        <div className="messageUser">
                          <div className="userImg">
                            <img src={Avatar} />
                          </div>
                          <div className="userName">
                            <h4>You</h4>
                            <p>{formatAMPM(new Date())}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                                        <div className="dropdown text-end">
                                            <button className="btn btn-secondary dropdown-toggle btn-info" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deletemessageModal">Delete</a></li>
                                            </ul>
                                        </div>
                                    </div>
                    </div>
                  </div>*/}

                    {ticket.status != "Closed" && (
                      <div className="reply-section mt-0">
                        <form className="rounded-bottom">
                          <div className="form-group">
                            <Editor
                              className="form-control"
                              placeholder="Add a note for your team..."
                              apiKey={editorApiKey}
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              value={messageCotent}
                              init={{
                                selector: "textarea",
                                height: 200,
                                menu: {
                                  custom: {
                                    title: "custom",
                                    items: "basicitem nesteditem toggleitem",
                                  },
                                  toolbar:
                                    "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                    "fullpage" +
                                    "indent | blockquote | codesample | fontselect ",
                                  font_formats:
                                    "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                  block_formats:
                                    "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                  plugins: [
                                    "image",
                                    "help",
                                    "link",
                                    "codesample",
                                    "lists",
                                  ],
                                  content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  menubar: false,
                                },
                                branding: false,
                              }}
                                // onChange={(e) => handleEditorChange(e)}
                                onEditorChange={(e) =>
                                  handleMessageEditorChange(e)
                                }
                              />
                              <div className="row"></div>
                              <div className="row mt-3">
                                <div className="col-lg-6">
                                  <div className="text-start">
                                    <div
                                      id="attachment"
                                      className="messageAttachment"
                                    >
                                      <AttachmentFiles />
                                    </div>
                                    <input
                                      type="file"
                                      className="btn btn-secondary"
                                      hidden
                                      multiple
                                      id="myFileInput"
                                      onChange={(e) => {
                                        setSelectedFile(e.target.files);
                                      }}
                                    />
                                    <span
                                      className="delete"
                                      onClick={() => {
                                        document
                                          .getElementById("myFileInput")
                                          .click();
                                      }}
                                    >
                                      <i className="fa-solid fa-link"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="send-btn">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      value={ticket.message}
                                      onClick={(e) => {
                                        if (messageCotent.length > 0) {
                                          updateTicketMessage(messageCotent);

                                          setMessageCotent("");
                                        } else {
                                          alert("Please insert a message");
                                        }
                                      }}
                                    >
                                      Send
                                    </button>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {ticket.status != "Closed" && (
                      <p className="ccText mb-0">
                        Add:{" "}
                        <a
                          className="text-decoration-none"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#ccModal"
                        >
                          {ticket.cc.length > 0
                            ? ticket.cc.map((value, index) => {
                                return (
                                  value.email +
                                  `${index == ticket.cc.length - 1 ? "" : ","}`
                                );
                              })
                              : "CC"}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {showRating && (
                <div className="emojisection">
                  <h4>How did we do?</h4>
                  <div className="emojibutton">
                    <button onClick={(e) => updateTicket({ rating: 1 })}>
                      <img src={Terrible} /> <br />
                      Terrible
                    </button>

                    <button onClick={(e) => updateTicket({ rating: 2 })}>
                      <img src={Sad} /> <br />
                      Bad
                    </button>

                    <button onClick={(e) => updateTicket({ rating: 3 })}>
                      <img src={Okay} /> <br />
                      Okay
                    </button>

                    <button onClick={(e) => updateTicket({ rating: 4 })}>
                      <img src={Good} /> <br />
                      Good
                    </button>

                    <button onClick={(e) => updateTicket({ rating: 5 })}>
                      <img src={Great} /> <br />
                      Great
                    </button>
                  </div>
                </div>
              )}
              {ratingResponse && ticket.status === "Closed" && (
                <div className="emojisection">
                  <h4>How did we do?</h4>
                  <div className="emojibutton">
                    <img src={ratingNumber.img} /> <br />
                    {ratingNumber.name}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        </div>
      )}

      {/* </div> */}

      {/* cc modal */}
      <div
        className="modal fade history-modal"
        id="ccModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>CC</label>

                    <Select
                      className="my-react-select my-react-select--compact"
                      isMulti
                      placeholder="Add CC"
                      menuIsOpen={false}
                      onChange={(e) => {
                        setCcUser(e);
                      }}
                      value={ccUser}
                      onBlur={(e) => {
                        if (e.target.value.length > 6) {
                          let status = String(e.target.value)
                            .toLowerCase()
                            .match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            );
                          if (status != null) {
                            let data = {
                              label: e.target.value,
                              value: "newCC",
                            };
                            ccUser.push(data);
                            setCcUser(ccUser);
                          } else {
                            toastMessage("error", "Invalid Email Address");
                          }
                        }
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  if (ccUser.length > 0) {
                    CheckNewCC();
                  } else {
                    updateTicket({
                      cc: [],
                    });
                  }
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* cc modal */}
    </div>
  );
};
export default ViewTickets;
