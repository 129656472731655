import { useLocation, useNavigate } from "react-router-dom";

export const PermissionDenied = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <div className="section-sermissionDenied">
      <div className="container">
        <div className="row h-80vh mx-0 align-items-center">
          {state.noPermission ? (
            <div className="col-xxl-4 mx-auto">
              <h1>Role does not have any permissions.</h1>
              <p>This is a 400 Bad Request,sorry🤕</p>
              <a href="#" onClick={() => navigate("/login")}>
                <i className="fa-solid fa-angles-left"></i> Go Back
              </a>
            </div>
          ) : (
            <div className="col-xxl-4 mx-auto">
              <h1>403 Forbidden</h1>
              <p>You don't have permission to access this resource.</p>
              <a
                href="#"
                onClick={() => navigate("/settings/team")}
              >
                <i className="fa-solid fa-angles-left"></i> Go Back
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
