import "./App.css";
import "antd/dist/antd.min.css";
import React, { useEffect } from "react";
// import "../src/assets/css/custom.css";
import "../src/assets/css/customNew.scss";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// client
import Home from "./screens/home";
import Purchases from "./screens/purchases";
import ViewPurchase from "./screens/view-purchase";
import Shop from "./screens/shop";
import ViewCart from "./screens/view-cart";
import Payments from "./screens/payments";
import ViewPayment from "./screens/view-payment";
import Profile from "./screens/profile";
import ClientTeam from "./screens/client-team";
import Help from "./screens/help";
import ViewTickets from "./screens/view-tickets";
import Checkout from "./screens/checkout";
import PaymentFailed from "./screens/paymentFailed";
import CheckoutInvoice from "./screens/checkoutInvoice";
import Thankyou from "./screens/thankyou";
import InTakeForm from "./screens/intakeForm";
import CustomIntakeForm from "./screens/customIntakeForm";
import Payment from "./screens/payment";
// admin
import Dashboard from "./screens/dashboard";
import ServiceReport from "./screens/service-report";
import ClientReport from "./screens/client-report";
import CompletedOrder from "./screens/completed-order";
import OrderStatus from "./screens/order-status";
import Revenue from "./screens/revenue";
import Sales from "./screens/sales";
import Orders from "./screens/orders";
import Clients from "./screens/clients";
import AddClient from "./screens/add-client";
import EditClient from "./screens/edit-client";
import ViewClient from "./screens/view-client";
import Tickets from "./screens/tickets";
import AddTicket from "./screens/add-ticket";
import ViewTicket from "./screens/view-ticket";
import EditTicket from "./screens/edit-ticket";
import Files from "./screens/files";
import Coupons from "./screens/coupons";
import AddCoupon from "./screens/add-coupon";
import EditCoupon from "./screens/edit-coupon";
import Messaging from "./screens/messaging";
import AddMessage from "./screens/add-message";
import EditMessage from "./screens/edit-message";
import Services from "./screens/services";
import AddService from "./screens/add-service";
import EditService from "./screens/edit-service";
import Settings from "./screens/settings";
import CompanySetting from "./components/settings/company";
import PaymentSetting from "./components/settings/payment";
import TeamSetting from "./components/settings/team/index.jsx";
import AddRole from "./screens/add-role";
import EditRole from "./screens/edit-role";
import AddTeamMember from "./screens/add-team-member";
import EditTeam from "./screens/edit-team";
import Logs from "./screens/logs";
import ViewLogs from "./screens/view-logs";
import ViewOrders from "./screens/view-orders";
import Tags from "./screens/tags";
import Email from "./screens/email";
import Invoices from "./screens/invoices";
import EditInvoice from "./screens/edit-invoice";
import AddInvoice from "./screens/add-invoice";
import ViewInvoice from "./screens/view-invoice";
import ViewPublicInvoice from "./screens/public-invoice";
import Subscription from "./screens/subscription";
import ViewSubscription from "./screens/view-subscription";
import Login from "./screens/login";
import Signup from "./screens/signup";
import ForgotPassword from "./screens/forgot-password";
import ResetPassword from "./screens/reset-password";
import Logout from "./screens/logout";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { PermissionDenied } from "./components/PermissionDenied";
import ViewTeamMember from "./screens/view-team";
import PageNotFound from "./components/pageNotFound";
import IncorrectInvoice from "./components/IncorrectInvoice";
import "react-toastify/dist/ReactToastify.css";
import EmailTemplates from "./screens/email-templates";
import IncorrectOrder from "./components/IncorrectOrder";
import AccountSettings from "./screens/accountSettings";
import TicketSettings from "./screens/ticketSettings";
// import Clientsettings from "./screens/clientSettings";
import LayoutSettings from "./screens/layoutSettings";
import PayLaterOrder from "./payLater";
import AdminRoute from "./routes/AdminRoute";
import ClientRoute from "./routes/ClientRoutes";
// import CrmFields from "./components/settings/crm-fields";
import OrderSettings from "./screens/orderSettings";
import ShopSettings from "./screens/shopSettings";
import SuperAdminPortal from "./SuperAdminPortal";
import SuperAdminLogin from "./SuperAdminPortal/components/SuperAdminLogin";
import SuperAdminRoutes from "./routes/SuperAdminRoute";
import SuperAdminDashboard from "./SuperAdminPortal/components/SuperAdminDasboard";
import ManageBusiness from "./SuperAdminPortal/components/ManageBusiness";
import { getBusinessSubdomain } from "./shared/functions";
// import NotAssigned from "./components/NotAssigned";
import { AllContext } from "./context";
import { useContext } from "react";
import { apiPostMethod } from "./api/rest";

function App() {
  const authRoleList = useSelector(
    (state) => state.loginReducer.loginUserInfo?.roles
  );
  const context = useContext(AllContext);
  const {
    allApiUrl,
    toastMessage,
  } = context;
  const {isSuperAdminLoggedIn} = useSelector((state) => state?.loginReducer);

  let loggedIn = localStorage.getItem("userLoggedIn");
  const allTrueChecker = (value) => {
    let result = Object.values(value).every((v) => v === false);
    return result;
  };

  useEffect(() => {
    const subDomain = getBusinessSubdomain();
    if(!!subDomain === true) {
      checkBusinessNameDomainExist(subDomain);
    }
  }, []);

  const checkBusinessNameDomainExist = (subDomain) => {
    apiPostMethod(allApiUrl.VERIFY_BUSINESS, {subDomain})
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      toastMessage("error", err.data.message);
      window.location.replace(process.env.MAIN_SITE_URL || "https://opflow-landing-page.softuvo.click/signup");
    });
  }

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        autoClose={5000}
        bodyClassName="toastBody"
      />
      <Router>
        <Routes>
          {/* Auth */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/public-invoice/:id" element={<ViewPublicInvoice />} />
          <Route path="/incorrectInvoice" element={<IncorrectInvoice />} />
          <Route path="/incorrectOrder" element={<IncorrectOrder />} />

          { /* Admin routes */}

          {/* ticket */}
          <Route
            path="/tickets"
            element={
              loggedIn &&
              <AdminRoute>
                {!allTrueChecker(authRoleList?.ticketAccess) ?
                  <Tickets /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/add-ticket"
            element={
              loggedIn &&
              <AdminRoute>
                {authRoleList?.ticketManagement?.add_delete ?
                  <AddTicket /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/view-ticket"
            element={
              loggedIn &&
              <AdminRoute>
                {!allTrueChecker(authRoleList?.ticketAccess) ?
                  <ViewTicket /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/edit-ticket"
            element={
              loggedIn &&
              <AdminRoute>
                {(!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ?
                  <EditTicket /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/view-ticket/:id"
            element={
              loggedIn &&
              <AdminRoute>
                {!allTrueChecker(authRoleList?.ticketAccess) ?
                  <ViewTicket /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/edit-ticket/:id"
            element={
              loggedIn &&
              <AdminRoute>
                {(!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ?
                  <EditTicket /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          {/* files */}
          <Route path="/files" element={
            <AdminRoute>
              <Files />
            </AdminRoute>
            }
          />

          {/* coupons */}
          <Route
            path="/coupons"
            element={
              <AdminRoute>
                { authRoleList?.coupons?.view_add_edit_delete ? 
                  <Coupons /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/add-coupon"
            element={
              <AdminRoute>
                { authRoleList?.coupons?.view_add_edit_delete ? 
                  <AddCoupon /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/edit-coupon/:id"
            element={
              <AdminRoute>
                { authRoleList?.coupons?.view_add_edit_delete ? 
                  <EditCoupon /> : <Dashboard />
                }
              </AdminRoute>
            }
          />

          {/* messaging */}
          <Route path="/messaging" element={<Messaging />} />
          <Route path="/add-message" element={<AddMessage />} />

          {/* services */}
          <Route
            path="/services"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <AdminRoute>
                  <Services />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/add-service"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <AdminRoute>
                  <AddService />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/edi-t-service/:id"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <AdminRoute>
                  <EditService />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/edit-service/:id"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <AdminRoute>
                  <EditService />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />

          <Route path="/edit-message/:id" element={<EditMessage />} />
          <Route path="/permissionDenied" element={<PermissionDenied />} />

          {/* invoices */}
          <Route path="/invoices" element={
            <AdminRoute>
              <Invoices />
            </AdminRoute>
          } />
          <Route path="/add-invoice" element={
            <AdminRoute>
              { authRoleList?.invoiceManagement?.add_edit ? 
                <AddInvoice /> : <Dashboard />
              }
            </AdminRoute>
          } />
          <Route path="/view-invoice/:id" element={
            <AdminRoute>
              <ViewInvoice />
            </AdminRoute>
          } />
          <Route path="/edit-invoice/:id" element={
            <AdminRoute>
              { authRoleList?.invoiceManagement?.add_edit ? 
                <EditInvoice /> : <Dashboard />
              }
            </AdminRoute>
          } />

          {/* subscription */}
          <Route path="/subscription" element={
            <AdminRoute>
              <Subscription />
            </AdminRoute>
          } />
          <Route path="/view-subscription/:id" element={
            <AdminRoute>
              <ViewSubscription />
            </AdminRoute>
          } />
          <Route path="/view-subscription" element={
            <AdminRoute>
              <ViewSubscription />
            </AdminRoute>
          } />

          <Route path="/dashboard" element={<AdminRoute><Dashboard /></AdminRoute>} />
          <Route path="/service-report" element={
            <AdminRoute>
              <ServiceReport />
            </AdminRoute>
          } />
          <Route path="/client-report" element={<ClientReport />} />
          <Route path="/completed-order" element={<CompletedOrder />} />
          <Route path="/order-status" element={<OrderStatus />} />
          <Route path="/revenue" element={<Revenue />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/role/role" element={<AddRole />} />
          <Route path="/role/role/:id" element={<EditRole />} />
          <Route path="/accounts/add/:id" element={<AddTeamMember />} />
          <Route path="/accounts/edit/:id" element={<EditTeam />} />
          <Route path="/accounts/team/:id" element={<ViewTeamMember />} />
          <Route path="/view-logs" element={<ViewLogs />} />
          <Route path="/email" element={<Email />} />
          <Route
            path="/orders"
            element={
              loggedIn &&
              <AdminRoute>
                {!allTrueChecker(authRoleList?.orderAccess)  ? 
                  <Orders /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/view-orders/:id"
            element={
              loggedIn &&
              <AdminRoute>
                {!allTrueChecker(authRoleList?.orderAccess)  ? 
                  <ViewOrders /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          {/* admin settings routes */}
          <Route
            path="/settings"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <Settings />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/general"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <CompanySetting />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/payment"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <PaymentSetting />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/tags"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <Tags />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/team"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <TeamSetting />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <Email />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/client_welcome"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"client_welcome"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/client_password_reset"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"client_password_reset"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/client_order_incomplete"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"client_order_incomplete"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/client_receipt"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"client_receipt"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/subscription_failed"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"subscription_failed"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/subscription_cancelled"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"subscription_cancelled"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/payment_refunded"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"payment_refunded"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/new_invoice"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"new_invoice"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/upcoming_payment_reminder"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"upcoming_payment_reminder"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/unpaid_invoice_reminder"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"unpaid_invoice_reminder"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/order_updated"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"order_updated"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/order_missing_project_data"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"order_missing_project_data"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/order_needs_attention"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"order_needs_attention"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />

          <Route
            path="/settings/email/template/order_data_submitted"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"order_data_submitted"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/order_canceled"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"order_canceled"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />

          <Route
            path="/settings/email/template/recurring_payment"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"recurring_payment"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/invoice_paid"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"invoice_paid"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />

          <Route
            path="/settings/email/template/subscription_canceled_team"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"subscription_canceled_team"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/email/template/subscription_failed_team"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <EmailTemplates dataKey={"subscription_failed_team"} />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route
            path="/settings/layoutSettings"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <AdminRoute>
                  <LayoutSettings />
                </AdminRoute>
              ) : (
                <AdminRoute><Dashboard /></AdminRoute>
              )
            }
          />
          <Route path="/settings/accounts"
            element={
              <AdminRoute>
                <AccountSettings />
              </AdminRoute>
          } />
          <Route path="/settings/tickets"
            element={
              <AdminRoute>
                <TicketSettings />
              </AdminRoute>
            }
          />
           <Route path="/settings/orders"
            element={
              <AdminRoute>
                <OrderSettings />
              </AdminRoute>
            }
          />
          <Route path="/settings/shop"
            element={
              <AdminRoute>
                <ShopSettings />
              </AdminRoute>
            }
          />
          {/*<Route path="/settings/crm-fields"
            element={
              <AdminRoute>
                <CrmFields />
              </AdminRoute>
            }
          />*/}
          { /* End of admin settings */ }

          {/* Admin manage clients routes */}
          <Route
            path="/clients"
            element={authRoleList?.clients?.view ?
            <AdminRoute>
              <Clients />
            </AdminRoute> : <AdminRoute><Dashboard /></AdminRoute>}
          />
          <Route
            path="/add-client"
            element={
              <AdminRoute>
                { authRoleList?.clients?.add_edit_login ?
                  <AddClient /> : <Dashboard />
                }
              </AdminRoute>
            }
          />
          <Route
            path="/edit-client/:id"
            element={
              <AdminRoute>
                { authRoleList?.clients?.add_edit_login ? 
                  <EditClient /> : <Dashboard />
                }
              </AdminRoute>  
            }
          />
          <Route
            path="/view-client/:id"
            element={
              authRoleList?.clients?.view ?
              <AdminRoute>
                <ViewClient />
              </AdminRoute> : <AdminRoute><Dashboard /></AdminRoute>
            }
          />
          {/* End of Admin manage clients routes */}

          {/* <Route path="/accounts/import" element={<Import />} /> */}
          {/* Logs */}
          <Route path="/logs" element={<Logs />} />
          <Route path="/logs/log/:id" element={<ViewLogs />} />
          <Route path="*" element={<Login />} />

          {/* messaging */}
          <Route path="/messaging" element={<Messaging />} />
          <Route path="/add-message" element={<AddMessage />} />

          <Route path="/edit-message/:id" element={<EditMessage />} />
          <Route path="/permissionDenied" element={<PermissionDenied />} />
          <Route path="/public-invoice/:id" element={<ViewPublicInvoice />} />
          <Route path="/view-tickets" element={<ViewTickets />} />

          {/* End of Admin routes */ }

          { /* Client Routes */}
          <Route path="/home" element={<Home />} />
          <Route path="/purchases" element={
            <ClientRoute>
              <Purchases />
            </ClientRoute>
          } />
          <Route path="/view-purchase" element={
            <ClientRoute>
              <ViewPurchase />
            </ClientRoute>
          } />
          <Route path="/view-purchase/:id" element={
            <ClientRoute>
              <ViewPurchase />
            </ClientRoute>
          } />
          <Route path="/shop"
            element={
              <ClientRoute>
                <Shop />
              </ClientRoute>
            }
          />
          <Route path="/cart" element={
            <ClientRoute>
              <ViewCart />
            </ClientRoute>
          } />
          <Route path="/payments" element={
            <ClientRoute>
              <Payments />
            </ClientRoute>
          } />
          <Route path="/view-payment/:id" element={
            <ClientRoute>
              <ViewPayment />
            </ClientRoute>
          } />
          <Route path="/clientTeam" element={
            <ClientRoute>
              <ClientTeam />
            </ClientRoute>
          } />
          <Route path="/help" element={
            <ClientRoute>
              <Help />
            </ClientRoute>
          } />
          {/* <Route path="/clientSettings" element={<Clientsettings />} /> */}
          <Route path="/product/paymentSuccess" element={<Checkout />} />
          <Route path="/product/paymentFailed" element={<PaymentFailed />} />
          <Route path="/invoice/paymentSuccess" element={<CheckoutInvoice />} />
          <Route path="/invoice/paylaterOrderSuccess" element={<PayLaterOrder />} />
          <Route path="/portal/view-ticket/:id" element={<ViewTickets />} />
          <Route path="/portal/intakeForm/:id" element={<InTakeForm />} />
          <Route
            path="/portal/customIntakeForm"
            element={<CustomIntakeForm />}
          />
          <Route path="/payment" element={<Payment />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/super-admin" element={
            <SuperAdminRoutes>
              <SuperAdminPortal />
            </SuperAdminRoutes>
          }>
            <Route path="/super-admin" element={<Navigate to="/super-admin/dashboard" />} />
            <Route path="/super-admin/login" element={!isSuperAdminLoggedIn ? 
              <SuperAdminLogin /> : <Navigate to="/super-admin/dashboard" />
            }></Route>
            <Route path="/super-admin/dashboard" element={isSuperAdminLoggedIn ? 
              <SuperAdminDashboard /> : <Navigate to="/super-admin/login" />
            }></Route>
            <Route path="/super-admin/manage-business" element={isSuperAdminLoggedIn ? 
              <ManageBusiness /> : <Navigate to="/super-admin/login" />
            }></Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
