import React, { useContext } from "react";
import parse from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import DummyAvatar from "../../assets/img/default .jpeg";
import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";
import LazyLoadImage from "../LazyloadImage";

const ServiceCart = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    toastMessage,
    redirectToLogin,
    apiPostMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  async function addToCartHandler(callback) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const data = {
        product: {
          id: props._id,
          price: props.price,
          name: props.name,
          quantity: 1,
        },
        addToCart: true,
      };
      const response = await apiPostMethod(allApiUrl.CART, data, header);
      if (response.status === 200) {
        toastMessage("success", "Item added to cart.");
        props.setShowCart(true);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data.message);
    }
  }
  return (
    <div className="col-xxl-3 col-lg-4 col-md-6">
      <div className="card mb-4">
        <div>
          <LazyLoadImage
            path={props.imageUrl}
            placeholder={props.imageUrl}
            loadError={DummyAvatar}
            className="serviceImg"
          />
        </div>
        <div className="card-body">
          <h3>{props.name}</h3>
          <div className="servicesContent">
            {props.description &&
              props.description !== "" &&
              typeof props.description == "string" &&
              parse(props.description)}
          </div>
        </div>

        <div className="card-footer">
          <div className="row">
            <div className="col-lg-6 col-md-6 d-flex align-items-center">
              <p>
                {props.oneTimeService == true
                  ? `${props.currency?.symbol ? props.currency?.symbol : "$"} ${props.price.toFixed(2)}`
                  : `${props.currency?.symbol ? props.currency?.symbol : "$"} ${props.price.toFixed(2)}` +
                    "/" +
                    props.recurringOrder.durationTime +
                    " " +
                    props.recurringOrder.durationPeriod}
              </p>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="text-end">
                <button
                  className="btn btn-primary"
                  // data-bs-toggle="modal"
                  data-bs-target="#addcartModal"
                  onClick={() => {
                    addToCartHandler();
                    props.setCouponCodeAppliedSuccessfully(false);
                  }}
                >
                  <i className="fa-solid fa-cart-shopping"></i> Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceCart;
